import styled from 'styled-components/macro'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Button as Btn } from '../index'
import { device } from '../../utils/mediaQueries'

export const Wrapper = styled(Modal)`
  .modal-dialog.modal-xl {
    max-width: inherit;
    margin: 0;
    height: 100%;
  }

  &.modal {
    z-index: 7070 !important;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }

  .modal-content {
    background: #fbfbfb;
    border: none;
    border-radius: 0;
    height: 100%;
    width: 100%;
    flex-direction: column;
    padding-top: 66.09px;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    overflow-y: scroll;
  }

  @media screen and ${device.smallMobile} {
    .modal-dialog {
    }

    .modal-content {
    }
  }

  @media screen and ${device.mobile} {
    .modal-dialog {
    }

    .modal-content {
    }
  }
`

export const NavWrapper = styled.nav`
  background-color: #ffffff !important;
  padding: 0.8rem 3rem;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 6060;
  height: 66.09px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  display: flex;
  align-items: center;

  @media screen and ${device.smallMobile} {
    padding: 0.8rem 1.5rem;
    height: 70px;
  }

  @media screen and ${device.mobile} {
    padding: 0.8rem 1.5rem;
    height: 70px;
  }
`

export const BackAction = styled(Link)`
  display: flex;
  padding-top: 0.32rem;
  padding-bottom: 0.32rem;
  line-height: inherit;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.9);
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  font-family: 'GoldplayMedium', sans-serif;
  font-style: normal;
  color: #38313c;
  font-size: 16px;
  align-items: center;

  &:hover {
    text-decoration: none;
    color: #38313c;
  }

  img {
    margin-right: 15px;
    width: 20px;
  }
`

export const ModalBody = styled(Modal.Body)`
  width: inherit;
  height: inherit;
  padding: 0 3rem 3rem;
  background: #fbfbfb;
  position: relative;

  @media screen and ${device.smallMobile} {
    padding: 1rem;
  }

  @media screen and ${device.mobile} {
    padding: 1rem;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  max-width: 30%;
  flex-direction: column;
  position: relative;
  padding: 50px 15px;
  margin-right: auto;
  margin-left: auto;

  div.questions-wrapper {
    width: 100%;
  }

  p.container-title {
    font-family: 'GoldplayMedium', sans-serif;
    font-style: normal;
    font-size: 16px;
    color: #99979b;
    margin-bottom: 0;
  }

  p.container-subtitle {
    letter-spacing: 0.03em;
    font-family: 'DM Serif Display', sans-serif;
    font-size: 25px;
    line-height: initial;
    color: #38313c;
    margin-bottom: 0;
  }

  @media screen and ${device.smallMobile} {
    z-index: 1;
    max-width: 100%;
    padding-top: 30px;
  }

  @media screen and ${device.mobile} {
    z-index: 1;
    max-width: 100%;
    padding-top: 30px;
  }
`

export const LoonBag = styled.div`
  position: fixed;
  bottom: 0;
  right: -45px;

  img {
    width: 180px;
  }

  @media screen and ${device.smallMobile} {
    right: -30px;
    img {
      width: 100px;
    }
  }

  @media screen and ${device.mobile} {
    right: -30px;
    img {
      width: 100px;
    }
  }
`

export const Button = styled(Btn)`
  margin-top: 15%;
  width: 100%;

  @media screen and ${device.smallMobile} {
    margin-top: 20%;
  }

  @media screen and ${device.mobile} {
    margin-top: 28%;
  }
`
