import { useState, useEffect, RefObject } from 'react'

const useInfiniteScroll = (
  callback: (onStopFetching: any) => void,
  target: RefObject<any>,
  inverse = false
) => {
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    function handleScroll(e: any) {
      const isScrolledToBtm = e.target.scrollHeight - e.target.scrollTop - e.target.offsetHeight < 1
      const isScrolledToTop = e.target.scrollTop === 0
      if (inverse) {
        // checked scroll top
        if (!isScrolledToTop || isFetching) return
        setIsFetching(true)
      } else {
        // checked scroll bottom
        if (!isScrolledToBtm || isFetching) return
        setIsFetching(true)
      }
    }

    const element = target.current

    element.addEventListener('scroll', handleScroll)

    return () => element.removeEventListener('scroll', handleScroll)
  }, [inverse, isFetching, target])

  useEffect(() => {
    if (!isFetching) return
    callback(setIsFetching)
  }, [callback, isFetching])

  return [isFetching, setIsFetching]
}

export default useInfiniteScroll
