import styled from 'styled-components/macro'
import { Modal } from 'react-bootstrap'
import { device } from '../../utils/mediaQueries'
import { Button as Btn } from '../../components'

export const ModalWrapper = styled(Modal)`
  &.modal {
    z-index: 7070 !important;
  }

  .modal-content {
    border: none;
    border-radius: 5px;
    min-height: 370px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  @media screen and ${device.smallMobile} {
    .modal-dialog {
      margin: 0.5rem 1rem;
    }
  }

  @media screen and ${device.mobile} {
    .modal-dialog {
      margin: 0.5rem 1rem;
    }
  }
`

export const Container = styled.div`
  height: inherit;
  width: 100%;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding: 20px 30px 80px;
  align-items: center;

  div.content-wrapper {
    align-self: center;
    text-align: center;
  }

  div.content-wrapper .title {
    font-family: 'GoldplayMedium', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.03em;
    color: #3a3a3a;
    text-transform: none;
  }

  div.content-wrapper .img-cover {
    margin-top: 20px;
    margin-bottom: 20px;
    min-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img-cover img {
    width: 350px;
    border-radius: 2.5px;
  }
`

export const Close = styled.div`
  font-size: 15px;
  font-weight: bolder;
  font-family: 'GoldplayMedium', sans-serif;
  font-style: normal;
  padding: 5px 15px;
  align-self: flex-end;
  cursor: pointer;
  text-align: center;

  @media screen and ${device.smallMobile} {
    top: 20px;
    right: 40px;
  }

  @media screen and ${device.mobile} {
    top: 20px;
    right: 40px;
  }
`

export const ModalCloseIcon = styled.img`
  width: inherit;
  cursor: pointer;
`

export const Button = styled(Btn)`
  justify-content: center;
  width: 70%;
  margin-top: 4%;

  @media screen and ${device.smallMobile} {
    margin-top: 0;
    margin-bottom: 15%;
  }

  @media screen and ${device.mobile} {
    margin-top: 0;
    margin-bottom: 15%;
  }
`
