import React, { useEffect, useState } from 'react'
import {
  ErrorNotice,
  TextNotice,
  HeightInput,
  AlertPanel,
  QuestionPanel,
  OptionsPanel,
  FormsCol,
} from './HeightInputFormBuilder.styles'
import PropTypes, { InferProps } from 'prop-types'
import { isEmpty } from 'ramda'
import { icons } from '../../../assets/icons'

type Answer = {
  questionKey: string
  answers: Array<string>
}

type ChangeEvent = React.ChangeEvent<HTMLInputElement>

export interface BuilderLabel {
  id: string
  label: string
}

export interface BuilderInputs {
  name: string
  placeholder?: string
}

HeightInputFormBuilder.defaultProps = {
  builderValue: '',
  notice: undefined,
}

HeightInputFormBuilder.propTypes = {
  builderLabel: PropTypes.object as PropTypes.Validator<BuilderLabel>,
  builderInputs: PropTypes.object as PropTypes.Validator<BuilderInputs>,
  onInputChange: PropTypes.func as PropTypes.Validator<(answers: Answer) => void>,
  error: PropTypes.string as PropTypes.Validator<string | undefined>,
  builderValue: PropTypes.string as PropTypes.Validator<string>,
  notice: PropTypes.string as PropTypes.Requireable<string>,
}

function HeightInputFormBuilder({
  builderLabel,
  builderValue,
  onInputChange,
  error,
  notice,
}: InferProps<typeof HeightInputFormBuilder.propTypes>) {
  const [heightValues, setHeightValues] = useState({
    feet: '',
    inches: '',
  })

  useEffect(() => {
    if (builderValue) {
      const heightValues = builderValue.split(' ')
      const feet = heightValues[0]
      const inches = heightValues[1]

      setHeightValues({
        feet: feet?.replace(/[^0-9]/g, '') || '',
        inches: inches?.replace(/[^0-9]/g, '') || '',
      })
    }
  }, [builderLabel.id, builderValue])

  const handleHeightInputChange = (event: ChangeEvent) => {
    const { value, name } = event.target

    if (!value.match('^[0-9]*$')) {
      return
    }

    setHeightValues((height) => {
      const heightValues = Object.values({ ...height, [name]: value })

      if (
        heightValues &&
        heightValues.length === 2 &&
        heightValues.every((height) => !isEmpty(height))
      ) {
        const feet = parseInt(heightValues[0])
        const inches = parseInt(heightValues[1])

        const height = `${feet}ft ${inches}${inches > 1 ? 'inches' : 'inch'}`

        onInputChange({ questionKey: builderLabel?.id, answers: [height] })
      } else {
        onInputChange({ questionKey: builderLabel?.id, answers: [''] })
      }

      return { ...height, [name]: value }
    })
  }

  return (
    <>
      <AlertPanel>
        <img src={icons.iconAlertInfoWarning} alt={'alert warning'} />
        We need to calculate your BMI. Your BMI can affect the effectiveness of birth control
      </AlertPanel>

      <QuestionPanel>
        <label htmlFor={builderLabel?.id}>{builderLabel?.label}</label>
      </QuestionPanel>
      {/* {notice && <TextNotice>{notice}</TextNotice>} */}

      <OptionsPanel>
        <FormsCol>
          <HeightInput
            value={heightValues.feet}
            name={'feet'}
            placeholder={'Feet'}
            onChange={handleHeightInputChange}
            maxLength={2}
          />
          <HeightInput
            value={heightValues.inches}
            name={'inches'}
            placeholder={'Inches'}
            onChange={handleHeightInputChange}
            maxLength={2}
          />
        </FormsCol>
        {error && !isEmpty(error) && <ErrorNotice>{error}</ErrorNotice>}
      </OptionsPanel>
    </>
  )
}

export default HeightInputFormBuilder
