import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import PropTypes, { InferProps } from 'prop-types'
import { Wrapper, ChatInput, AttachBtn } from './ChatConvo.style'
import { AuthUser, UserAssignedGuide } from '../../context/authContext'
import {
  Conversation,
  getMessages,
  listenToGuideMessage,
  loadMoreMessages,
  Message,
  MessageType,
  randomTempMsgIdGenerator,
  readMessage,
  resendMessage,
  sendMessage,
  useMessageContext,
} from '../../context/msgContext'
import { CHAT_LIMIT } from '../../utils/constants'
import { ChatBubble, Icon, Loader } from '../index'
import PhotoViewer from '../PhotoViewer/PhotoViewer'
import readImageFile from '../../utils/readImageFile'

const LOAD_MESSAGE_INTERVAL = 3000

ChatConvo.propTypes = {
  authUser: PropTypes.object as PropTypes.Validator<AuthUser>,
  guide: PropTypes.object as PropTypes.Validator<UserAssignedGuide>,
  conversation: PropTypes.object as PropTypes.Validator<Conversation>,
}

function ChatConvo({ authUser, conversation, guide }: InferProps<typeof ChatConvo.propTypes>) {
  const [message, setMessage] = useState('')
  const [showPhotoViewer, setShowPhotoViewer] = useState(false)
  const [attachmentDataURL, setAttachmentDataURL] = useState('')
  const [attachmentDataFile, setAttachmentDataFile] = useState<File>()
  const [isLoadingMessages, setIsLoadingMessages] = useState(true)
  const [page, setPage] = useState(1)
  const lastMessageRef = useRef(null)

  const {
    state: { messages, groupedMessages, tempMessageId },
    dispatch,
  } = useMessageContext()

  useEffect(() => {
    ;(async function loadMessages() {
      await getMessages(dispatch, conversation.id, CHAT_LIMIT)
      setIsLoadingMessages(false)
    })()
  }, [conversation.id, dispatch])

  useEffect(() => {
    readMessage(dispatch, conversation.id)
  }, [conversation.id, dispatch])

  useEffect(() => {
    if (page !== 1) return
    scrollToLastMessage()
  }, [messages, groupedMessages, page])

  useEffect(() => {
    const interval = setInterval(async () => {
      await listenToGuideMessage(dispatch, messages, conversation.id, guide.id)
    }, LOAD_MESSAGE_INTERVAL)
    return () => clearInterval(interval)
  }, [dispatch, conversation.id, messages, guide.id])

  const scrollToLastMessage = () => {
    const lastMessageElement = lastMessageRef.current as any

    if (!lastMessageElement) return

    lastMessageElement.scrollIntoView()
  }

  const handleSendMessage = async (
    message: { content: string; type: MessageType },
    attachment: File | undefined = undefined
  ) => {
    if (!message.content) {
      return
    }
    // There's a message being sent so wait
    if (tempMessageId) {
      return
    }
    setMessage('')
    const tempId = randomTempMsgIdGenerator()
    await sendMessage(dispatch, messages, conversation.id, authUser, message, attachment, tempId)
  }

  const handleResendMessage = async (message: Message) => {
    await resendMessage(dispatch, messages, message, authUser)
  }

  const handleLoadMoreMessages = async (onStopFetching: any) => {
    await loadMoreMessages(dispatch, messages, conversation.id, CHAT_LIMIT, page)
    setPage((page) => page + 1)
    onStopFetching(false)
  }

  const handleInputAttachmentUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length > 0) {
      setAttachmentDataFile(files[0])
      setAttachmentDataURL(await readImageFile(files[0]))
      if (files[0].type.includes('image')) {
        setShowPhotoViewer(true)
      }
    }
  }

  const handleDropAttachmentUpload = async (event: any) => {
    const files = [...event.dataTransfer.files]
    if (files && files.length > 0) {
      setAttachmentDataFile(files[0])
      setAttachmentDataURL(await readImageFile(files[0]))
      if (files[0].type.includes('image')) {
        setShowPhotoViewer(true)
      }
    }
  }

  if (isLoadingMessages) return <Loader guide={guide} />

  return (
    <>
      <Wrapper>
        <ChatBubble
          authUser={authUser}
          messages={messages}
          groupedMessages={groupedMessages}
          lastMessageRef={lastMessageRef}
          tempMessageId={tempMessageId}
          onResendMessage={handleResendMessage}
          onLoadMoreMessage={(onStopFetching: any) => handleLoadMoreMessages(onStopFetching)}
          onDropAttachmentFile={handleDropAttachmentUpload}
        />
        <div className={'chat-input__wrapper'}>
          <ChatInput
            setFocus={true}
            value={message}
            name={'messageText'}
            placeholder={'Type your message here....'}
            onChange={(event) => setMessage(event.currentTarget.value)}
            onKeyPress={async (event) => {
              if (event.key === 'Enter') {
                event.preventDefault()
                await handleSendMessage({
                  content: message,
                  type: 'TEXT',
                })
              }
            }}
            onSendPress={() =>
              handleSendMessage({
                content: message,
                type: 'TEXT',
              })
            }
            onResize={() => false}
            error={false}
          />
          <AttachBtn>
            <input
              type="file"
              name="attachPhoto"
              accept="image/*"
              onChange={(event) => handleInputAttachmentUpload(event)}
            />
            <Icon id={'paperclip'} className={'attachment-icon__paperclip'} />
          </AttachBtn>
        </div>
      </Wrapper>

      {showPhotoViewer && attachmentDataURL && (
        <PhotoViewer
          onCloseModal={() => setShowPhotoViewer(false)}
          showModal={showPhotoViewer}
          photoDataURL={attachmentDataURL}
          onSendPhoto={() =>
            handleSendMessage(
              {
                content: attachmentDataURL,
                type: 'IMAGE',
              },
              attachmentDataFile
            )
          }
        />
      )}
    </>
  )
}

export default ChatConvo
