import React from 'react'
import { PhoneInputWrapper } from './PhoneNumberInput.styles'
import PropTypes, { InferProps } from 'prop-types'

type ChangeEvent = (value: string) => void

type KeyPressEvent = (event: React.KeyboardEvent<string>) => void

type BlurEvent = () => void

PhoneNumberInput.defaultProps = {
  defaultCountry: 'US',
  placeholder: 'Phone number',
  disabled: false,
  onBlur: undefined,
  onKeyPress: undefined,
  error: false,
}

PhoneNumberInput.propTypes = {
  width: PropTypes.number,
  name: PropTypes.string.isRequired,
  value: PropTypes.string as PropTypes.Validator<string>,
  defaultCountry: PropTypes.string as PropTypes.Validator<string>,
  error: PropTypes.bool,
  placeholder: PropTypes.string as PropTypes.Validator<string>,
  className: PropTypes.string,
  disabled: PropTypes.bool as PropTypes.Validator<boolean>,
  onBlur: PropTypes.func as PropTypes.Validator<BlurEvent>,
  onChange: PropTypes.func.isRequired as PropTypes.Validator<ChangeEvent>,
  onKeyPress: PropTypes.func as PropTypes.Validator<KeyPressEvent>,
}

function PhoneNumberInput({
  width,
  name,
  value,
  defaultCountry,
  error,
  placeholder,
  className,
  disabled,
  onBlur,
  onChange,
  onKeyPress,
}: InferProps<typeof PhoneNumberInput.propTypes>) {
  return (
    <PhoneInputWrapper
      className={className || ''}
      width={width || undefined}
      onBlur={onBlur}
      name={name}
      id={name}
      disabled={disabled}
      defaultCountry={defaultCountry}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onKeyPress={onKeyPress}
      hasError={error}
    />
  )
}

export default PhoneNumberInput
