import React, { RefObject, useRef, useState } from 'react'
import PropTypes, { InferProps } from 'prop-types'
import { Wrapper, InChatBubble, OutChatBubble, ChatMedia, ChatLink } from './ChatBubble.style'
import { AuthUser } from '../../context/authContext'
import { Message } from '../../context/msgContext'
import { Icon, LinkDropdown } from '../index'
import { formatMessageText } from '../../utils/formatMessageText'
import { formatMessageTimestamp } from '../../utils/formatMessageTimestamp'
import DOMPurify from 'dompurify'
import useInfiniteScroll from '../../hooks/useInfiniteScroll'
import { paths } from '../../routes'

ChatBubble.propTypes = {
  authUser: PropTypes.object as PropTypes.Validator<AuthUser>,
  messages: PropTypes.object as PropTypes.Validator<Array<Message>>,
  groupedMessages: PropTypes.object as PropTypes.Validator<Array<[string, Message[]]>>,
  lastMessageRef: PropTypes.object as PropTypes.Validator<RefObject<any>>,
  tempMessageId: PropTypes.string as PropTypes.Validator<string>,
  onResendMessage: PropTypes.func as PropTypes.Validator<(message: Message) => void>,
  onLoadMoreMessage: PropTypes.func as PropTypes.Validator<(onStopFetching: any) => void>,
  onDropAttachmentFile: PropTypes.func as PropTypes.Validator<(event: any) => void>,
}

function ChatBubble({
  authUser,
  messages,
  groupedMessages,
  lastMessageRef,
  tempMessageId,
  onResendMessage,
  onLoadMoreMessage,
  onDropAttachmentFile,
}: InferProps<typeof ChatBubble.propTypes>) {
  const scrollableRef = useRef(null)
  const [showMessageOptions, setShowMessageOptions] = useState('')
  const [isFetching, setIsFetching] = useInfiniteScroll(onLoadMoreMessage, scrollableRef, true)
  const [isDraggingOver, setIsDraggingOver] = useState(false)

  const preventDragDropDefaults = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDragOver = (event: any) => {
    preventDragDropDefaults(event)
    setIsDraggingOver(true)
  }

  const handleDragEnter = (event: any) => {
    preventDragDropDefaults(event)
    setIsDraggingOver(true)
  }

  const handleDragLeave = (event: any) => {
    preventDragDropDefaults(event)
    setIsDraggingOver(false)
  }

  const handleDrop = (event: any) => {
    preventDragDropDefaults(event)
    setIsDraggingOver(false)
    onDropAttachmentFile(event)
  }

  return (
    <Wrapper
      ref={scrollableRef}
      isDraggingOver={isDraggingOver}
      onDrop={(event) => handleDrop(event)}
      onDragOver={(event) => handleDragOver(event)}
      onDragEnter={(event) => handleDragEnter(event)}
      onDragLeave={(event) => handleDragLeave(event)}
    >
      {isFetching && (
        <div className={'chat-msg-group-loader'}>
          <span>
            <Icon id={'loader'} className={'chat-loader__icon'} />
          </span>
        </div>
      )}

      {groupedMessages &&
        groupedMessages.map((groupMessage, index) => (
          <div key={index} className={'chat-msg-group'}>
            <div className={'chat-msg-group-date__wrapper'}>
              <span>{groupMessage[0]}</span>
            </div>

            {groupMessage[1]
              ? groupMessage[1].map((message, index) => {
                  const assignRef = () =>
                    index === groupMessage[1].length - 1 ? lastMessageRef : null
                  return (
                    <>
                      {message.type === 'IMAGE' ||
                      message.type === 'VIDEO' ||
                      message.type === 'FILE' ? (
                        <ChatMedia
                          ref={assignRef()}
                          className={
                            message.userId === authUser.id ? 'outgoing-chat' : 'incoming-chat'
                          }
                        >
                          <img src={message.content} alt="" className="chat-image" />

                          {message.userId === authUser.id ? (
                            <span className="chat-timestamp">
                              {tempMessageId === message.id ? (
                                <Icon id={'clock'} className={'chat-clock__icon'} />
                              ) : message.hasError ? (
                                <Icon id={'exclamationCircle'} className={'chat-error__icon'} />
                              ) : (
                                <>
                                  {formatMessageTimestamp(message.createdAt)}
                                  <Icon id={'chatStatusIcon'} className={'chat-status__icon'} />
                                </>
                              )}
                            </span>
                          ) : (
                            <span className="chat-timestamp">
                              {formatMessageTimestamp(message.createdAt)}
                            </span>
                          )}

                          {message.userId === authUser.id && (
                            <button aria-label="Message options" className="chat-options">
                              <Icon id={'chatDropArrow'} className="chat-options__icon" />
                            </button>
                          )}
                        </ChatMedia>
                      ) : message.userId === authUser.id ? (
                        <OutChatBubble ref={assignRef()}>
                          {formatMessageText(message.content).url &&
                            formatMessageText(message.content).url.map((url, index) => (
                              <ChatLink key={index} url={url} />
                            ))}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                formatMessageText(message.content).text || '',
                                {
                                  ADD_ATTR: ['target'],
                                }
                              ),
                            }}
                          />
                          {(message.type === 'QUESTIONNAIRE' || message.type === 'LAB-REPORT') && (
                            <a
                              href={`${paths.BIRTH_CONTROL_HISTORY_URL_PATH}?visitId=${message.patientQuestionnaireId}`}
                              className="chat-view__more"
                            >
                              View...
                            </a>
                          )}
                          <span className="chat-filler" />
                          <span className="chat-timestamp">
                            {tempMessageId === message.id ? (
                              <Icon id={'clock'} className={'chat-clock__icon'} />
                            ) : message.hasError ? (
                              <Icon id={'exclamationCircle'} className={'chat-error__icon'} />
                            ) : (
                              <>
                                {formatMessageTimestamp(message.createdAt)}
                                <Icon id={'chatStatusIcon'} className={'chat-status__icon'} />
                              </>
                            )}
                          </span>
                          {message.hasError && (
                            <LinkDropdown
                              className={'chat-options'}
                              showDropdown={showMessageOptions === message.id}
                              setShowDropdown={() => setShowMessageOptions('')}
                              position={'right'}
                              label={
                                <button
                                  onClick={() => setShowMessageOptions(message.id)}
                                  aria-label="Message options"
                                  className={`chat-options__btn ${
                                    showMessageOptions === message.id ? 'opened' : ''
                                  }`}
                                >
                                  <Icon id={'chatDropArrow'} className="chat-options__icon" />
                                </button>
                              }
                              options={[
                                {
                                  label: `Resend`,
                                  path: '#',
                                  onOptionClicked: () => onResendMessage(message),
                                },
                                {
                                  label: `Delete`,
                                  path: '#',
                                  onOptionClicked: () => false,
                                },
                              ]}
                            />
                          )}
                        </OutChatBubble>
                      ) : (
                        <InChatBubble ref={assignRef()}>
                          {formatMessageText(message.content).url &&
                            formatMessageText(message.content).url.map((url, index) => (
                              <ChatLink key={index} url={url} />
                            ))}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                formatMessageText(message.content).text || '',
                                {
                                  ADD_ATTR: ['target'],
                                }
                              ),
                            }}
                          />
                          {(message.type === 'QUESTIONNAIRE' || message.type === 'LAB-REPORT') && (
                            <a
                              href={`${paths.BIRTH_CONTROL_HISTORY_URL_PATH}?visitId=${message.patientQuestionnaireId}`}
                              className="chat-view__more"
                            >
                              View...
                            </a>
                          )}
                          <span className="chat-filler" />
                          <span className="chat-timestamp">
                            {formatMessageTimestamp(message.createdAt)}
                          </span>
                        </InChatBubble>
                      )}
                    </>
                  )
                })
              : ''}
          </div>
        ))}
    </Wrapper>
  )
}

export default ChatBubble
