import React, { useEffect, useRef, useState } from 'react'
import PropTypes, { InferProps } from 'prop-types'
import * as H from 'history'
import {
  ModalBody,
  Wrapper,
  BackAction,
  NavWrapper,
  Container,
  LoonBag,
  Button,
} from './HealthQuestionsModal.style'
import { icons } from '../../assets/icons'
import { images } from '../../assets/images'
import {
  append,
  findIndex,
  isEmpty,
  pluck,
  prop,
  propEq,
  reject,
  sortBy,
  uniq,
  update,
} from 'ramda'
import { paths } from '../../routes'
import {
  Answer,
  getVisitTypeQuestions,
  Question,
  sendAnswers,
  useQuestionnaireContext,
  VisitType,
} from '../../context/quesContext'
import {
  BPInputFormBuilder,
  CheckBoxFormBuilder,
  FileUploadBuilder,
  HeightInputFormBuilder,
  InputFormBuilder,
  RadioInputFormBuilder,
  TextBoxFormBuilder,
  WeightInputFormBuilder,
} from '../index'
import {
  BIRTH_CONTROL_FILTER_PAST_PRES_QUESTIONNAIRES,
  BIRTH_CONTROL_FILTER_SMOKER_QUESTIONNAIRES,
} from '../../utils/constants'

HealthQuestionsModal.propTypes = {
  location: PropTypes.object as PropTypes.Validator<H.Location>,
  token: PropTypes.string as PropTypes.Validator<string>,
  type: PropTypes.string as PropTypes.Validator<VisitType>,
  onCloseVisitModal: PropTypes.func as PropTypes.Validator<() => void>,
  onCompleteQuestions: PropTypes.func as PropTypes.Validator<() => void>,
}

function HealthQuestionsModal({
  location,
  token,
  type,
  onCloseVisitModal,
  onCompleteQuestions,
}: InferProps<typeof HealthQuestionsModal.propTypes>) {
  const [pageQuestions, setPageQuestions] = useState<Array<Question>>([])
  const [providedAnswers, setProvidedAnswers] = useState<Array<Answer>>([])
  const [unAnsweredQuestions, setUnAnsweredQuestions] = useState<Array<string> | null>(null)
  const [unUsedQuestions, setUnUsedQuestions] = useState<Array<string>>([])
  const [disableButton, setDisableButton] = useState(true)

  const topScroll = useRef(null)

  const loggedInGetStartedPaths = [paths.MEDICAL_QUESTIONNAIRES_URL_PATH]

  const {
    state: { isLoadingQuestions, isSendingAnswers, questions },
    dispatch,
  } = useQuestionnaireContext()

  useEffect(() => {
    if (token) {
      ;(async function fetchQuestions(token: string) {
        await getVisitTypeQuestions(dispatch, type, token)
      })(token)
    }
  }, [dispatch, token, type])

  useEffect(() => {
    setPageQuestions(sortBy(prop('priority'), questions).slice(0, 1) as Array<Question>)
  }, [questions])

  useEffect(() => {
    const questions = pluck('id', pageQuestions)
    const answers = pluck('questionKey', providedAnswers)

    if (questions.length > 0 && answers.length > 0) {
      setDisableButton(!answers.some((ans) => questions.includes(ans)))
    }
  }, [providedAnswers, pageQuestions])

  const handleBuilderInputsChange = (answer: Answer) => {
    // Filter out some questions
    if (answer.questionKey === BIRTH_CONTROL_FILTER_SMOKER_QUESTIONNAIRES[0]) {
      if (answer?.answers[0] === 'NO') {
        const smokeFrequencyKey = BIRTH_CONTROL_FILTER_SMOKER_QUESTIONNAIRES[1]
        const smokeFrequency = { questionKey: smokeFrequencyKey, answers: ['None'] }
        setProvidedAnswers((answers) => {
          const tempAnsIndex = findIndex(propEq('questionKey', smokeFrequencyKey))(answers)
          if (tempAnsIndex === -1) {
            return append(smokeFrequency, answers)
          }
          return update(tempAnsIndex, smokeFrequency, answers)
        })
        setUnUsedQuestions((ques) => uniq([...ques, smokeFrequencyKey]))
        setPageQuestions((questions) => {
          return questions.filter((question) => question.id === answer.questionKey)
        })
      }
    }

    if (answer.questionKey === BIRTH_CONTROL_FILTER_PAST_PRES_QUESTIONNAIRES[0]) {
      if (answer?.answers[0] === 'NO') {
        const pastPresKey = BIRTH_CONTROL_FILTER_PAST_PRES_QUESTIONNAIRES[1]
        const pastPrescription = { questionKey: pastPresKey, answers: ['None'] }
        setProvidedAnswers((answers) => {
          const tempAnsIndex = findIndex(propEq('questionKey', pastPresKey))(answers)
          if (tempAnsIndex === -1) {
            return append(pastPrescription, answers)
          }
          return update(tempAnsIndex, pastPrescription, answers)
        })
        setUnUsedQuestions((ques) => uniq([...ques, pastPresKey]))
        setPageQuestions((questions) => {
          return questions.filter((question) => question.id === answer.questionKey)
        })
      }
    }

    setProvidedAnswers((answers) => {
      const tempAnsIndex = findIndex(propEq('questionKey', answer.questionKey))(answers)
      if (tempAnsIndex === -1) {
        return append(answer, answers)
      }
      return update(tempAnsIndex, answer, answers)
    })

    setUnAnsweredQuestions((unAnsQuestions) => {
      if (!unAnsQuestions || unAnsQuestions.length === 0) {
        return null
      }
      return reject((unAnswered) => unAnswered === answer.questionKey, unAnsQuestions)
    })
  }

  const handleBuilderValue = (questionId: string) => {
    let ansValue = ''
    if (providedAnswers && providedAnswers.length > 0) {
      ansValue =
        providedAnswers.find((answer) => answer.questionKey === questionId)?.answers[0] || ''
    }
    return ansValue
  }

  const handleBuilderMultiValues = (questionId: string) => {
    let ansValue: Array<string> = []
    if (providedAnswers && providedAnswers.length > 0) {
      ansValue = providedAnswers.find((answer) => answer.questionKey === questionId)?.answers || []
    }
    return ansValue
  }

  const handleNextQuestion = () => {
    const inputQuestionKeys = pluck('id', pageQuestions)

    if (!providedAnswers || providedAnswers.length === 0) {
      setUnAnsweredQuestions(inputQuestionKeys)
      return
    }

    const unAnsweredQuestions = inputQuestionKeys.filter(
      (questionKey) =>
        !providedAnswers.find(
          (answer) =>
            answer.questionKey === questionKey &&
            answer.answers.filter((ans) => !isEmpty(ans)).length > 0
        )
    )

    if (unAnsweredQuestions.length > 0) {
      setUnAnsweredQuestions(unAnsweredQuestions)
      return
    }

    if (questions.length === providedAnswers.length) {
      // SEND TO SERVER
      token && sendAnswers(dispatch, type, questions, providedAnswers, token, onCompleteQuestions)
      return
    }

    const sortedQuestions = sortBy(
      prop('priority'),
      questions.filter((ques) => !unUsedQuestions.includes(ques.id))
    )

    let lastQuestionOnPage = pageQuestions[pageQuestions.length - 1]

    if (unUsedQuestions.includes(lastQuestionOnPage.id)) {
      lastQuestionOnPage = pageQuestions[pageQuestions.length - 2]
    }

    const findIndexOfLastQuestion = findIndex(propEq('id', lastQuestionOnPage.id))(sortedQuestions)

    let getNextQuestions = sortedQuestions
      .filter((ques, index) => index > findIndexOfLastQuestion)
      .slice(0, 2)

    const hasMoreChildren = isASinglePageQuestions(getNextQuestions)

    if (hasMoreChildren) {
      getNextQuestions = getNextQuestions.slice(0, 1)
    }

    const topScrollElement = topScroll?.current as any

    topScrollElement && topScrollElement.scrollIntoView(true)

    setPageQuestions(getNextQuestions)
  }

  const handleGoBack = () => {
    const sortedQuestions = sortBy(prop('priority'), questions)

    const firstQuestionOnPage = pageQuestions[0]

    const findIndexOfFirstQuestion = findIndex(propEq('id', firstQuestionOnPage.id))(
      sortedQuestions
    )

    if (findIndexOfFirstQuestion === 0) {
      onCloseVisitModal()
      return
    }

    const prevLastQuestionIndex = findIndexOfFirstQuestion - 1

    let filteredSortedQuestions = sortedQuestions
      .filter((ques, index) => index <= prevLastQuestionIndex)
      .slice(-2)

    const hasMoreChildren = isASinglePageQuestions(filteredSortedQuestions)

    if (hasMoreChildren) {
      filteredSortedQuestions = filteredSortedQuestions.slice(-1)
    }

    setPageQuestions(filteredSortedQuestions)
  }

  const isASinglePageQuestions = (questions: Array<Question>) => {
    return questions.some((ques) => {
      const isMultipleChoice =
        ques.type === 'MULTIPLE-CHOICE' && ques?.children && ques?.children?.length > 3
      const isBinary = ques.type === 'BINARY' && ques?.children && ques?.children?.length > 3
      const isOpenImage = ques.type === 'OPEN-IMAGE'

      return isMultipleChoice || isOpenImage || isBinary
    })
  }

  return (
    <>
      <Wrapper size={'xl'} show={true} onHide={() => false} animation={false}>
        <NavWrapper>
          <BackAction to="#" onClick={handleGoBack}>
            <img className="back" src={icons.iconBackArrowLeft} alt={'back'} />
            Back
          </BackAction>
        </NavWrapper>
        <ModalBody ref={topScroll}>
          <Container>
            <p className={`container-title`}>{`MEDICAL QUESTIONS`}</p>
            <p
              className={`container-subtitle`}
            >{`Help us prescribe the right birth control for you.`}</p>
            <div className={'questions-wrapper'}>
              {pageQuestions.map((question) => {
                switch (question.type) {
                  case 'OPEN-TEXT':
                    return (
                      <InputFormBuilder
                        key={question.id}
                        builderValue={handleBuilderValue(question.id)}
                        onInputChange={handleBuilderInputsChange}
                        notice={question?.notice}
                        error={
                          unAnsweredQuestions?.includes(question.id) ? question?.error : undefined
                        }
                        builderLabel={{
                          id: `${question.id}`,
                          label: `${question.option}`,
                        }}
                        builderInputs={{
                          name: `${question.id}`,
                          placeholder: '',
                        }}
                      />
                    )
                  case 'BINARY':
                  case 'BINARY-SELECT':
                    return (
                      <RadioInputFormBuilder
                        key={question.id}
                        builderValue={handleBuilderValue(question.id)}
                        onInputChange={handleBuilderInputsChange}
                        notice={question?.notice}
                        error={
                          unAnsweredQuestions?.includes(question.id) ? question?.error : undefined
                        }
                        builderLabel={{
                          id: `${question.id}`,
                          label: `${question.option}`,
                        }}
                        builderInputs={question.children}
                      />
                    )
                  case 'MULTIPLE-CHOICE':
                    return (
                      <CheckBoxFormBuilder
                        key={question.id}
                        builderValue={handleBuilderMultiValues(question.id)}
                        onInputChange={handleBuilderInputsChange}
                        notice={question?.notice}
                        error={
                          unAnsweredQuestions?.includes(question.id) ? question?.error : undefined
                        }
                        builderLabel={{
                          id: `${question.id}`,
                          label: `${question.option}`,
                        }}
                        builderInputs={question.children}
                      />
                    )
                  case 'OPEN-HEIGHT':
                    return (
                      <HeightInputFormBuilder
                        key={question.id}
                        builderValue={handleBuilderValue(question.id)}
                        onInputChange={handleBuilderInputsChange}
                        notice={question?.notice}
                        error={
                          unAnsweredQuestions?.includes(question.id) ? question?.error : undefined
                        }
                        builderLabel={{
                          id: `${question.id}`,
                          label: `${question.option}`,
                        }}
                        builderInputs={{
                          name: `${question.id}`,
                        }}
                      />
                    )
                  case 'OPEN-WEIGHT':
                    return (
                      <WeightInputFormBuilder
                        key={question.id}
                        builderValue={handleBuilderValue(question.id)}
                        onInputChange={handleBuilderInputsChange}
                        notice={question?.notice}
                        error={
                          unAnsweredQuestions?.includes(question.id) ? question?.error : undefined
                        }
                        builderLabel={{
                          id: `${question.id}`,
                          label: `${question.option}`,
                        }}
                        builderInputs={{
                          name: `${question.id}`,
                          placeholder: 'In lbs',
                        }}
                      />
                    )
                  case 'OPEN-BLOOD-PRESSURE':
                    return (
                      <BPInputFormBuilder
                        key={question.id}
                        builderValue={handleBuilderMultiValues(question.id)}
                        onInputChange={handleBuilderInputsChange}
                        notice={question?.notice}
                        error={
                          unAnsweredQuestions?.includes(question.id) ? question?.error : undefined
                        }
                        builderLabel={{
                          id: `${question.id}`,
                          label: `${question.option}`,
                        }}
                        builderInputs={question.children}
                      />
                    )
                  case 'OPEN-TEXTAREA':
                    return (
                      <TextBoxFormBuilder
                        key={question.id}
                        builderValue={handleBuilderValue(question.id)}
                        onInputChange={handleBuilderInputsChange}
                        notice={question?.notice}
                        error={
                          unAnsweredQuestions?.includes(question.id) ? question?.error : undefined
                        }
                        builderLabel={{
                          id: `${question.id}`,
                          label: `${question.option}`,
                        }}
                        builderInputs={{
                          name: `${question.id}`,
                          placeholder: '',
                        }}
                      />
                    )
                  case 'OPEN-IMAGE':
                    return (
                      <FileUploadBuilder
                        key={question.id}
                        builderValue={handleBuilderMultiValues(question.id)}
                        onFileChange={handleBuilderInputsChange}
                        error={
                          unAnsweredQuestions?.includes(question.id) ? question?.error : undefined
                        }
                        notice={question?.notice}
                        builderLabel={{
                          id: `${question.id}`,
                          label: `${question.option}`,
                        }}
                        builderInputs={question.children}
                      />
                    )
                  default:
                    return null
                }
              })}
              <Button
                label={'Save and Continue'}
                type={'button-primary'}
                onClick={() => handleNextQuestion()}
                disabled={disableButton || isSendingAnswers}
                loading={isSendingAnswers}
              />
            </div>
          </Container>
          {loggedInGetStartedPaths.includes(location.pathname) && (
            <LoonBag>
              <img src={images.imageLoonBag} alt={'loon package bag'} />
            </LoonBag>
          )}
        </ModalBody>
      </Wrapper>
    </>
  )
}

export default HealthQuestionsModal
