import styled from 'styled-components/macro'
import 'react-phone-number-input/style.css'
import PinInput from 'react-pin-input'

type InputWrapperProps = {
  error?: boolean | number | null
  width?: number | null
}

export const PinInputWrapper = styled(PinInput)<InputWrapperProps>``
