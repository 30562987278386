import React, { useState } from 'react'
import {
  Wrapper,
  BrandLogo,
  MenuWrapper,
  MenuItems,
  NotifyBadge,
  BackAction,
  MenuProfile,
} from './Navigation.style'
import PropTypes, { InferProps } from 'prop-types'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { icons } from '../../assets/icons'
import { paths } from '../../routes'
import { LinkDropdown } from '../index'
import { AuthUser } from '../../context/authContext'

Navigation.defaultProps = {}

Navigation.propTypes = {
  onSignOut: PropTypes.func.isRequired as PropTypes.Validator<() => void>,
  authUser: PropTypes.object as PropTypes.Validator<AuthUser | undefined>,
  hasNewMessage: PropTypes.bool,
}

function Navigation({
  onSignOut,
  authUser,
  hasNewMessage,
}: InferProps<typeof Navigation.propTypes>) {
  const [showDropdown, setShowDropdown] = useState(false)
  const [showProfileDropdown, setShowProfileDropdown] = useState(false)

  const history = useHistory()

  const location = useLocation()

  const pathsToHideMenu = [paths.MESSAGE_URL_PATH, paths.PROFILE_URL_PATH]

  const pathsToHideNotify = [paths.MESSAGE_URL_PATH]

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <Wrapper>
      {!pathsToHideMenu.includes(location.pathname) ? (
        <BrandLogo to={paths.HOME_URL_PATH}>
          <img src={icons.iconLoonLogo} alt={'loon logo'} />
        </BrandLogo>
      ) : (
        <BackAction onClick={handleGoBack}>
          <img className="back" src={icons.iconBackArrowLeft} alt={'back'} />
          Back
        </BackAction>
      )}

      <MenuWrapper>
        {!pathsToHideMenu.includes(location.pathname) && (
          <MenuItems>
            <Link
              to={paths.HOME_URL_PATH}
              className={location.pathname === paths.HOME_URL_PATH ? 'active' : ''}
            >
              Home
            </Link>
            <Link
              to={paths.MESSAGE_URL_PATH}
              className={location.pathname === paths.MESSAGE_URL_PATH ? 'active' : ''}
            >
              Your Provider
            </Link>
            <LinkDropdown
              showDropdown={showDropdown}
              setShowDropdown={setShowDropdown}
              label={
                <a
                  className={
                    location.pathname === paths.BIRTH_CONTROL_HISTORY_URL_PATH ? 'active' : ''
                  }
                  role="button"
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  Previous Visits
                </a>
              }
              options={[
                {
                  label: 'Birth Control Visit',
                  path: paths.BIRTH_CONTROL_HISTORY_URL_PATH,
                  onOptionClicked: () => false,
                },
              ]}
            />
          </MenuItems>
        )}

        <MenuProfile>
          <NotifyBadge
            onClick={() => history.push(paths.MESSAGE_URL_PATH)}
            className={pathsToHideNotify.includes(location.pathname) ? 'invisible' : ''}
          >
            <img src={icons.iconNotificationBellV2} alt="notify" />
            {hasNewMessage && <span></span>}
          </NotifyBadge>
          <LinkDropdown
            className={'profile-dropdown'}
            showDropdown={showProfileDropdown}
            setShowDropdown={setShowProfileDropdown}
            label={
              <span
                data-initials={
                  authUser ? `${authUser.firstName.charAt(0)}${authUser.lastName.charAt(0)}` : ''
                }
                onClick={() => setShowProfileDropdown(!showProfileDropdown)}
              >
                <span>{authUser ? `${authUser.firstName} ${authUser.lastName}` : ''}</span>
              </span>
            }
            options={[
              {
                label: 'Profile',
                path: paths.PROFILE_URL_PATH,
                onOptionClicked: () => false,
              },
              {
                label: 'Log Out',
                path: undefined,
                onOptionClicked: () => onSignOut(),
              },
            ]}
          />
        </MenuProfile>
      </MenuWrapper>

      {/* <NavigationToggle */}
      {/*  className="navbar-toggler" */}
      {/*  type="button" */}
      {/*  data-toggle="collapse" */}
      {/*  data-target="#navbarSupportedContent" */}
      {/*  aria-controls="navbarSupportedContent" */}
      {/*  aria-expanded="false" */}
      {/*  aria-label="Toggle navigation" */}
      {/* > */}
      {/*  <span className="navbar-toggler-icon"></span> */}
      {/* </NavigationToggle> */}
    </Wrapper>
  )
}

export default Navigation
