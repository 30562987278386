import React from 'react'
import { PinInputWrapper } from './PinInput.styles'
import PropTypes, { InferProps } from 'prop-types'

type ChangeEvent = (value: string) => void

type CompleteEvent = (value: string, index: number) => void

type InputType = 'numeric' | 'custom'

PinInput.defaultProps = {
  type: 'numeric',
  length: 4,
  focus: true,
  secret: true,
  ref: undefined,
  onComplete: (value: string, index: number) => false,
}

PinInput.propTypes = {
  type: PropTypes.string.isRequired as PropTypes.Validator<InputType>,
  length: PropTypes.number.isRequired as PropTypes.Validator<number>,
  error: PropTypes.bool,
  width: PropTypes.number,
  focus: PropTypes.bool as PropTypes.Validator<boolean>,
  secret: PropTypes.bool as PropTypes.Validator<boolean>,
  onChange: PropTypes.func.isRequired as PropTypes.Validator<ChangeEvent>,
  onComplete: PropTypes.func as PropTypes.Validator<CompleteEvent>,
}

function PinInput({
  type,
  length,
  focus,
  secret,
  onChange,
  onComplete,
  error,
  width,
}: InferProps<typeof PinInput.propTypes>) {
  return (
    <PinInputWrapper
      type={type}
      length={length}
      focus={focus}
      secret={secret}
      onChange={onChange}
      onComplete={onComplete}
      inputMode={type}
      error={error}
      width={width}
    />
  )
}

export default PinInput
