import React, { useState } from 'react'
import { ThumbImage, FullImage } from './Image.styles'
import PropTypes, { InferProps } from 'prop-types'

Image.defaultProps = {
  srcThumb: '',
  srcFull: '',
  alt: '',
  onClick: undefined,
  onError: undefined,
}

Image.propTypes = {
  srcThumb: PropTypes.string as PropTypes.Validator<string>,
  srcFull: PropTypes.string as PropTypes.Validator<string>,
  alt: PropTypes.string as PropTypes.Validator<string>,
  className: PropTypes.string,
  onClick: PropTypes.func as PropTypes.Validator<(value: any) => void>,
  onError: PropTypes.func as PropTypes.Validator<(event: React.UIEvent<HTMLImageElement>) => void>,
}

function Image({
  srcThumb,
  srcFull,
  alt,
  className,
  onClick,
  onError,
}: InferProps<typeof Image.propTypes>) {
  const [isLoaded, setIsLoaded] = useState(false)
  return (
    <>
      <ThumbImage
        isLoaded={isLoaded}
        onError={onError}
        onClick={onClick}
        className={`${className ? `${className} thumb` : 'thumb'}`}
        alt={alt}
        src={srcThumb}
      />
      <FullImage
        isLoaded={isLoaded}
        onError={onError}
        onLoad={() => {
          setIsLoaded(true)
        }}
        onClick={onClick}
        className={`${className ? `${className} full` : 'full'}`}
        alt={alt}
        src={srcFull}
      />
    </>
  )
}

export default Image
