import React from 'react'
import { Wrapper, Menu } from './BottomNavigation.style'
import { useLocation } from 'react-router-dom'
import { icons } from '../../assets/icons'
import { paths } from '../../routes'

BottomNavigation.defaultProps = {}

function BottomNavigation() {
  const location = useLocation()

  const pathsToHideMenu = [paths.MESSAGE_URL_PATH, paths.PROFILE_URL_PATH]

  return (
    <Wrapper className={!pathsToHideMenu.includes(location.pathname) ? 'show' : ''}>
      <Menu
        to={paths.HOME_URL_PATH}
        className={`home-menu ${location.pathname === paths.HOME_URL_PATH ? 'active' : ''}`}
      >
        <img src={icons.iconBottomNavHome} alt={'Home'} />
        Home
      </Menu>
      <Menu
        to={paths.MESSAGE_URL_PATH}
        className={`doctor-menu ${location.pathname === paths.MESSAGE_URL_PATH ? 'active' : ''}`}
      >
        <img src={icons.iconBottomNavMessages} alt={'Doctor'} />
        Doctor
      </Menu>
      <Menu
        to={paths.BIRTH_CONTROL_HISTORY_URL_PATH}
        className={`visits-menu ${
          location.pathname === paths.BIRTH_CONTROL_HISTORY_URL_PATH ? 'active' : ''
        }`}
      >
        <img src={icons.iconBottomNavVisits} alt={'Visits'} />
        Visits
      </Menu>
    </Wrapper>
  )
}

export default BottomNavigation
