import React, { useEffect, useState } from 'react'
import {
  QuestionPanel,
  ListCol,
  RadioItem,
  OptionsPanel,
  RadioLabel,
  ErrorNotice,
  TextNotice,
} from './RadioInputFormBuilder.styles'
import PropTypes, { InferProps } from 'prop-types'
import { isEmpty } from 'ramda'

type Answer = {
  questionKey: string
  answers: Array<string>
}

type ChangeEvent = React.ChangeEvent<HTMLInputElement>

export interface BuilderLabel {
  id: string
  label: string
}

export interface BuilderInputs {
  id: string
  priority: number
  option: string
}

RadioInputFormBuilder.defaultProps = {
  builderValue: '',
  notice: undefined,
}

RadioInputFormBuilder.propTypes = {
  builderLabel: PropTypes.object as PropTypes.Validator<BuilderLabel>,
  builderInputs: PropTypes.array.isRequired as PropTypes.Requireable<Array<BuilderInputs>>,
  onInputChange: PropTypes.func as PropTypes.Validator<(answers: Answer) => void>,
  error: PropTypes.string as PropTypes.Validator<string | undefined>,
  builderValue: PropTypes.string as PropTypes.Validator<string>,
  notice: PropTypes.string as PropTypes.Requireable<string>,
}

function RadioInputFormBuilder({
  builderLabel,
  builderInputs,
  builderValue,
  onInputChange,
  error,
  notice,
}: InferProps<typeof RadioInputFormBuilder.propTypes>) {
  const [checkedItem, setCheckedItem] = useState<string>('')

  useEffect(() => {
    setCheckedItem(builderValue)
  }, [builderLabel.id, builderValue])

  const handleRadioBoxChange = (event: ChangeEvent) => {
    const { value, checked } = event.target
    if (checked) {
      setCheckedItem(value)
      onInputChange({ questionKey: builderLabel?.id, answers: [value] })
    }
  }

  return (
    <>
      <QuestionPanel>
        <label htmlFor={builderLabel?.id}>{builderLabel?.label}</label>
      </QuestionPanel>
      {/* {notice && <TextNotice>{notice}</TextNotice>} */}
      <OptionsPanel>
        {builderInputs &&
          builderInputs.map((input, index) => (
            <ListCol key={index} checked={checkedItem === input.id}>
              <RadioItem
                size={'small'}
                id={`${builderLabel?.id}_${input.id}`}
                name={builderLabel?.id || ''}
                value={input.id}
                onChange={handleRadioBoxChange}
                checked={checkedItem === input.id}
              />
              <RadioLabel htmlFor={`${builderLabel?.id}_${input.id}`}>{input.option}</RadioLabel>
            </ListCol>
          ))}
      </OptionsPanel>
      {error && !isEmpty(error) && <ErrorNotice>{error}</ErrorNotice>}
    </>
  )
}

export default RadioInputFormBuilder
