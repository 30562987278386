import axios, { AxiosTransformer, Method } from 'axios'
import env from './env'
import toSnakeCase from 'snakecase-keys'
import toCamelCase from 'camelcase-keys'
import * as queryString from 'querystring'
import { getCookie } from './getCookie'
import { AuthToken } from '../context/authContext'
import { isEmpty } from 'ramda'

const objectToQueryString = (obj: any) => {
  const data: any = obj && toSnakeCase(obj, { deep: true })
  return queryString.stringify(data)
}

export const transformRequest = [
  (data: any) => {
    return data && toSnakeCase(data, { deep: true })
  },
  ...(axios.defaults.transformRequest as AxiosTransformer[]),
]

export const transformResponse = [
  ...(axios.defaults.transformResponse as AxiosTransformer[]),
  (data: any) => {
    return data && toCamelCase(data, { deep: true })
  },
]

const defaultRequests = {
  baseURL: env('LOON_API_URL'),
  headers: () => {
    let AUTH_TOKEN

    const cookie = getCookie('_loon_tokid')

    if (cookie) {
      const authToken = JSON.parse(cookie) as AuthToken

      AUTH_TOKEN = authToken.token
    }

    return {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...(AUTH_TOKEN || !isEmpty(AUTH_TOKEN) ? { Authorization: `Bearer ${AUTH_TOKEN}` } : {}),
    }
  },
  transformRequest,
  transformResponse,
}

export const formatErrors = (error: any) => {
  let phoneNumber = ''

  const authTokenCookie = getCookie('_loon_tokid')

  if (authTokenCookie) {
    phoneNumber = JSON.parse(authTokenCookie)?.phoneNumber
  }

  if (error?.response) {
    const response = error?.response

    const config = {
      url: response?.config?.url || '',
      method: response?.config?.method || '',
      data: response?.config?.data || '',
      phoneNumber: phoneNumber,
    }

    const status = response?.status

    let message =
      response?.data?.error ||
      'Oops. Something unusual happened at our end. Please contact our support team!'

    if (status >= 400 && status < 500) {
      message = `Oops. Looks like there's issue with your inputs (${response?.data?.error})`
    }

    if (status >= 500) {
      message = `Oops. Something unusual happened at our end (${response?.data?.error}). Please contact our support team!`
    }

    return { config, status, message }
  } else {
    const config = {
      url: '',
      method: '',
      data: '',
      phoneNumber: phoneNumber,
    }

    const status = 'FRONTEND ERROR'

    const message = error?.message

    return { config, status, message }
  }
}

export default function request(defaultConfigs = {}) {
  const defaults = { ...defaultRequests, ...defaultConfigs }
  const api = (method: Method, url: string, data: any) => {
    return new Promise((resolve, reject) => {
      return axios({
        url: `${defaults.baseURL}${url}`,
        method,
        headers: defaults.headers(),
        params: method === 'get' ? data : undefined,
        data: method !== 'get' ? data : undefined,
        paramsSerializer: objectToQueryString,
        transformRequest: defaults.transformRequest,
        transformResponse: defaults.transformResponse,
      }).then(
        (response) => {
          const data = response?.data?.data
          return resolve(data)
        },
        (error) => {
          const status = error?.status || 503
          const message =
            error?.message ||
            'Oops. Something unusual happened at our end. Please contact our support team!'
          const hasErrorsOrError = error?.response && formatErrors(error.response)

          reject(
            hasErrorsOrError || {
              message,
              status,
            }
          )
        }
      )
    })
  }

  return {
    get: (url: string, data?: any) => api('get', url, data),
    post: (url: string, data?: any) => api('post', url, data),
    patch: (url: string, data?: any) => api('patch', url, data),
    put: (url: string, data?: any) => api('put', url, data),
    delete: (url: string, data?: any) => api('delete', url, data),
  }
}
