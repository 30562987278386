import React, { useState } from 'react'
import {
  FormsCol,
  FormInput,
  OptionsPanel,
  ErrorNotice,
  QuestionPanel,
} from './InputFormBuilder.styles'
import PropTypes, { InferProps } from 'prop-types'
import { isEmpty } from 'ramda'

type Answer = {
  questionKey: string
  answers: Array<string>
}

type ChangeEvent = React.ChangeEvent<HTMLInputElement>

export interface BuilderLabel {
  id: string
  label: string
}

export interface BuilderInputs {
  name: string
  placeholder?: string
}

InputFormBuilder.defaultProps = {
  builderValue: '',
  notice: undefined,
}

InputFormBuilder.propTypes = {
  builderLabel: PropTypes.object as PropTypes.Validator<BuilderLabel>,
  builderInputs: PropTypes.object as PropTypes.Validator<BuilderInputs>,
  onInputChange: PropTypes.func as PropTypes.Validator<(answers: Answer) => void>,
  error: PropTypes.string as PropTypes.Validator<string | undefined>,
  builderValue: PropTypes.array as PropTypes.Validator<string>,
  notice: PropTypes.string as PropTypes.Requireable<string>,
}

function InputFormBuilder({
  builderLabel,
  builderInputs,
  builderValue,
  onInputChange,
  error,
  notice,
}: InferProps<typeof InputFormBuilder.propTypes>) {
  const [value, setValue] = useState(builderValue || '')

  const handleInputsChange = (event: ChangeEvent) => {
    const { value } = event.target
    setValue(value)
    onInputChange({ questionKey: builderLabel?.id, answers: [value] })
  }
  return (
    <>
      <QuestionPanel>
        <label htmlFor={builderLabel?.id}>{builderLabel?.label}</label>
      </QuestionPanel>
      {/* {notice && <TextNotice>{notice}</TextNotice>} */}

      <OptionsPanel>
        <FormsCol>
          <FormInput
            value={value}
            name={builderInputs?.name}
            placeholder={builderInputs?.placeholder || ''}
            onChange={handleInputsChange}
          />
          {error && !isEmpty(error) && <ErrorNotice>{error}</ErrorNotice>}
        </FormsCol>
      </OptionsPanel>
    </>
  )
}

export default InputFormBuilder
