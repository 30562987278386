import { Storage } from '@aws-amplify/storage'
import env from './env'
import { reportErrorToSlack } from './reportErrorToSlack'

const uploadAWSS3File = async (
  file: any,
  fileKey: string,
  fileType: string,
  level: string
): Promise<any> => {
  try {
    const result: any = await Storage.put(fileKey, file, { contentType: fileType })
    return `https://${env('LOON_AWS_BUCKET_NAME')}/${level}/${result?.key}`
  } catch (error) {
    reportErrorToSlack(
      error,
      '500',
      'User tried uploading an image. Error in *uploadAWSS3File.ts* Component',
      window.location.href,
      '',
      ''
    )
    return undefined
  }
}

export default uploadAWSS3File
