/* eslint-disable react/display-name */
import React from 'react'

const icons: Record<string, any> = {
  chatDropArrow: (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 20" width="19" height="20" {...props}>
      <path
        fill="currentColor"
        d="M3.8 6.7l5.7 5.7 5.7-5.7 1.6 1.6-7.3 7.2-7.3-7.2 1.6-1.6z"
      ></path>
    </svg>
  ),
  chatStatusIcon: (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.5957 9.1612L4.7577 11.5745L9.83504 5.77186L8.83104 4.89453L4.57504 9.75853L2.4037 8.1012L1.5957 9.1612ZM14.5017 5.77186L13.4977 4.89453L9.2517 9.7472L8.7497 9.34586L7.91637 10.3872L9.41437 11.5859L14.5017 5.77186Z"
        fill="currentColor"
      />
    </svg>
  ),
  paperclip: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.9732 15.9885L14.5152 19.1704C13.7113 20.925 14.4846 23.0063 16.2392 23.8102C17.9937 24.6142 20.075 23.8409 20.879 22.0863L23.17 17.0862C24.7821 13.5679 23.2404 9.41859 19.7222 7.80652C16.2039 6.19445 12.0546 7.73614 10.4425 11.2544L7.94317 16.7091C6.56437 19.7182 7.88934 23.2843 10.8985 24.6631"
        stroke="#C1C0C3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  chatSend: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.39969 6.32015L15.8897 3.49015C19.6997 2.22015 21.7697 4.30015 20.5097 8.11015L17.6797 16.6002C15.7797 22.3102 12.6597 22.3102 10.7597 16.6002L9.91969 14.0802L7.39969 13.2402C1.68969 11.3402 1.68969 8.23015 7.39969 6.32015Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1104 13.6486L13.6904 10.0586"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  menu: (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" {...props}>
      <path
        fill="currentColor"
        d="M12 7a2 2 0 1 0-.001-4.001A2 2 0 0 0 12 7zm0 2a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 9zm0 6a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 15z"
      ></path>
    </svg>
  ),
  close: (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" {...props}>
      <path
        fill="currentColor"
        d="M19.1 17.2l-5.3-5.3 5.3-5.3-1.8-1.8-5.3 5.4-5.3-5.3-1.8 1.7 5.3 5.3-5.3 5.3L6.7 19l5.3-5.3 5.3 5.3 1.8-1.8z"
      ></path>
    </svg>
  ),
  verified: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.5534 5.52038L23.46 10.5737C23.4467 11.2671 23.8867 12.1871 24.4467 12.6004L27.7534 15.1071C29.8734 16.7071 29.5267 18.6671 26.9934 19.4671L22.6867 20.8137C21.9667 21.0404 21.2067 21.8271 21.02 22.5604L19.9934 26.4804C19.18 29.5737 17.1534 29.8804 15.4734 27.1604L13.1267 23.3604C12.7 22.6671 11.6867 22.1471 10.8867 22.187L6.43337 22.4137C3.2467 22.5737 2.34004 20.7337 4.42004 18.3071L7.06004 15.2404C7.55337 14.6671 7.78004 13.6004 7.55337 12.8804L6.19337 8.56038C5.4067 6.02705 6.82004 4.62705 9.34004 5.45372L13.2734 6.74705C13.94 6.96038 14.94 6.81372 15.5 6.40038L19.6067 3.44038C21.8334 1.85372 23.6067 2.78705 23.5534 5.52038Z"
        fill="#F2C94C"
      />
      <path
        d="M29.0866 27.2939L25.0466 23.2539C24.6599 22.8672 24.0199 22.8672 23.6333 23.2539C23.2466 23.6405 23.2466 24.2805 23.6333 24.6672L27.6733 28.7072C27.8733 28.9072 28.1266 29.0005 28.3799 29.0005C28.6333 29.0005 28.8866 28.9072 29.0866 28.7072C29.4733 28.3205 29.4733 27.6805 29.0866 27.2939Z"
        fill="#F2C94C"
      />
    </svg>
  ),
  folder: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      width="35"
      height="36"
      viewBox="0 0 35 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M33.6167 21.832L32.95 30.1654C32.7 32.7154 32.5 34.6654 27.9833 34.6654H7.01666C2.5 34.6654 2.3 32.7154 2.05 30.1654L1.38333 21.832C1.25 20.4487 1.68333 19.1654 2.46666 18.182C2.48333 18.1654 2.48333 18.1654 2.5 18.1487C3.41666 17.032 4.8 16.332 6.35 16.332H28.65C30.2 16.332 31.5667 17.032 32.4667 18.1154C32.4833 18.132 32.5 18.1487 32.5 18.1654C33.3167 19.1487 33.7667 20.432 33.6167 21.832Z"
        stroke="#99979B"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M3.33331 17.0495V8.46615C3.33331 2.79948 4.74998 1.38281 10.4166 1.38281H12.5333C14.65 1.38281 15.1333 2.01615 15.9333 3.08281L18.05 5.91615C18.5833 6.61615 18.9 7.04948 20.3166 7.04948H24.5666C30.2333 7.04948 31.65 8.46615 31.65 14.1328V17.1161"
        stroke="#99979B"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2167 26.332H21.7833"
        stroke="#99979B"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  clock: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 6 L 11 12.414062 L 15.292969 16.707031 L 16.707031 15.292969 L 13 11.585938 L 13 6 L 11 6 z"
      />
    </svg>
  ),
  exclamationCircle: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  ),
  loader: (props: React.SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="40"
      height="40"
      viewBox="0 0 50 50"
      {...props}
    >
      <path
        fill="currentColor"
        d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
      >
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  ),
}

export default icons
