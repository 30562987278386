import React, { createContext, useContext, useReducer } from 'react'
import axios, { AxiosResponse } from 'axios'
import env from '../utils/env'
import queryString from 'querystring'
import { BIRTH_CONTROL_QUESTIONS } from '../utils/constants'
import { prop, sortBy } from 'ramda'
import { transformRequest, transformResponse } from '../utils/request'

axios.defaults.baseURL = env('LOON_API_URL')

axios.defaults.headers.common['Accept'] = 'application/json'

export type VisitType = 'BIRTH-CONTROL'

export type Question = {
  id: string
  type:
    | 'OPEN-TEXT'
    | 'OPEN-TEXTAREA'
    | 'OPEN-DATE'
    | 'OPEN-ADDRESS'
    | 'OPEN-NUMBER'
    | 'OPEN-BLOOD-PRESSURE'
    | 'OPEN-HEIGHT'
    | 'OPEN-WEIGHT'
    | 'BINARY'
    | 'BINARY-SELECT'
    | 'MULTIPLE-CHOICE'
    | 'OPEN-IMAGE'
  priority: number
  option: string
  notice?: string
  error?: string
  children?: Array<{
    id: string
    priority: number
    option: string
  }>
}

export type Answer = {
  questionKey: string
  answers: Array<string>
}

export type QuestionnaireAnswers = {
  id: string
  createdAt: string
  updatedAt: string
  type: string
  patientUserId: string
  answers: Array<Answer>
  status: string
  prescription: string
  notesForPatient: string
}

export type VisitSchedule = {
  count: number
  id: string
  date: string
}

type QuestionnaireState = {
  questions: Array<Question>
  questionnaireAnswers: Array<QuestionnaireAnswers>
  isSendingAnswers: boolean
  isLoadingAnswers: boolean
  isLoadingQuestions: boolean
}

type QuestionnaireDispatch = (action: QuestionnaireAction) => void

type QuestionnaireAction =
  | { type: 'SET_QUESTIONS'; payload: Array<Question> }
  | { type: 'SET_QUESTIONNAIRE_ANSWERS'; payload: Array<QuestionnaireAnswers> }
  | { type: 'LOADING_QUESTIONS'; payload: boolean }
  | { type: 'LOADING_ANSWERS'; payload: boolean }
  | { type: 'SENDING_ANSWERS'; payload: boolean }

const initialState: QuestionnaireState = {
  questions: [],
  questionnaireAnswers: [],
  isSendingAnswers: false,
  isLoadingAnswers: false,
  isLoadingQuestions: false,
}

const QuestionnaireStateContext = createContext<
  { state: QuestionnaireState; dispatch: QuestionnaireDispatch } | undefined
>(undefined)

// prettier-ignore
function QuestionnaireReducer(state = initialState, action: QuestionnaireAction): QuestionnaireState {
  switch (action.type) {
    case 'SET_QUESTIONS':
      return {
        ...state,
        questions: action.payload,
      }
    case 'SET_QUESTIONNAIRE_ANSWERS':
      return {
        ...state,
        questionnaireAnswers: action.payload,
      }
    case 'SENDING_ANSWERS':
      return {
        ...state,
        isSendingAnswers: action.payload,
      }
    case 'LOADING_QUESTIONS':
      return {
        ...state,
        isLoadingQuestions: action.payload,
      }
    case 'LOADING_ANSWERS':
      return {
        ...state,
        isLoadingAnswers: action.payload,
      }
    default:
      return state
  }
}

function QuestionnaireProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(QuestionnaireReducer, initialState)

  return (
    <QuestionnaireStateContext.Provider value={{ state, dispatch }}>
      {children}
    </QuestionnaireStateContext.Provider>
  )
}

function useQuestionnaireContext() {
  const context = useContext(QuestionnaireStateContext)
  if (context === undefined) {
    throw new Error('quesContext must be used within a QuestionnaireProvider')
  }
  return context
}

export { QuestionnaireProvider, useQuestionnaireContext }

/****
 * ----------------------------------------------------------------
 * Questionnaire Actions Asynchronous Functions (similar to Redux)
 * ----------------------------------------------------------------
 */
export const getVisitTypeQuestions = async (
  dispatch: QuestionnaireDispatch,
  type: VisitType,
  token: string
) => {
  try {
    const paramsQuery = queryString.stringify({ visitType: type })

    dispatch({ type: 'LOADING_QUESTIONS', payload: true })

    // const { data: response } = (await axios({
    //   method: 'get',
    //   url: `/questionnaires/${type}/questions?${paramsQuery}`,
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Accept: 'application/json',
    //     Authorization: `Bearer ${token}`,
    //   },
    //   transformResponse: transformResponse,
    // })) as AxiosResponse<any>
    //
    // const questions = response.data as Array<Question>

    const questions = BIRTH_CONTROL_QUESTIONS

    dispatch({ type: 'SET_QUESTIONS', payload: questions })

    dispatch({ type: 'LOADING_QUESTIONS', payload: false })
  } catch (error) {
    dispatch({ type: 'LOADING_QUESTIONS', payload: false })
    alert(error.message)
  }
}

export const getAnswers = async (
  dispatch: QuestionnaireDispatch,
  type: VisitType,
  token: string
) => {
  try {
    const paramsQuery = queryString.stringify({ type: type })

    dispatch({ type: 'LOADING_ANSWERS', payload: true })

    const { data: response } = (await axios({
      method: 'get',
      url: `/questionnaires?${paramsQuery}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      transformResponse: transformResponse,
    })) as AxiosResponse<any>

    const answers = response.data as Array<QuestionnaireAnswers>

    dispatch({
      type: 'SET_QUESTIONNAIRE_ANSWERS',
      payload: sortBy(prop('createdAt'), [...answers]).reverse(),
    })

    dispatch({ type: 'LOADING_ANSWERS', payload: false })
  } catch (error) {
    dispatch({ type: 'LOADING_ANSWERS', payload: false })
    alert(error.message)
  }
}

export const sendAnswers = async (
  dispatch: QuestionnaireDispatch,
  type: VisitType,
  questions: Array<Question>,
  answers: Array<Answer>,
  token: string,
  cb: () => void
) => {
  try {
    const paramsQuery = queryString.stringify({ visitType: type })

    dispatch({ type: 'SENDING_ANSWERS', payload: true })

    const sortedAnswers = sortBy(prop('priority'), questions).map((question) => {
      const quesAns = answers.find((questAns) => questAns.questionKey === question.id)?.answers
      return { questionKey: question.id, answers: quesAns }
    })

    await axios({
      method: 'post',
      url: `/questionnaire/${type}?${paramsQuery}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: sortedAnswers,
      transformResponse: transformResponse,
      transformRequest: transformRequest,
    })

    dispatch({ type: 'SENDING_ANSWERS', payload: false })

    cb()
  } catch (error) {
    dispatch({ type: 'SENDING_ANSWERS', payload: false })
    alert(error.message)
  }
}
