import moment from 'moment'

export const formatMessageTimestamp = (time: string) => {
  const currentTime = moment(Date.now()).format('MM/DD')
  const chatTime = moment(time).format('MM/DD')
  if (currentTime === chatTime) {
    return moment(time).format('h:mm a')
  }

  return moment(time).format('MM/DD/YY')
}
