import styled, { css } from 'styled-components/macro'
import TextareaAutosize from 'react-autosize-textarea'

type InputWrapperProps = {
  error?: string | null
  width?: number | null
  hasFocused?: boolean | null
}

export const InputWrapper = styled.div<InputWrapperProps>`
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: inherit;
  display: flex;
  align-items: center;
  position: relative;
  background: #ffffff;
  box-shadow: rgb(0 0 0 / 7%) 0 0 12px;
  padding-right: 5px;
  padding-bottom: 2px;
  padding-top: 2px;
  border: none;
  box-sizing: border-box;
  border-radius: 25px;
  margin: 5px 0;

  ${(props) =>
    props.hasFocused &&
    css`
      outline-offset: 0;
      border: none;
    `}

  grammarly-extension {
    top: 8px !important;
  }
`

export const MsgInputWrapper = styled(TextareaAutosize)<InputWrapperProps>`
  width: 100%;
  height: 36px;
  border: none;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  padding: 11.5px 14px 10px;
  font-family: 'GoldplayMedium', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 15px;
  line-height: 1.2;
  color: #000000;
  background: #ffffff;
  outline: 0;
  text-rendering: optimizeLegibility;
  letter-spacing: 0.03em;

  ${(props) =>
    props.error === 'error' &&
    css`
      border-color: red !important;
    `}
  &:disabled {
    color: #7a8998;
    background: #f5f7fa;
    border: 1.5px solid #dfe5eb;
  }

  &:focus {
    outline: none;
  }

  &::-webkit-input-placeholder {
    color: #a7a7a7;
    font-weight: 300;
    font-size: 15px;
    font-family: 'Goldplay', sans-serif;
  }

  &:-ms-input-placeholder {
    color: #a7a7a7;
    font-weight: 300;
    font-size: 15px;
    font-family: 'Goldplay', sans-serif;
  }

  &::placeholder {
    color: #a7a7a7;
    font-weight: 300;
    font-size: 15px;
    font-family: 'Goldplay', sans-serif;
  }
`

export const SendIcon = styled.span`
  background: #e98fb6;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  .send-icon {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    width: 20px;
    cursor: pointer;
  }

  &:hover,
  &:focus {
    background: rgba(233, 143, 182, 0.85);
  }
`
