import styled from 'styled-components/macro'
import { device } from '../../../utils/mediaQueries'
import { Input } from '../../index'

export const AlertPanel = styled.div`
  background: #e4fafb;
  border: 1px solid #1dc7d3;
  box-sizing: border-box;
  border-radius: 8px;
  color: #393340;
  padding: 15px;
  font-size: 14px;
  margin-top: 40px;
  display: flex;
  font-family: 'GoldplayMedium', sans-serif;

  img {
    margin-right: 10px;
  }
`

export const QuestionPanel = styled.div`
  color: #393340;
  font-size: 16px;
  font-family: 'GoldplayMedium', sans-serif;
  letter-spacing: 0.5px;
  line-height: 28px;
  margin-top: 30px;
  margin-bottom: 10px;

  label {
    margin-bottom: 0;
  }

  span {
  }

  @media screen and ${device.smallMobile} {
    font-size: 17px;
  }

  @media screen and ${device.mobile} {
    font-size: 17px;
  }
`

export const OptionsPanel = styled.div`
  display: flex;
  flex-direction: column;
`

export const FormsCol = styled.div`
  display: flex;
  padding: 0;

  @media screen and ${device.smallMobile} {
    width: 100%;
  }

  @media screen and ${device.mobile} {
    width: auto;
  }
`

export const ErrorNotice = styled.div`
  font-size: 13.5px;
  letter-spacing: 0.4px;
  color: #ff8c42;
  font-family: 'GoldplayMedium', sans-serif;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0;

  @media screen and ${device.smallMobile} {
  }

  @media screen and ${device.mobile} {
  }
`

export const TextNotice = styled.div`
  font-size: 13.5px;
  letter-spacing: 0.4px;
  color: #ff8c42;
  font-family: 'GoldplayMedium', sans-serif;
  font-weight: 400;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;

  @media screen and ${device.smallMobile} {
  }

  @media screen and ${device.mobile} {
  }
`

export const WeightInput = styled(Input)`
  &[name='feet'] {
    margin-right: 20px;
  }

  &[name='inches'] {
    margin-left: 20px;
  }

  @media screen and ${device.smallMobile} {
  }

  @media screen and ${device.mobile} {
  }
`
