import Icons from '../../assets/icons/indexSVG'

type IconElement = {
  id: string
  className?: string
}

const allIcons = Icons

const Icon = ({ id, ...props }: IconElement) => {
  const selectedIcon = allIcons[id]
  return selectedIcon ? selectedIcon(props) : null
}

export default Icon
