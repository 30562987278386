import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  ErrorNotice,
  AlertPanel,
  QuestionPanel,
  OptionsPanel,
  ImageUploadGrid,
  UploadIcon,
  UploadInput,
  UploadStatus,
  UploadedFile,
} from './FileUploadBuilder.style'
import PropTypes, { InferProps } from 'prop-types'
import { icons } from '../../../assets/icons'
import { isEmpty } from 'ramda'
import { v4 as uuidv4 } from 'uuid'
import uploadAWSS3File from '../../../utils/uploadAWSS3File'
import readImageFile from '../../../utils/readImageFile'

type Answer = {
  questionKey: string
  answers: Array<string>
}

const UPLOAD_FILE_FOLDER = 'document'

const UPLOAD_ITEM_NOTICE: Record<string, string> = {
  ID_UPLOADER: 'Click to upload your I.D.',
  SELFIE_UPLOADER: 'Click to upload a selfie',
}

const UPLOAD_ITEM_ICON: Record<string, string> = {
  ID_UPLOADER: icons.iconIdentificationUploader,
  SELFIE_UPLOADER: icons.iconSelfieUploader,
}

export interface BuilderLabel {
  id: string
  label: string
}

export interface BuilderInputs {
  id: string
  priority: number
  option: string
}

FileUploadBuilder.defaultProps = {
  onCardClicked: () => false,
  builderValue: [],
  notice: undefined,
}

FileUploadBuilder.propTypes = {
  builderLabel: PropTypes.object as PropTypes.Validator<BuilderLabel>,
  builderInputs: PropTypes.array.isRequired as PropTypes.Requireable<Array<BuilderInputs>>,
  onFileChange: PropTypes.func as PropTypes.Validator<(answers: Answer) => void>,
  error: PropTypes.string as PropTypes.Validator<string | undefined>,
  builderValue: PropTypes.array as PropTypes.Validator<Array<string>>,
  notice: PropTypes.string as PropTypes.Requireable<string>,
}

function FileUploadBuilder({
  onFileChange,
  builderValue,
  builderLabel,
  builderInputs,
  notice,
  error,
}: InferProps<typeof FileUploadBuilder.propTypes>) {
  const [file, setFile] = useState<Record<string, string>>({
    id_uploader: '',
    selfie_uploader: '',
  })
  const [fileDataURL, setFileDataURL] = useState<Record<string, string>>({
    id_uploader: '',
    selfie_uploader: '',
  })
  const [uploadingFile, setUploadingFile] = useState<Record<string, boolean>>({
    id_uploader: false,
    selfie_uploader: false,
  })
  const [isDraggingOver, setIsDraggingOver] = useState<Record<string, boolean>>({
    id_uploader: false,
    selfie_uploader: false,
  })

  useEffect(() => {
    if (builderValue.length > 0 && builderValue.every((item) => !isEmpty(item))) {
      setFile({
        id_uploader: builderValue[0] || '',
        selfie_uploader: builderValue[1] || '',
      })
    }
  }, [builderLabel.id, builderValue])

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files, name } = event.target
    if (files && files.length > 0) {
      await handleFileProcessing(files[0], name)
    }
  }

  const handleFileProcessing = async (file: File, name: string) => {
    setUploadingFile((uploads) => ({ ...uploads, [name]: true }))

    const filename = file?.name

    const fileType = file?.type || 'image/jpeg'

    const fileKey = `${UPLOAD_FILE_FOLDER}/${name}_${uuidv4()}.${
      filename ? filename.split('.').pop() : 'jpeg'
    }`

    const uploadedFile = await uploadAWSS3File(file, fileKey, fileType, 'public')

    setUploadingFile((uploads) => ({ ...uploads, [name]: false }))

    const fileData = await readImageFile(file)

    setFileDataURL((data) => ({ ...data, [name]: fileData }))

    setFile((file) => {
      const uploadedFiles = { ...file, [name]: uploadedFile }

      const answers = Object.values(uploadedFiles)

      onFileChange({ questionKey: builderLabel?.id, answers: answers })

      return { ...file, [name]: uploadedFile }
    })
  }

  const preventDragDropDefaults = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDragOver = (event: any, name: string) => {
    preventDragDropDefaults(event)
    setIsDraggingOver((drag) => ({ ...drag, [name]: true }))
  }

  const handleDragEnter = (event: any, name: string) => {
    preventDragDropDefaults(event)
    setIsDraggingOver((drag) => ({ ...drag, [name]: true }))
  }

  const handleDragLeave = (event: any, name: string) => {
    preventDragDropDefaults(event)
    setIsDraggingOver((drag) => ({ ...drag, [name]: false }))
  }

  const handleDrop = async (event: any, name: string) => {
    preventDragDropDefaults(event)
    setIsDraggingOver((drag) => ({ ...drag, [name]: false }))
    const files = [...event.dataTransfer.files]
    if (files) {
      await handleFileProcessing(files[0], name)
    }
  }

  return (
    <>
      <AlertPanel>
        <img src={icons.iconAlertInfoWarning} alt={'alert warning'} />
        In order to mail prescriptions to you, please upload an ID that includes your picture, name,
        and date of birth.
      </AlertPanel>

      <QuestionPanel>
        We’re required by law to do this. We accept School IDs, Driver’s License, or Passport.
      </QuestionPanel>

      <OptionsPanel>
        {builderInputs &&
          builderInputs.map((input, index) => (
            <ImageUploadGrid
              key={index}
              isDraggingOver={isDraggingOver[`${input.id.toLowerCase()}`]}
              onDrop={(event: any) => handleDrop(event, input.id.toLowerCase())}
              onDragOver={(event: any) => handleDragOver(event, input.id.toLowerCase())}
              onDragEnter={(event: any) => handleDragEnter(event, input.id.toLowerCase())}
              onDragLeave={(event: any) => handleDragLeave(event, input.id.toLowerCase())}
            >
              {uploadingFile[`${input.id.toLowerCase()}`] ? (
                <img src={icons.iconLoader} alt="loader" />
              ) : (
                <>
                  {!isEmpty(fileDataURL[`${input.id.toLowerCase()}`]) ? (
                    <>
                      <UploadStatus className={input.id?.toLowerCase()}>
                        <img
                          src={icons.iconRadioInputCheckedV1}
                          alt={`upload_status_${input.id?.toLowerCase()}`}
                        />
                      </UploadStatus>
                      <UploadedFile>
                        {input.id?.toLowerCase() === 'id_uploader' ? (
                          <img
                            src={fileDataURL[`${input.id.toLowerCase()}`]}
                            alt={input.id?.toLowerCase()}
                          />
                        ) : (
                          <img
                            src={fileDataURL[`${input.id.toLowerCase()}`]}
                            alt={input.id?.toLowerCase()}
                          />
                        )}
                      </UploadedFile>
                    </>
                  ) : (
                    <UploadIcon>
                      <img
                        src={UPLOAD_ITEM_ICON[`${input.id}`] || ''}
                        alt={input.id?.toLowerCase()}
                      />
                      <p>{UPLOAD_ITEM_NOTICE[`${input.id}`] || 'Drag and drop image here'}</p>
                    </UploadIcon>
                  )}
                </>
              )}
              <UploadInput>
                <input
                  type="file"
                  name={input.id?.toLowerCase()}
                  accept="image/*"
                  onChange={(event) => handleFileUpload(event)}
                />
              </UploadInput>
            </ImageUploadGrid>
          ))}
      </OptionsPanel>

      {error && !isEmpty(error) && <ErrorNotice>{error}</ErrorNotice>}
    </>
  )
}

export default FileUploadBuilder
