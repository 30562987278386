import styled from 'styled-components/macro'
import { device } from '../../utils/mediaQueries'

export const Container = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  padding-top: 66.09px;
  padding-left: 0;
  padding-right: 0;
  margin-right: auto;
  margin-left: auto;
  background: #fbfbfb;
`

export const PageWrapper = styled.div`
  width: 100%;
  flex: 1 0 auto;
  padding: 0 3rem;
  //background: #edd2d2;
  background: #fbfbfb;
  position: relative;

  @media screen and ${device.smallMobile} {
    padding: 0 2rem;
  }

  @media screen and ${device.mobile} {
    padding: 0 2rem;
  }
`
