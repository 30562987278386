import { Storage } from '@aws-amplify/storage'
import { isValidURL } from './isValidURL'
import env from './env'

const readAWSS3File = async (folder: string, fileURL?: string): Promise<any> => {
  try {
    if (!fileURL) {
      return fileURL || ''
    }

    if (!isValidURL(fileURL)) {
      return fileURL
    }

    if (fileURL.includes(env('LOON_AWS_BUCKET_NAME') || '')) {
      const getFileKey = fileURL.split(folder)[1].substr(1)
      return await Storage.get(`${folder}/${getFileKey}`)
    }

    return fileURL
  } catch (error) {
    console.log('Could not read file errors', error)
    return fileURL || ''
  }
}

export default readAWSS3File
