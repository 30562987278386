import 'croppie/croppie.css'
import React, { useEffect, useRef } from 'react'
import PropTypes, { InferProps } from 'prop-types'
import { Container, ModalWrapper, Button, Close, Cropper } from './PhotoCropper.style'
import Croppie from 'croppie'
import { isEmpty } from 'ramda'

type ClickEvent = () => void

const CROPPIE_OPTIONS = {
  enableExif: true,
  viewport: {
    width: 150,
    height: 150,
    type: 'circle',
  },
  boundary: {
    width: 200,
    height: 200,
  },
}

const CROPPIE_ELEMENT_ID = 'PHOTO-UPLOADER'

PhotoCropper.propTypes = {
  showModal: PropTypes.bool,
  onCloseModal: PropTypes.func as PropTypes.Validator<ClickEvent>,
  photoDataURL: PropTypes.string,
  setCroppedPhoto: PropTypes.func as PropTypes.Validator<(croppedPhoto: Blob) => void>,
}

function PhotoCropper({
  showModal,
  onCloseModal,
  photoDataURL,
  setCroppedPhoto,
}: InferProps<typeof PhotoCropper.propTypes>) {
  const croppie: any = useRef()

  useEffect(() => {
    croppie.current = new Croppie(document.getElementById(CROPPIE_ELEMENT_ID), CROPPIE_OPTIONS)
  }, [])

  useEffect(() => {
    if (croppie.current && photoDataURL && !isEmpty(photoDataURL)) {
      croppie.current.bind({
        url: photoDataURL,
      })
    }
  }, [croppie, photoDataURL])

  const handleCroppedPhoto = () => {
    croppie.current
      .result({
        type: 'blob',
        size: {
          width: 400,
          height: 400,
        },
      })
      .then((blob: Blob) => {
        setCroppedPhoto(blob)
        onCloseModal()
      })
  }
  return (
    <>
      <ModalWrapper show={showModal} onHide={onCloseModal} centered>
        <Container>
          <Close onClick={onCloseModal}>x</Close>
          <Cropper id={CROPPIE_ELEMENT_ID} />
          <Button
            label={'Crop & Upload'}
            type={'button-primary'}
            onClick={handleCroppedPhoto}
            disabled={false}
            loading={false}
            hideIcon={true}
          />
        </Container>
      </ModalWrapper>
    </>
  )
}

export default PhotoCropper
