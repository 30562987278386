import styled from 'styled-components/macro'
import { Button as Btn } from '../index'
import { images } from '../../assets/images'
import { device } from '../../utils/mediaQueries'

export const Wrapper = styled.div`
  background-color: #ffffff;
  background-image: url('${images.imageActivityCardWhiteBg}');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: auto;
  flex-direction: row;
  display: flex;
  border-radius: 16px;
  padding: 20px 0 20px 20px;
  min-height: 200px;
  margin-bottom: 30px;
  //box-shadow: rgb(0 0 0 / 9%) 0 0 2px;

  @media screen and ${device.smallMobile} {
    padding: 20px 0 0 20px;
    filter: drop-shadow(0px 1px 2px rgba(74, 34, 52, 0.05));
    flex-wrap: wrap;
  }

  @media screen and ${device.mobile} {
    padding: 20px 0 0 20px;
    filter: drop-shadow(0px 1px 2px rgba(74, 34, 52, 0.05));
    flex-wrap: wrap;
  }
`

export const Button = styled(Btn)`
  width: fit-content;
  font-size: 15px;

  @media screen and ${device.smallMobile} {
    width: auto;

    img[alt='Action'] {
      display: none;
    }
  }

  @media screen and ${device.mobile} {
    width: auto;

    img[alt='Action'] {
      display: none;
    }
  }
`

export const CardImage = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  img {
    width: 150px;
    margin-top: 5px;
    margin-bottom: -20px;
  }

  span {
    background: #8cc122;
    margin-right: 20px;
    border-radius: 100px;
    color: #ffffff;
    font-size: 12px;
    font-family: 'GoldplayMedium', sans-serif;
    padding: 2px 13px;
  }

  @media screen and ${device.smallMobile} {
    overflow: hidden;
    align-items: flex-end;
    justify-content: space-between;

    img {
      width: 120px;
      margin-top: 0;
      margin-bottom: 0;
    }

    span {
      margin-top: 5px;
      margin-right: 10px;
    }
  }

  @media screen and ${device.mobile} {
    overflow: hidden;
    align-items: flex-end;
    justify-content: space-between;

    img {
      width: 120px;
      margin-top: 0;
      margin-bottom: 0;
    }

    span {
      margin-top: 5px;
      margin-right: 10px;
    }
  }
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;

  p.content-title {
    letter-spacing: 0.03em;
    font-family: 'DM Serif Display', sans-serif;
    font-size: 20px;
    line-height: initial;
    color: #38313c;
    font-style: normal;
    margin-bottom: 5px;
  }

  p.content-subtitle {
    font-family: 'GoldplayMedium', sans-serif;
    font-style: normal;
    font-size: 15px;
    color: #99979b;
    margin-bottom: 25px;

    @media screen and ${device.smallMobile} {
      font-size: 15px;
    }

    @media screen and ${device.mobile} {
      font-size: 15px;
    }
  }

  @media screen and ${device.smallMobile} {
    justify-content: flex-start;
    z-index: 1;
  }

  @media screen and ${device.mobile} {
    justify-content: flex-start;
    z-index: 1;
  }
`
