import styled, { css } from 'styled-components/macro'

type CareHistoryStyleProps = {
  readMore?: boolean
  linesLimit?: number
}

export const Text = styled.div<CareHistoryStyleProps>`
  line-height: 23px;
  white-space: pre-line;

  ${(props) =>
    !props.readMore &&
    css`
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: ${props.linesLimit ? props.linesLimit + 1 : '3'};
      -webkit-box-orient: vertical;
    `}
`

export const ActionBtn = styled.span`
  cursor: pointer;
  font-family: 'GoldplayMedium', sans-serif;
  color: #9ac83f;
  letter-spacing: 0.3px;
  font-size: 14px;
  margin-top: 2px;
  display: inline-block;
  font-weight: 600;
`
