import React, { useEffect, useState } from 'react'
import {
  QuestionPanel,
  OptionsPanel,
  SystolicCol,
  RadioItem,
  ListItems,
  RadioLabel,
  ErrorNotice,
  DiastolicCol,
} from './BPInputFormBuilder.styles'
import PropTypes, { InferProps } from 'prop-types'
import { isEmpty } from 'ramda'

type Answer = {
  questionKey: string
  answers: Array<string>
}

type ChangeEvent = React.ChangeEvent<HTMLInputElement>

export interface BuilderLabel {
  id: string
  label: string
}

export interface BuilderInputs {
  id: string
  priority: number
  option: string
}

BPInputFormBuilder.defaultProps = {
  builderValue: [],
  notice: undefined,
}

BPInputFormBuilder.propTypes = {
  builderLabel: PropTypes.object as PropTypes.Validator<BuilderLabel>,
  builderInputs: PropTypes.array.isRequired as PropTypes.Requireable<Array<BuilderInputs>>,
  onInputChange: PropTypes.func as PropTypes.Validator<(answers: Answer) => void>,
  error: PropTypes.string as PropTypes.Validator<string | undefined>,
  builderValue: PropTypes.array as PropTypes.Validator<Array<string>>,
  notice: PropTypes.string as PropTypes.Requireable<string>,
}

function BPInputFormBuilder({
  builderLabel,
  builderInputs,
  builderValue,
  onInputChange,
  error,
  notice,
}: InferProps<typeof BPInputFormBuilder.propTypes>) {
  const [checkedItem, setCheckedItem] = useState({
    systolic: '',
    diastolic: '',
  })
  const [systolicInputs, setSystolicInputs] = useState<Array<BuilderInputs>>([])
  const [diastolicInputs, setDiastolicInputs] = useState<Array<BuilderInputs>>([])

  useEffect(() => {
    if (builderValue.length > 0 && builderValue.every((item) => !isEmpty(item))) {
      setCheckedItem({
        systolic: builderValue[0] || '',
        diastolic: builderValue[1] || '',
      })
    }
  }, [builderLabel.id, builderValue])

  useEffect(() => {
    if (builderInputs && builderInputs.length > 0) {
      const systolicInputs = builderInputs.filter((input) =>
        input.id.toLowerCase().includes('systolic')
      )

      const diastolicInputs = builderInputs.filter((input) =>
        input.id.toLowerCase().includes('diastolic')
      )

      setSystolicInputs(systolicInputs)

      setDiastolicInputs(diastolicInputs)
    }
  }, [builderInputs])

  const handleRadioBoxChange = (event: ChangeEvent) => {
    const { value, checked, name } = event.target

    if (checked) {
      setCheckedItem((checkedItem) => {
        const checkedItems = { ...checkedItem, [name]: value }
        const answers = Object.values(checkedItems)

        if (checkedItems && answers.length === 2 && answers.every((item) => !isEmpty(item))) {
          onInputChange({ questionKey: builderLabel?.id, answers: answers })
        } else {
          onInputChange({ questionKey: builderLabel?.id, answers: [''] })
        }

        return { ...checkedItem, [name]: value }
      })
    }
  }

  return (
    <>
      <QuestionPanel>
        <label htmlFor={builderLabel?.id}>{builderLabel?.label}</label>
      </QuestionPanel>
      {/* {notice && <TextNotice>{notice}</TextNotice>} */}

      <OptionsPanel>
        <SystolicCol>
          <label className={'systolic-options--label'}>Systolic</label>
          <div className={'systolic-options'}>
            {systolicInputs &&
              systolicInputs.map((input, index) => (
                <ListItems key={index} checked={checkedItem.systolic === input.id}>
                  <RadioItem
                    size={'small'}
                    id={`SYSTOLIC_${input.id}`}
                    name={'systolic'}
                    value={input.id}
                    onChange={handleRadioBoxChange}
                    checked={checkedItem.systolic === input.id}
                  />
                  <RadioLabel htmlFor={`SYSTOLIC_${input.id}`}>{input.option}</RadioLabel>
                </ListItems>
              ))}
          </div>
        </SystolicCol>
        <DiastolicCol>
          <label className={'diastolic-options--label'}>Diastolic</label>
          <div className={'diastolic-options'}>
            {diastolicInputs &&
              diastolicInputs.map((input, index) => (
                <ListItems key={index} checked={checkedItem.diastolic === input.id}>
                  <RadioItem
                    size={'small'}
                    id={`DIASTOLIC_${input.id}`}
                    name={'diastolic'}
                    value={input.id}
                    onChange={handleRadioBoxChange}
                    checked={checkedItem.diastolic === input.id}
                  />
                  <RadioLabel htmlFor={`DIASTOLIC_${input.id}`}>{input.option}</RadioLabel>
                </ListItems>
              ))}
          </div>
        </DiastolicCol>
      </OptionsPanel>

      {error && !isEmpty(error) && <ErrorNotice>{error}</ErrorNotice>}
    </>
  )
}

export default BPInputFormBuilder
