import React, { createContext, useContext, useReducer } from 'react'
import axios from 'axios'
import env from '../utils/env'
import { AuthUser } from './authContext'
import apigateway from '../utils/apigateway'
import { getCookie } from '../utils/getCookie'
import { groupBy, map, prop, reject, sortBy, uniqBy, update } from 'ramda'
import { setCookie } from '../utils/setCookie'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import readAWSS3File from '../utils/readAWSS3File'
import { CHAT_FILE_FOLDER, CHAT_LIMIT } from '../utils/constants'
import { formatErrors } from '../utils/request'
import uploadAWSS3File from '../utils/uploadAWSS3File'

axios.defaults.baseURL = env('LOON_API_URL')

axios.defaults.headers.common['Accept'] = 'application/json'

export type MessageType = 'TEXT' | 'IMAGE' | 'VIDEO' | 'FILE' | 'QUESTIONNAIRE' | 'LAB-REPORT'

export type Message = {
  id: string
  content: string
  type: MessageType
  conversationId: string
  userId: string
  createdAt: string
  updatedAt: string
  deletedAt?: string
  patientQuestionnaireId?: string
  patientLabTestId?: string
  hasError?: boolean
}

export type Conversation = {
  id: string
  users: Array<{ user: AuthUser; lastReadAt: string }>
  unreadMessageCount: number
  lastMessage: Array<Message>
  createdAt: string
  updatedAt: string
  deletedAt?: string
}

type MessageState = {
  messages: Array<Message>
  groupedMessages: Array<[string, Message[]]>
  tempMessageId: string
}

type MessageDispatch = (action: MessageAction) => void

type MessageAction =
  | { type: 'SET_MESSAGES'; payload: Array<Message> }
  | { type: 'SET_GROUPED_MESSAGES'; payload: Array<[string, Message[]]> }
  | { type: 'SET_TEMP_MESSAGE_ID'; payload: string }

const initialState: MessageState = {
  messages: [],
  groupedMessages: [],
  tempMessageId: '',
}

const MessageStateContext = createContext<
  { state: MessageState; dispatch: MessageDispatch } | undefined
>(undefined)

function MessageReducer(state = initialState, action: MessageAction): MessageState {
  switch (action.type) {
    case 'SET_MESSAGES':
      return {
        ...state,
        messages: action.payload,
      }
    case 'SET_GROUPED_MESSAGES':
      return {
        ...state,
        groupedMessages: action.payload,
      }
    case 'SET_TEMP_MESSAGE_ID':
      return {
        ...state,
        tempMessageId: action.payload,
      }
    default:
      return state
  }
}

function MessageProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(MessageReducer, initialState)
  return (
    <MessageStateContext.Provider value={{ state, dispatch }}>
      {children}
    </MessageStateContext.Provider>
  )
}

function useMessageContext() {
  const context = useContext(MessageStateContext)
  if (context === undefined) {
    throw new Error('messageContext must be used within a MessageProvider')
  }
  return context
}

export { MessageProvider, useMessageContext }

/****
 * ----------------------------------------------------------------
 * Message Actions Asynchronous Functions (similar to Redux)
 * ----------------------------------------------------------------
 */
export const getMessages = async (
  dispatch: MessageDispatch,
  conversationId: string,
  pageSize: number
) => {
  try {
    const messages = (await apigateway.get(`/conversation/${conversationId}`, {
      conversationId: conversationId,
      pageSize: pageSize,
    })) as Array<Message>

    const errorMessages = getLocallyErrorMessage() as Array<Message>

    let sortedMessages = await Promise.all(
      map(
        async (message: Message) => ({
          ...message,
          content:
            message?.type === 'IMAGE' || message?.type === 'VIDEO' || message?.type === 'FILE'
              ? await readAWSS3File(CHAT_FILE_FOLDER, message.content)
              : message.content,
        }),
        sortBy(prop('createdAt'), [...messages, ...errorMessages])
      )
    )

    sortedMessages = uniqBy(prop('id'), sortedMessages)

    dispatch({
      type: 'SET_GROUPED_MESSAGES',
      payload: Object.entries(groupMessages(sortedMessages)),
    })

    dispatch({
      type: 'SET_MESSAGES',
      payload: sortedMessages,
    })
  } catch (error) {
    const { config, message, status } = formatErrors(error)
    alert(message)
  }
}

export const readMessage = async (dispatch: MessageDispatch, conversationId: string) => {
  try {
    await apigateway.patch(`/conversation/${conversationId}/read?conversation_id=${conversationId}`)
  } catch (error) {}
}

export const sendMessage = async (
  dispatch: MessageDispatch,
  messages: Array<Message>,
  conversationId: string,
  authUser: AuthUser,
  message: {
    content: string
    type: MessageType
  },
  attachment: File | undefined,
  tempMessageId: string,
  resendMessage = false
) => {
  const tempMessage: Message = {
    id: tempMessageId,
    content: message.content,
    type: message.type,
    userId: authUser.id,
    conversationId: conversationId,
    createdAt: moment(Date.now()).format(),
    updatedAt: moment(Date.now()).format(),
  }

  let appendTempMessages = [...messages, tempMessage]

  appendTempMessages = uniqBy(prop('id'), appendTempMessages)

  try {
    // SET TEMPORARY MESSAGE ON CLIENT SIDE
    dispatch({
      type: 'SET_GROUPED_MESSAGES',
      payload: Object.entries(groupMessages(appendTempMessages)),
    })

    dispatch({
      type: 'SET_MESSAGES',
      payload: appendTempMessages,
    })

    dispatch({
      type: 'SET_TEMP_MESSAGE_ID',
      payload: tempMessageId,
    })
    // SET TEMPORARY MESSAGE ON CLIENT SIDE

    // SEND MESSAGE TO THE SERVER
    let payload = {
      content: message.content,
      type: message.type,
      conversationId: conversationId,
    }

    // ATTACHMENT FILE - UPLOAD
    if (attachment) {
      const filename = attachment.name
      const fileType = attachment.type || 'image/jpeg'
      const fileKey = `${CHAT_FILE_FOLDER}/${uuidv4()}.${
        filename ? filename.split('.').pop() : 'jpeg'
      }`

      const path = await uploadAWSS3File(attachment, fileKey, fileType, 'public')

      payload = {
        content: path,
        type: message.type,
        conversationId: conversationId,
      }
    }

    await apigateway.post(
      `/conversation/${conversationId}/message?conversation_id=${conversationId}`,
      payload
    )

    const getSentMessages = (await apigateway.get(`/conversation/${conversationId}`, {
      conversationId: conversationId,
      pageSize: 1,
    })) as Array<Message>

    let newMessage = getSentMessages[0]

    newMessage = {
      ...newMessage,
      content:
        newMessage.type === 'IMAGE' || newMessage.type === 'VIDEO' || newMessage.type === 'FILE'
          ? await readAWSS3File(CHAT_FILE_FOLDER, newMessage.content)
          : newMessage.content,
    }

    let sortedMessages = sortBy(
      prop('createdAt'),
      update(appendTempMessages.length - 1, newMessage, appendTempMessages)
    )

    sortedMessages = uniqBy(prop('id'), sortedMessages)

    dispatch({
      type: 'SET_GROUPED_MESSAGES',
      payload: Object.entries(groupMessages(sortedMessages)),
    })

    dispatch({
      type: 'SET_MESSAGES',
      payload: sortedMessages,
    })

    dispatch({
      type: 'SET_TEMP_MESSAGE_ID',
      payload: '',
    })
    // SEND MESSAGE TO THE SERVER
  } catch (error) {
    dispatch({
      type: 'SET_TEMP_MESSAGE_ID',
      payload: '',
    })

    const errorMessage = { ...tempMessage, hasError: true }

    let sortedMessages = sortBy(
      prop('createdAt'),
      update(appendTempMessages.length - 1, errorMessage, appendTempMessages)
    )

    sortedMessages = uniqBy(prop('id'), sortedMessages)

    await locallyStoreErrorMessage(errorMessage)

    dispatch({
      type: 'SET_GROUPED_MESSAGES',
      payload: Object.entries(groupMessages(sortedMessages)),
    })

    dispatch({
      type: 'SET_MESSAGES',
      payload: sortedMessages,
    })

    if (resendMessage) {
      const { config, message, status } = formatErrors(error)
      alert(message)
    }
  }
}

export const listenToGuideMessage = async (
  dispatch: MessageDispatch,
  messages: Array<Message>,
  conversationId: string,
  guideId: string
) => {
  try {
    const listenedMessages = (await apigateway.get(`/conversation/${conversationId}`, {
      conversationId: conversationId,
      pageSize: 1,
    })) as Array<Message>

    let guideMessage = listenedMessages.find((message) => message.userId === guideId) as Message

    if (!guideMessage) return

    const messageExists = messages.find((message) => message.id === guideMessage.id)

    if (messageExists) return

    guideMessage = {
      ...guideMessage,
      content:
        guideMessage.type === 'IMAGE' ||
        guideMessage.type === 'VIDEO' ||
        guideMessage.type === 'FILE'
          ? await readAWSS3File(CHAT_FILE_FOLDER, guideMessage.content)
          : guideMessage.content,
    }

    let sortedMessages = sortBy(prop('createdAt'), [...messages, guideMessage])

    sortedMessages = uniqBy(prop('id'), sortedMessages)

    dispatch({
      type: 'SET_GROUPED_MESSAGES',
      payload: Object.entries(groupMessages(sortedMessages)),
    })

    dispatch({
      type: 'SET_MESSAGES',
      payload: sortedMessages,
    })

    await readMessage(dispatch, conversationId)
  } catch (error) {
    const { config, message, status } = formatErrors(error)
  }
}

export const loadMoreMessages = async (
  dispatch: MessageDispatch,
  messages: Array<Message>,
  conversationId: string,
  pageSize: number,
  page: number
) => {
  try {
    let getOLDMessages = (await apigateway.get(`/conversation/${conversationId}`, {
      conversationId: conversationId,
      pageSize: CHAT_LIMIT,
      page: page + 1,
    })) as Array<Message>

    if (!getOLDMessages || getOLDMessages.length === 0) {
      return
    }

    getOLDMessages = await Promise.all(
      map(
        async (message) => ({
          ...message,
          content:
            message?.type === 'IMAGE' || message?.type === 'VIDEO' || message?.type === 'FILE'
              ? await readAWSS3File(CHAT_FILE_FOLDER, message.content)
              : message.content,
        }),
        getOLDMessages
      )
    )

    let sortedMessages = sortBy(prop('createdAt'), [...getOLDMessages, ...messages])

    sortedMessages = uniqBy(prop('id'), sortedMessages)

    dispatch({
      type: 'SET_GROUPED_MESSAGES',
      payload: Object.entries(groupMessages(sortedMessages)),
    })

    dispatch({
      type: 'SET_MESSAGES',
      payload: sortedMessages,
    })
  } catch (error) {
    const { config, message, status } = formatErrors(error)
  }
}

export const resendMessage = async (
  dispatch: MessageDispatch,
  messages: Array<Message>,
  message: Message,
  authUser: AuthUser
) => {
  await removeLocallyErrorMessage(message.id)

  await sendMessage(
    dispatch,
    messages,
    message.conversationId,
    authUser,
    { content: message.content, type: message.type },
    undefined,
    message.id,
    true
  )
}

const locallyStoreErrorMessage = async (message: Message) => {
  let errorMessages: Array<Message> = []

  const messages = getCookie('_loon_user_error_msgs')

  if (!messages) {
    errorMessages = [...errorMessages, message]
  } else {
    errorMessages = [...JSON.parse(messages), message]
  }

  await setCookie('_loon_user_error_msgs', JSON.stringify(errorMessages), 7)
}

const getLocallyErrorMessage = () => {
  const messages = getCookie('_loon_user_error_msgs')
  if (!messages) {
    return []
  }
  return JSON.parse(messages).filter((message: Message) => message.content !== '')
}

const removeLocallyErrorMessage = async (messageId: string) => {
  const messages = getCookie('_loon_user_error_msgs')
  if (messages) {
    const errorMessages = reject(
      (message: Message) => message.id === messageId,
      JSON.parse(messages)
    )
    await setCookie('_loon_user_error_msgs', JSON.stringify(errorMessages), 7)
  }
}

export const randomTempMsgIdGenerator = () => {
  return uuidv4()
}

const groupMessages = groupBy((message: Message) => {
  return moment.utc(message?.createdAt).local().calendar(null, {
    sameDay: '[Today]',
    lastDay: '[Yesterday]',
    lastWeek: 'dddd, DD MMM',
    sameElse: 'DD MMM, YYYY',
  })
})
