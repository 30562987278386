import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { device } from '../../utils/mediaQueries'

export const Wrapper = styled.div`
  background-color: #ffffff;
  //border-top: 1px solid rgba(193, 192, 195, 0.5);
  filter: drop-shadow(0.5px -0.5px 2px rgba(74, 34, 52, 0.05));
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1.5rem;
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  min-height: 65px;
  display: none;
  //transition: bottom 0.3s;

  @media screen and ${device.smallMobile} {
    &.show {
      display: flex !important;
    }
  }

  @media screen and ${device.mobile} {
    &.show {
      display: flex !important;
    }
  }
`

export const Menu = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #99979b;
  cursor: pointer;
  padding: 0.5rem;
  text-decoration: none;
  background-color: transparent;
  font-family: 'GoldplayMedium', sans-serif;
  font-style: normal;
  font-size: 15.5px;
  font-weight: 400;
  position: relative;
  letter-spacing: 0.03em;

  &.active {
    color: #38313c;
    font-weight: bolder;
    text-decoration: none;

    img {
      filter: brightness(0) saturate(100%);
    }
  }

  &:hover,
  &:focus {
    color: #38313c;
    text-decoration: none;

    img {
      filter: brightness(0) saturate(100%);
    }
  }

  &.home-menu {
    img {
      width: 25px;
    }
  }

  &.doctor-menu {
    img {
      width: 25px;
    }
  }

  &.visits-menu {
    img {
      width: 25px;
    }
  }
`
