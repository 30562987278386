import { Question } from '../context/quesContext'

export const PROFILE_PHOTO_FOLDER = 'avatar'

export const CHAT_FILE_FOLDER = 'chat'

export const CHAT_LIMIT = 30

export const VISIT_STATUS = {
  COMPLETE: 'COMPLETED',
  UNANSWERED: 'IN-PROGRESS',
}

export const BIRTH_CONTROL_FILTER_SMOKER_QUESTIONNAIRES = ['SMOKER', 'SMOKING_FREQUENCY']

export const BIRTH_CONTROL_FILTER_PAST_PRES_QUESTIONNAIRES = [
  'PAST_BIRTH_CONTROL',
  'PAST_PRESCRIPTION',
]

export const BIRTH_CONTROL_QUESTIONS: Array<Question> = [
  {
    id: 'TYPE_OF_BIRTH_CONTROL',
    type: 'BINARY',
    priority: 1,
    option: 'What type of birth control do you prefer?',
    error: '*Please select one birth control type.',
    children: [
      {
        id: 'PILL',
        priority: 1,
        option: 'Pill',
      },
      {
        id: 'PATCH',
        priority: 2,
        option: 'Patch',
      },
      {
        id: 'RING',
        priority: 3,
        option: 'Ring',
      },
      {
        id: 'NOT_SURE',
        priority: 4,
        option: 'I’m not sure',
      },
    ],
  },
  {
    id: 'CURRENT_MEDICATION',
    type: 'MULTIPLE-CHOICE',
    priority: 2,
    option: 'Are you currently taking any of these drugs?',
    error: '*Please select one or more options.',
    children: [
      {
        id: 'FUNGAL_TREATMENT',
        priority: 1,
        option: 'Fungal Treatment',
      },
      {
        id: 'OXCARBAZEPINE',
        priority: 2,
        option: 'Oxcarbazepine',
      },
      {
        id: 'PRIMIDONE',
        priority: 3,
        option: 'Primidone',
      },
      {
        id: 'BARBITURATES',
        priority: 4,
        option: 'Barbiturates',
      },
      {
        id: 'LAMOTRIGINE',
        priority: 5,
        option: 'Lamotrigine',
      },
      {
        id: 'CARBAMAZEPINE',
        priority: 6,
        option: 'Carbamazepine',
      },
      {
        id: 'ST_JOHN_WORT',
        priority: 7,
        option: 'St. John Wort',
      },
      {
        id: 'HIV_TREATMENT',
        priority: 8,
        option: 'HIV Treatment',
      },
      {
        id: 'SEIZURE_MEDS',
        priority: 9,
        option: 'Seizure Meds',
      },
      {
        id: 'TB_TREATMENT',
        priority: 10,
        option: 'TB Treatment',
      },
      {
        id: 'NONE',
        priority: 11,
        option: 'None',
      },
    ],
  },
  {
    id: 'PREGNANT_OR_BREASTFEEDING',
    type: 'MULTIPLE-CHOICE',
    priority: 3,
    option: 'Are you pregnant or breastfeeding?',
    error: '*Please select one or more options.',
    children: [
      {
        id: 'BREASTFEEDING',
        priority: 1,
        option: "I'm Breastfeeding",
      },
      {
        id: 'PREGNANT',
        priority: 2,
        option: "I'm Pregnant",
      },
      {
        id: 'HAD_BABY',
        priority: 3,
        option: 'Had a baby 6 weeks ago',
      },
      {
        id: 'NONE',
        priority: 4,
        option: 'None',
      },
    ],
  },
  {
    id: 'SMOKER',
    type: 'BINARY-SELECT',
    priority: 4,
    option: 'Are you a smoker?',
    error: '*Please select an option.',
    children: [
      {
        id: 'YES',
        priority: 1,
        option: 'Yes',
      },
      {
        id: 'NO',
        priority: 2,
        option: 'No',
      },
    ],
  },
  {
    id: 'SMOKING_FREQUENCY',
    type: 'BINARY',
    priority: 5,
    option: 'How often do you smoke in a week?',
    error: '*Please select an option.',
    children: [
      {
        id: '1_3_TIMES',
        priority: 1,
        option: '1-3 times',
      },
      {
        id: '4_7_TIMES',
        priority: 2,
        option: '4-7 times',
      },
      {
        id: '7_TIMES',
        priority: 3,
        option: '7+ times',
      },
    ],
  },
  {
    id: 'MEDICAL_CONDITIONS',
    type: 'MULTIPLE-CHOICE',
    priority: 6,
    option: 'Do you have any of these medical conditions?',
    error: '*Please select one or more options.',
    children: [
      {
        id: 'CANCER',
        priority: 1,
        option: 'Cancer',
      },
      {
        id: 'HIGH_BLOOD_PRESSURE',
        priority: 2,
        option: 'High Blood Pressure',
      },
      {
        id: 'DEEP_VEIN_THROMBOSIS',
        priority: 3,
        option: 'Deep Vein Thrombosis',
      },
      {
        id: 'DIABETES',
        priority: 4,
        option: 'Diabetes',
      },
      {
        id: 'LUPUS',
        priority: 5,
        option: 'Lupus',
      },
      {
        id: 'LIVER_CONDITIONS',
        priority: 6,
        option: 'Liver Conditions',
      },
      {
        id: 'STROKE',
        priority: 7,
        option: 'Stroke',
      },
      {
        id: 'EPILEPSY_SEIZURES',
        priority: 8,
        option: 'Epilepsy or Seizures',
      },
      {
        id: 'HEART_ATTACK',
        priority: 9,
        option: 'Heart Attack',
      },
      {
        id: 'SICKLE_CELL',
        priority: 10,
        option: 'Sickle Cell',
      },
      {
        id: 'NONE',
        priority: 11,
        option: 'None',
      },
    ],
  },
  {
    id: 'HEIGHT',
    type: 'OPEN-HEIGHT',
    priority: 7,
    option: 'How tall are you?',
    error: '*Please enter your height.',
    notice: '*In feet and inches (in feet and inches)',
  },
  {
    id: 'WEIGHT',
    type: 'OPEN-WEIGHT',
    priority: 8,
    option: 'How much do you weight?',
    error: '*Please enter your weight.',
    notice: '*In pounds (in pound (lb))',
  },
  {
    id: 'SCHEDULED_SURGERY',
    type: 'BINARY-SELECT',
    priority: 9,
    option: 'Have you had surgery or have you been scheduled for surgery?',
    error: '*Please select an option.',
    children: [
      {
        id: 'YES',
        priority: 1,
        option: 'Yes',
      },
      {
        id: 'NO',
        priority: 2,
        option: 'No',
      },
    ],
  },
  {
    id: 'BLOOD_PRESSURE_READING',
    type: 'OPEN-BLOOD-PRESSURE',
    priority: 10,
    option:
      'What is your most recent blood pressure reading? We need your blood pressure reading within the last 6 months to prescribe birth control.',
    notice:
      '*You can get a free blood pressure reading at any Walmart store, Walgreens or CVS pharmacy',
    error: '*Please select your systolic and diastolic pressure.',
    children: [
      {
        id: 'SYSTOLIC_099',
        priority: 1,
        option: '0-99',
      },
      {
        id: 'SYSTOLIC_100139',
        priority: 2,
        option: '100-139',
      },
      {
        id: 'SYSTOLIC_140',
        priority: 3,
        option: '140+',
      },
      {
        id: 'DIASTOLIC_059',
        priority: 1,
        option: '0-59',
      },
      {
        id: 'DIASTOLIC_6089',
        priority: 2,
        option: '60-89',
      },
      {
        id: 'DIASTOLIC_90',
        priority: 3,
        option: '90+',
      },
    ],
  },
  {
    id: 'PAST_BIRTH_CONTROL',
    type: 'BINARY-SELECT',
    priority: 11,
    option: 'Have you been prescribed birth control in the past?',
    error: '*Please select an option.',
    children: [
      {
        id: 'YES',
        priority: 1,
        option: 'Yes',
      },
      {
        id: 'NO',
        priority: 2,
        option: 'No',
      },
    ],
  },
  {
    id: 'PAST_PRESCRIPTION',
    type: 'OPEN-TEXTAREA',
    priority: 12,
    option: 'If yes, what were you prescribed in the past?',
    error: '*Type "None" if you"ve not been prescribed any birth control medication.',
  },
  {
    id: 'MAIN_BIRTH_CONTROL_REASON',
    type: 'MULTIPLE-CHOICE',
    priority: 13,
    option: 'What is your main reason for using birth control?',
    error: '*Please select one or more options.',
    children: [
      {
        id: 'PREVENT_PREGNANCY',
        priority: 1,
        option: 'Prevent Pregnancy',
      },
      {
        id: 'SKIP_PERIODS',
        priority: 2,
        option: 'Skip Periods',
      },
      {
        id: 'BETTER_SKIN',
        priority: 3,
        option: 'Better Skin (acne, etc)',
      },
      {
        id: 'REDUCE_CRAMPS',
        priority: 4,
        option: 'Reduce Cramps',
      },
      {
        id: 'REDUCE_HEAVY_PERIODS',
        priority: 5,
        option: 'Reduce Heavy Periods',
      },
      {
        id: 'NONE',
        priority: 6,
        option: 'None',
      },
    ],
  },
  {
    id: 'HISTORY_OF_MIGRAINES',
    type: 'BINARY-SELECT',
    priority: 14,
    option: 'Do you have a history of migraines?',
    error: '*Please select an option.',
    children: [
      {
        id: 'YES',
        priority: 1,
        option: 'Yes',
      },
      {
        id: 'NO',
        priority: 2,
        option: 'No',
      },
    ],
  },
  {
    id: 'NO_HOMONES',
    type: 'BINARY-SELECT',
    priority: 15,
    option: 'Has a doctor told you not to take hormones?',
    error: '*Please select an option.',
    children: [
      {
        id: 'YES',
        priority: 1,
        option: 'Yes',
      },
      {
        id: 'NO',
        priority: 2,
        option: 'No',
      },
    ],
  },
  {
    id: 'IDENTIFICATION',
    type: 'OPEN-IMAGE',
    priority: 16,
    option:
      "Please upload an ID that includes your picture, name, and date of birth. We're required by law to do this. We accept School IDs, Driver's License, or Passport.",
    error:
      "*Please upload all required images (Your driver's license, passport or school ID and a selfie image).",
    children: [
      {
        id: 'ID_UPLOADER',
        priority: 1,
        option: 'Identification',
      },
      {
        id: 'SELFIE_UPLOADER',
        priority: 2,
        option: 'Selfie',
      },
    ],
  },
]
