import React, { ChangeEvent, useEffect, useState } from 'react'
import PropTypes, { InferProps } from 'prop-types'
import {
  Button,
  AlertPanel,
  OptionsPanel,
  ImageUploadGrid,
  UploadIcon,
  UploadInput,
  UploadStatus,
  UploadedDocx,
} from './InsuranceCardUpload.style'
import { icons } from '../../assets/icons'
import { isEmpty } from 'ramda'
import { v4 as uuidv4 } from 'uuid'
import readImageFile from '../../utils/readImageFile'
import uploadAWSS3File from '../../utils/uploadAWSS3File'

const UPLOAD_FILE_FOLDER = 'document'

InsuranceCardUpload.propTypes = {
  isProcessingCardDetails: PropTypes.func as PropTypes.Validator<boolean>,
  onSubmitCardDetails: PropTypes.func as PropTypes.Validator<
    (file: Record<string, string>) => void
  >,
}

function InsuranceCardUpload({
  onSubmitCardDetails,
  isProcessingCardDetails,
}: InferProps<typeof InsuranceCardUpload.propTypes>) {
  const [file, setFile] = useState<Record<string, string>>({
    id_front_view: '',
    id_back_view: '',
  })
  const [fileDataURL, setFileDataURL] = useState<Record<string, string>>({
    id_front_view: '',
    id_back_view: '',
  })
  const [uploadingFile, setUploadingFile] = useState<Record<string, boolean>>({
    id_front_view: false,
    id_back_view: false,
  })
  const [isDraggingOver, setIsDraggingOver] = useState<Record<string, boolean>>({
    id_front_view: false,
    id_back_view: false,
  })

  const preventDragDropDefaults = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDragOver = (event: any, name: string) => {
    preventDragDropDefaults(event)
    setIsDraggingOver((drag) => ({ ...drag, [name]: true }))
  }

  const handleDragEnter = (event: any, name: string) => {
    preventDragDropDefaults(event)
    setIsDraggingOver((drag) => ({ ...drag, [name]: true }))
  }

  const handleDragLeave = (event: any, name: string) => {
    preventDragDropDefaults(event)
    setIsDraggingOver((drag) => ({ ...drag, [name]: false }))
  }

  const handleDrop = async (event: any, name: string) => {
    preventDragDropDefaults(event)
    setIsDraggingOver((drag) => ({ ...drag, [name]: false }))
    const files = [...event.dataTransfer.files]
    if (files) {
      await handleFileProcessing(files[0], name)
    }
  }

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files, name } = event.target
    if (files && files.length > 0) {
      await handleFileProcessing(files[0], name)
    }
  }

  const handleFileProcessing = async (file: File, name: string) => {
    setUploadingFile((uploads) => ({ ...uploads, [name]: true }))

    const filename = file?.name

    const fileType = file?.type || 'image/jpeg'

    const fileKey = `${UPLOAD_FILE_FOLDER}/${name}_${uuidv4()}.${
      filename ? filename.split('.').pop() : 'jpeg'
    }`

    const uploadedFile = await uploadAWSS3File(file, fileKey, fileType, 'public')

    setUploadingFile((uploads) => ({ ...uploads, [name]: false }))

    const fileData = await readImageFile(file)

    setFileDataURL((data) => ({ ...data, [name]: fileData }))

    setFile((file) => {
      return { ...file, [name]: uploadedFile }
    })
  }

  const handleInsuranceSubmit = () => {
    onSubmitCardDetails(file)
  }

  return (
    <>
      <AlertPanel>
        <img src={icons.iconAlertInfoWarning} alt={'alert warning'} />
        Upload your insurance card information.
      </AlertPanel>
      <OptionsPanel>
        <ImageUploadGrid
          isDraggingOver={isDraggingOver['id_front_view']}
          onDrop={(event: any) => handleDrop(event, 'id_front_view')}
          onDragOver={(event: any) => handleDragOver(event, 'id_front_view')}
          onDragEnter={(event: any) => handleDragEnter(event, 'id_front_view')}
          onDragLeave={(event: any) => handleDragLeave(event, 'id_front_view')}
        >
          {uploadingFile['id_front_view'] ? (
            <img src={icons.iconLoader} alt="loader" />
          ) : (
            <>
              {!isEmpty(fileDataURL['id_front_view']) ? (
                <>
                  <UploadStatus className={'id_front_view'}>
                    <img src={icons.iconRadioInputCheckedV1} alt={`upload_status_id_front_view}`} />
                  </UploadStatus>

                  <UploadedDocx>
                    <img src={fileDataURL['id_front_view']} alt="id_front_view" />
                  </UploadedDocx>
                </>
              ) : (
                <UploadIcon>
                  <img src={icons.iconIdentificationUploader} alt="id_front_view" />
                  <p>{`Upload a photo of the front part of your insurance card`}</p>
                </UploadIcon>
              )}
            </>
          )}
          <UploadInput>
            <input
              type="file"
              name={'id_front_view'}
              accept="image/*"
              onChange={(event) => handleFileUpload(event)}
            />
          </UploadInput>
        </ImageUploadGrid>

        <ImageUploadGrid
          isDraggingOver={isDraggingOver['id_back_view']}
          onDrop={(event: any) => handleDrop(event, 'id_back_view')}
          onDragOver={(event: any) => handleDragOver(event, 'id_back_view')}
          onDragEnter={(event: any) => handleDragEnter(event, 'id_back_view')}
          onDragLeave={(event: any) => handleDragLeave(event, 'id_back_view')}
        >
          {uploadingFile['id_back_view'] ? (
            <img src={icons.iconLoader} alt="loader" />
          ) : (
            <>
              {!isEmpty(fileDataURL['id_back_view']) ? (
                <>
                  <UploadStatus className={'id_back_view'}>
                    <img src={icons.iconRadioInputCheckedV1} alt={`upload_status_id_back_view}`} />
                  </UploadStatus>

                  <UploadedDocx>
                    <img src={fileDataURL['id_back_view']} alt="id_back_view" />
                  </UploadedDocx>
                </>
              ) : (
                <UploadIcon>
                  <img src={icons.iconIdentificationBackUploader} alt="id_back_view" />
                  <p>{`Upload a photo of the back part of your insurance card`}</p>
                </UploadIcon>
              )}
            </>
          )}
          <UploadInput>
            <input
              type="file"
              name={'id_back_view'}
              accept="image/*"
              onChange={(event) => handleFileUpload(event)}
            />
          </UploadInput>
        </ImageUploadGrid>
      </OptionsPanel>
      <Button
        label={'Save and Continue'}
        type={'button-primary'}
        onClick={handleInsuranceSubmit}
        disabled={
          isEmpty(fileDataURL['id_front_view']) ||
          isEmpty(fileDataURL['id_back_view']) ||
          isProcessingCardDetails
        }
        loading={isProcessingCardDetails}
      />
    </>
  )
}

export default InsuranceCardUpload
