import React, { useState } from 'react'
import {
  OptionsPanel,
  ListCol,
  CheckBoxItem,
  CheckBoxLabel,
  QuestionPanel,
  ErrorNotice,
  TextNotice,
} from './CheckBoxFormBuilder.styles'
import PropTypes, { InferProps } from 'prop-types'
import { isEmpty, reject } from 'ramda'

type Answer = {
  questionKey: string
  answers: Array<string>
}

type ChangeEvent = React.ChangeEvent<HTMLInputElement>

const CHECKBOX_NONE = 'NONE'

export interface BuilderLabel {
  id: string
  label: string
}

export interface BuilderInputs {
  id: string
  priority: number
  option: string
}

CheckBoxFormBuilder.defaultProps = {
  builderValue: [],
}

CheckBoxFormBuilder.propTypes = {
  builderLabel: PropTypes.object as PropTypes.Validator<BuilderLabel>,
  builderInputs: PropTypes.array.isRequired as PropTypes.Requireable<Array<BuilderInputs>>,
  onInputChange: PropTypes.func as PropTypes.Validator<(answers: Answer) => void>,
  error: PropTypes.string as PropTypes.Validator<string | undefined>,
  builderValue: PropTypes.array as PropTypes.Validator<Array<string>>,
  notice: PropTypes.string as PropTypes.Requireable<string>,
}

function CheckBoxFormBuilder({
  builderLabel,
  builderInputs,
  builderValue,
  error,
  onInputChange,
  notice,
}: InferProps<typeof CheckBoxFormBuilder.propTypes>) {
  const [checkedItems, setCheckedItems] = useState<Array<string>>(builderValue || [])

  const checkBoxNone = `${builderLabel?.id}_${CHECKBOX_NONE}`

  const handleCheckBoxChange = (event: ChangeEvent) => {
    const { value, checked } = event.target

    if (checked) {
      if (value === 'NONE') {
        setCheckedItems([value])
        onInputChange({ questionKey: builderLabel?.id, answers: [value] })
        return
      }

      if (checkedItems.includes('NONE')) {
        const removedCheckedItems = reject((checkedItem) => checkedItem === 'NONE', checkedItems)
        setCheckedItems([...removedCheckedItems, value])
        onInputChange({ questionKey: builderLabel?.id, answers: [...removedCheckedItems, value] })
        return
      }

      setCheckedItems([...checkedItems, value])
      onInputChange({ questionKey: builderLabel?.id, answers: [...checkedItems, value] })
    } else {
      const removedCheckedItems = reject((checkedItem) => checkedItem === value, checkedItems)
      setCheckedItems(removedCheckedItems)
      onInputChange({ questionKey: builderLabel?.id, answers: removedCheckedItems })
    }
  }

  return (
    <>
      <QuestionPanel>
        <label htmlFor={builderLabel?.id}>{builderLabel?.label}</label>
      </QuestionPanel>
      {/* {notice && <TextNotice>{notice}</TextNotice>} */}
      <OptionsPanel>
        {builderInputs &&
          builderInputs.map((input, index) => (
            <ListCol key={index} checked={checkedItems.includes(input.id)}>
              <CheckBoxItem
                size={'small'}
                name={input.id === 'NONE' ? checkBoxNone : input.id}
                value={input.id}
                checked={checkedItems.includes(input.id)}
                onChange={handleCheckBoxChange}
              />
              <CheckBoxLabel htmlFor={input.id === 'NONE' ? checkBoxNone : input.id}>
                {input.option}
              </CheckBoxLabel>
            </ListCol>
          ))}
      </OptionsPanel>
      {error && !isEmpty(error) && <ErrorNotice>{error}</ErrorNotice>}
    </>
  )
}

export default CheckBoxFormBuilder
