import React, { useRef, useState, useEffect } from 'react'
import { InputWrapper, SendIcon, MsgInputWrapper } from './MessageAutoSizeInput.styles'
import PropTypes, { InferProps } from 'prop-types'
import { Icon } from '../../index'

type ChangeEvent = (event: React.ChangeEvent<HTMLTextAreaElement>) => void
type BlurEvent = (event: React.FocusEvent<HTMLTextAreaElement>) => void
type FocusEvent = (event: React.FocusEvent<HTMLTextAreaElement>) => void
type KeyPressEvent = (event: React.KeyboardEvent<HTMLTextAreaElement>) => void
type ResizeEvent = (event: any) => void

MessageAutoSizeInput.defaultProps = {
  disabled: false,
  onBlur: undefined,
  onFocus: undefined,
  onResize: undefined,
}

MessageAutoSizeInput.propTypes = {
  width: PropTypes.number,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) as PropTypes.Validator<
    string | number
  >,
  error: PropTypes.bool as PropTypes.Validator<boolean>,
  placeholder: PropTypes.string as PropTypes.Validator<string>,
  className: PropTypes.string,
  disabled: PropTypes.bool as PropTypes.Validator<boolean>,
  onBlur: PropTypes.func as PropTypes.Validator<BlurEvent>,
  onFocus: PropTypes.func as PropTypes.Validator<FocusEvent>,
  onChange: PropTypes.func.isRequired as PropTypes.Validator<ChangeEvent>,
  onKeyPress: PropTypes.func.isRequired as PropTypes.Validator<KeyPressEvent>,
  onSendPress: PropTypes.func.isRequired as PropTypes.Validator<() => void>,
  onResize: PropTypes.func as PropTypes.Validator<ResizeEvent>,
  setFocus: PropTypes.bool as PropTypes.Validator<boolean>,
}

function MessageAutoSizeInput({
  width,
  name,
  value,
  error,
  placeholder,
  className,
  disabled,
  onChange,
  onKeyPress,
  onSendPress,
  onResize,
  setFocus,
}: InferProps<typeof MessageAutoSizeInput.propTypes>) {
  const inputElement = useRef<any>(null)
  const [hasFocused, setHasFocused] = useState(false)

  useEffect(() => {
    if (setFocus) {
      inputElement.current.focus()
    }
  }, [setFocus])

  return (
    <InputWrapper className={className || ''} hasFocused={hasFocused}>
      <MsgInputWrapper
        ref={inputElement}
        onResize={onResize}
        maxRows={7}
        error={error ? 'error' : 'no-error'}
        width={width || undefined}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={(event) => {
          setHasFocused(false)
        }}
        onFocus={(event) => {
          setHasFocused(true)
        }}
        name={name}
        value={value}
        disabled={disabled}
        onKeyPress={onKeyPress}
      />
      <SendIcon onClick={onSendPress}>
        <Icon id={'chatSend'} className={'send-icon'} />
      </SendIcon>
    </InputWrapper>
  )
}

export default MessageAutoSizeInput
