import styled from 'styled-components/macro'
import { device } from '../../utils/mediaQueries'

export const Wrapper = styled.div`
  text-align: center;
  position: absolute;
  display: inline-block;
  left: 50%;
  transform: translate(-50%, 0);
  top: 35%;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'GoldplayMedium', sans-serif;
    font-weight: 500;
    color: #99979b;
  }

  svg.loader__icon {
    width: 35px;
    color: #a46edd;
  }

  @media screen and ${device.smallMobile} {
  }

  @media screen and ${device.mobile} {
  }
`
