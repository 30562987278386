import React from 'react'
import { Wrapper, Button, CardContent, CardImage } from './VisitActivitiesCard.style'
import PropTypes, { InferProps } from 'prop-types'
import { images } from '../../assets/images'
import { VisitType } from '../../context/quesContext'

VisitActivitiesCard.defaultProps = {
  className: '',
}

VisitActivitiesCard.propTypes = {
  className: PropTypes.string,
  visitType: PropTypes.string as PropTypes.Validator<VisitType>,
  onVisitClicked: PropTypes.func as PropTypes.Validator<(type: VisitType) => void>,
}

function VisitActivitiesCard({
  visitType,
  onVisitClicked,
  className,
}: InferProps<typeof VisitActivitiesCard.propTypes>) {
  const getVisitTypeName = {
    'BIRTH-CONTROL': {
      title: 'Birth control visit',
      subtitle: 'View information about your birth control visits.',
      image: images.imageBirthControlVisitActivityGraphic,
    },
  }

  return (
    <Wrapper className={className || ''}>
      <CardContent>
        <p className={'content-title'}>{getVisitTypeName[visitType].title}</p>
        <p className={'content-subtitle'}>{getVisitTypeName[visitType].subtitle}</p>
        <Button
          label={'See previous visits'}
          type={'button-primary'}
          onClick={() => onVisitClicked(visitType)}
          disabled={false}
          loading={false}
        />
      </CardContent>
      <CardImage>
        <span>Under review</span>
        <img src={getVisitTypeName[visitType].image} alt={'your provider'} />
      </CardImage>
    </Wrapper>
  )
}

export default VisitActivitiesCard
