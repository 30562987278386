import styled, { css } from 'styled-components/macro'
import { MessageAutoSizeInput } from '../Inputs'
import { device } from '../../utils/mediaQueries'

type ConvoStyleProps = {
  checked?: boolean | null
}

export const Wrapper = styled.div`
  display: contents;

  div.chat-bubble__wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden scroll;
    position: relative;
  }

  div.chat-input__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    background-color: #fbfbfb;
    padding: 0 0 20px;
    position: sticky;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
  }
`

export const ChatInput = styled(MessageAutoSizeInput)`
  width: 80%;
  float: right;

  @media screen and ${device.smallMobile} {
    width: 85%;
  }

  @media screen and ${device.mobile} {
    width: 85%;
  }
`

export const AttachBtn = styled.div`
  background-color: #ffffff;
  box-shadow: rgb(0 0 0 / 7%) 0 0 12px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin-left: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  .attachment-icon__paperclip {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    width: 25px;
    cursor: pointer;
  }

  input {
    margin: 0;
    padding: 0;
    position: absolute;
    height: 40px;
    width: 30px;
    cursor: pointer;
    opacity: 0;
    -moz-opacity: 0;
    filter: Alpha(Opacity=0);
  }

  input::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }

  &:hover,
  &:focus {
    background: #ffffff;
  }
`
