import React from 'react'
import { Icon } from '../index'
import { Wrapper } from './Loader.styles'
import { UserAssignedGuide } from '../../context/authContext'
import PropTypes, { InferProps } from 'prop-types'

Loader.propTypes = {
  guide: PropTypes.object as PropTypes.Validator<UserAssignedGuide>,
}

function Loader({ guide }: InferProps<typeof Loader.propTypes>) {
  return (
    <Wrapper>
      <Icon id="loader" className="loader__icon" />
      <span>Reaching out to your provider, {`${guide.firstName} ${guide.lastName}`}.</span>
    </Wrapper>
  )
}

export default Loader
