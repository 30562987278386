import styled, { css } from 'styled-components/macro'
import { device } from '../../utils/mediaQueries'

type ImageWrapperProps = {
  isLoaded?: boolean | null
}

export const ThumbImage = styled.img<ImageWrapperProps>`
  &.thumb {
    ${(props) =>
      props.isLoaded &&
      css`
        position: absolute;
        visibility: hidden !important;
      `}

    visibility: ${(props) => (props.isLoaded ? 'hidden' : 'visible')};
    filter: blur(2px);
    -webkit-filter: blur(2px);
    transform: scale(1);
    transition: visibility 0ms ease 400ms;
  }

  @media screen and ${device.smallMobile} {
  }

  @media screen and ${device.mobile} {
  }
`

export const FullImage = styled.img<ImageWrapperProps>`
  position: absolute;

  ${(props) =>
    props.isLoaded &&
    css`
       position: unset; !important;
    `}

  &.full {
    opacity: ${(props) => (props.isLoaded ? 1 : 0)};
    transition: opacity 400ms ease 0ms;
  }

  @media screen and ${device.smallMobile} {
  }

  @media screen and ${device.mobile} {
  }
`
