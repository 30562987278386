import React, { useCallback, useEffect, useState } from 'react'
import { Container, PageWrapper } from './Dashboard.style'
import { Navigation as TopNav, BottomNavigation as BottomNav, Toast } from '../../components'
import PropTypes, { InferProps } from 'prop-types'
import { useIdleTimer } from 'react-idle-timer'
import {
  autoLoadUnReadMessages,
  getConversation,
  signOut,
  useAuthContext,
} from '../../context/authContext'
import { ToastProvider } from 'react-toast-notifications'
import { blinkingNotifyTab } from '../../utils/blinkingNotifyTab'

Dashboard.propTypes = {
  children: PropTypes.node,
}

const IDLE_TIMEOUT = 1000 * 60 * 30

function Dashboard({ children }: InferProps<typeof Dashboard.propTypes>) {
  const [hasNewMessage, setHasNewMessage] = useState(false)

  const {
    state: { authUser, assignedGuide },
    dispatch,
  } = useAuthContext()

  // Load messages conversation with assigned guide
  useEffect(() => {
    if (!assignedGuide) return
    ;(async function loadConversation() {
      await getConversation(dispatch, assignedGuide.id)
    })()
  }, [assignedGuide, dispatch])

  const getUnReadMessagesCount = useCallback(
    async (assignedGuide) => {
      const unreadCount = (await autoLoadUnReadMessages(dispatch, assignedGuide.id)) as number

      setHasNewMessage(unreadCount > 0)

      if (unreadCount > 0) {
        blinkingNotifyTab(`New ${unreadCount > 1 ? 'Messages' : 'Message'}`)
      }
    },
    [dispatch]
  )

  useEffect(() => {
    if (!assignedGuide) return

    getUnReadMessagesCount(assignedGuide) // Better solution for this

    const interval = setInterval(async () => {
      await getUnReadMessagesCount(assignedGuide)
    }, 5000)

    return () => clearInterval(interval)
  }, [assignedGuide, getUnReadMessagesCount])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: IDLE_TIMEOUT,
    onIdle: () => signOut(dispatch),
    onActive: () => false,
    debounce: 250,
  })

  return (
    <ToastProvider>
      <Container>
        <TopNav
          onSignOut={() => signOut(dispatch)}
          authUser={authUser}
          hasNewMessage={hasNewMessage}
        />
        <Toast />
        <PageWrapper>{children}</PageWrapper>
        <BottomNav />
      </Container>
    </ToastProvider>
  )
}

export default Dashboard
