import imageLoonDiva from './image-loon-diva.svg'
import imageLoonBag from './image-loon-bag.svg'
import imageProviderPhoto from './image-provider-photo.svg'
import imageActivityCardWhiteBg from './image-activity-card-white-background.svg'
import imageActivityCardTransparentBg from './image-activity-card-transparent-background.svg'
import imageActivityCardPurpleBg from './image-activity-card-purple-background.svg'
import imageBirthControlVisitActivityGraphic from './image-birth-control-visit-activity-graphic.svg'
import imageOverviewEmptyGraphic from './image-overview-empty-graphic.svg'
import imageActivityEmptyGraphic from './image-activity-empty-graphic.svg'
import imageProfileCoverPhoto from './image-profile-cover-photo.svg'
import imageProfileCoverPhotoMobile from './image-profile-cover-photo-(mobile).svg'

import imageAvatarPlaceholder from './image-avatar-placeholder.png'
import imageSampleProviderPhoto from './image-sample-provider-photo.png'
import imageSampleIDCard from './image-sample-id-card.png'
import imageSampleSelfie from './image-sample-selfie.png'

export const images = {
  imageLoonDiva: imageLoonDiva,
  imageLoonBag: imageLoonBag,
  imageProviderPhoto: imageProviderPhoto,
  imageActivityCardWhiteBg: imageActivityCardWhiteBg,
  imageActivityCardTransparentBg: imageActivityCardTransparentBg,
  imageActivityCardPurpleBg: imageActivityCardPurpleBg,
  imageBirthControlVisitActivityGraphic: imageBirthControlVisitActivityGraphic,
  imageOverviewEmptyGraphic: imageOverviewEmptyGraphic,
  imageActivityEmptyGraphic: imageActivityEmptyGraphic,
  imageProfileCoverPhoto: imageProfileCoverPhoto,
  imageProfileCoverPhotoMobile: imageProfileCoverPhotoMobile,

  imageAvatarPlaceholder: imageAvatarPlaceholder,
  imageSampleProviderPhoto: imageSampleProviderPhoto,
  imageSampleIDCard: imageSampleIDCard,
  imageSampleSelfie: imageSampleSelfie,
}
