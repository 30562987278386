import React, { useEffect, useState } from 'react'
import PropTypes, { InferProps } from 'prop-types'
import {
  ModalBody,
  Wrapper,
  BackAction,
  NavWrapper,
  LoonBag,
  OptionsPanel,
  Input,
  InputLabel,
  Button,
  InputRow,
  ErrorNotice,
  Container,
} from './SubscriptionByCardModal.style'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js'
import { icons } from '../../assets/icons'
import { images } from '../../assets/images'
import { isEmpty } from 'ramda'
import useValidator from '../../hooks/useValidator'
import { ConfirmCardPaymentData } from '@stripe/stripe-js'
import {
  AuthToken,
  confirmVisitPayment,
  getVisitPaymentIntent,
  Insurance,
  updateInsurance,
  useAuthContext,
} from '../../context/authContext'
import { VisitType } from '../../context/quesContext'

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: 'GoldplayMedium, sans-serif',
      fontSize: '17px',
      fontWeight: '500',
      fontStyle: 'normal',
      fontSmoothing: 'antialiased',
      letterSpacing: '0.03em',
      '::placeholder': {
        fontFamily: 'Goldplay, sans-serif',
        color: '#a7a7a7',
        fontWeight: '300',
        fontSmoothing: 'antialiased',
        fontStyle: 'normal',
        fontSize: '16px',
      },
    },
    invalid: {
      color: '#ff8c42',
    },
  },
}

SubscriptionByCardModal.propTypes = {
  authToken: PropTypes.object as PropTypes.Validator<AuthToken | undefined>,
  type: PropTypes.string as PropTypes.Validator<VisitType>,
  onCloseFullModal: PropTypes.func as PropTypes.Validator<() => void>,
  onCompleteSubscription: PropTypes.func as PropTypes.Validator<() => void>,
}

function SubscriptionByCardModal({
  authToken,
  type,
  onCloseFullModal,
  onCompleteSubscription,
}: InferProps<typeof SubscriptionByCardModal.propTypes>) {
  const stripe = useStripe()
  const elements = useElements()

  const [validator, showValidationMessage] = useValidator()
  const [showDetailsPage, setShowDetailsPage] = useState(false)
  const [isProcessingCard, setIsProcessingCard] = useState(false)
  const [cardName, setCardName] = useState('')
  const [paymentIntent, setPaymentIntent] = useState<{ id: string; clientSecret: string }>()
  const [cardErrors, setCardErrors] = useState({
    cardNumber: undefined,
    cardExpiry: undefined,
    cardCvc: undefined,
  })

  const { dispatch } = useAuthContext()

  useEffect(() => {
    if (!authToken) return
    ;(async function getPaymentIntent() {
      const {
        data: { data },
      } = (await getVisitPaymentIntent(authToken.token, type)) as any
      setPaymentIntent({
        id: data.paymentIntentId,
        clientSecret: data.clientSecret,
      })
    })()
  }, [authToken, type])

  const handleCardInputChange = (event: any) => {
    const { elementType, error } = event
    if (error && elementType) {
      setCardErrors((cardErrors) => ({
        ...cardErrors,
        [elementType]: error.message,
      }))

      setTimeout(() => {
        setCardErrors((cardErrors) => ({
          ...cardErrors,
          [elementType]: undefined,
        }))
      }, 3000)
    }
  }

  const handleCardSubscription = async () => {
    if (!stripe || !elements) {
      const message = 'Oops. Issue handling your card details. Please try again!'
      alert(message)
      return
    }

    if (!validator.allValid()) {
      showValidationMessage(true)
      return
    }

    if (!paymentIntent) {
      const message = 'Oops. Issue handling your card details. Please contact our support team!'
      alert(message)
      return
    }

    setIsProcessingCard(true)

    const response: any = await stripe.confirmCardPayment(paymentIntent.clientSecret, {
      payment_method: {
        card: elements?.getElement(CardNumberElement),
      },
    } as ConfirmCardPaymentData)

    if (response) {
      if (response.error) {
        const message = `Oops. Payment failed: ${response.error.message}. Please try again!`
        alert(message)
        setIsProcessingCard(false)
        return
      }

      if (response.paymentIntent.status !== 'succeeded') {
        const message = `Oops. Issue handling your card details. Please contact our support team!`
        alert(message)
        setIsProcessingCard(false)
        return
      }
    }

    if (!authToken) return

    const {
      data: { data },
    } = await confirmVisitPayment(authToken.token, paymentIntent.id)

    if (data.status !== 'succeeded') {
      const message = `Oops. Issue handling your card details. Please contact our support team!`
      alert(message)
      setIsProcessingCard(false)
      return
    }

    const payload = {
      provider: 'SELF',
    } as Insurance

    await updateInsurance(dispatch, payload, authToken.token, () => {
      setIsProcessingCard(false)

      onCompleteSubscription()
    })
  }

  const handleGoBack = () => {
    if (showDetailsPage) {
      setShowDetailsPage(false)
      return
    }
    onCloseFullModal()
  }

  return (
    <>
      <Wrapper size={'xl'} show={true} onHide={() => false} animation={false}>
        <NavWrapper>
          <BackAction to="#" onClick={handleGoBack}>
            <img className="back" src={icons.iconBackArrowLeft} alt={'back'} />
            Back
          </BackAction>
        </NavWrapper>
        <ModalBody>
          <Container>
            <p className={'container-title'}>{`ALMOST DONE!`}</p>
            <p className={'container-subtitle'}>{`Enter your credit card information.`}</p>
            <OptionsPanel>
              <InputLabel htmlFor={'cardName'}>Name on Card</InputLabel>
              <Input
                value={cardName}
                name={'cardName'}
                placeholder={'Eg. Jennifer Jacobs'}
                onChange={(event) => setCardName(event.target.value)}
                error={false}
              />
              {validator.message('cardName', cardName, 'required|string|min:2', {
                messages: {
                  required: 'Name on card is required.',
                },
              })}

              <InputLabel htmlFor={'number'}>Card number</InputLabel>
              <CardNumberElement
                options={{ placeholder: 'Eg: 4242 4242 4242 4242', ...ELEMENT_OPTIONS }}
                onChange={handleCardInputChange}
              />
              {cardErrors.cardNumber && <ErrorNotice>{cardErrors.cardNumber}</ErrorNotice>}
              <InputRow>
                <div className={'expiryDate'}>
                  <InputLabel htmlFor={'expiryDate'}>Expiry date</InputLabel>
                  <CardExpiryElement
                    options={{ placeholder: '', ...ELEMENT_OPTIONS }}
                    onChange={handleCardInputChange}
                  />
                  {cardErrors.cardExpiry && <ErrorNotice>{cardErrors.cardExpiry}</ErrorNotice>}
                </div>
                <div className={'cvv'}>
                  <InputLabel htmlFor={'cvv'}>CVV</InputLabel>
                  <CardCvcElement
                    options={{ placeholder: '', ...ELEMENT_OPTIONS }}
                    onChange={handleCardInputChange}
                  />
                  {cardErrors.cardCvc && <ErrorNotice>{cardErrors.cardCvc}</ErrorNotice>}
                </div>
              </InputRow>
            </OptionsPanel>
            <Button
              label={paymentIntent ? 'Save and Continue' : 'Please wait...'}
              type={'button-primary'}
              onClick={handleCardSubscription}
              disabled={isEmpty(cardName) || isProcessingCard}
              loading={isProcessingCard}
            />
          </Container>
          <LoonBag>
            <img src={images.imageLoonBag} alt={'loon package bag'} />
          </LoonBag>
        </ModalBody>
      </Wrapper>
    </>
  )
}

export default SubscriptionByCardModal
