import styled, { css } from 'styled-components/macro'
import { RadioSize } from './RadioInput'
import { device } from '../../../utils/mediaQueries'
import { icons } from '../../../assets/icons'

type InputWrapperProps = {
  error?: boolean | null
  checked?: boolean | null
  size?: RadioSize | null
}

export const RadioInputContainer = styled.div<InputWrapperProps>`
  display: inline-block;
  position: relative;
  cursor: pointer;

  input {
    display: none;
  }

  ${(props) =>
    props.checked &&
    css`
      input + label span {
        background-size: ${props.size === 'small' ? '20px 20px' : '25px 25px'};
        border: 2px solid #8cc122 !important;
        background: #8cc122 url('${icons.iconRadioInputCheckedV1}') no-repeat center;
      }
    `}

  label {
    margin-bottom: 0;
  }

  input + label span {
    display: inline-block;
    vertical-align: middle;
    width: ${(props) => (props.size && props.size === 'small' ? '20px' : '25px')};
    height: ${(props) => (props.size && props.size === 'small' ? '20px' : '25px')};
    border: 2px solid #626262;
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
    line-height: 10px;
  }

  @media screen and ${device.smallMobile} {
    span::after {
      top: ${(props) => (props.size && props.size === 'small' ? '7px' : '5px')};
    }
  }

  @media screen and ${device.mobile} {
    span::after {
      top: ${(props) => (props.size && props.size === 'small' ? '7px' : '5px')};
    }
  }

  ${(props) =>
    props.error &&
    css`
      input + label span {
        background: #ff6045 !important;
        border: 2px solid #ff6045 !important;
      }

      span::after {
        content: 'x';
        font-family: CeraProBlack, serif;
        font-style: normal;
        font-size: 15px;
        border: none;
        font-weight: bolder;
        color: #ffffff;
        left: 6px;
        top: 2px;
        position: absolute;
        transform: none;
        transition: none;
      }
    `}
`

export const RadioInputWrapper = styled.input<InputWrapperProps>``
