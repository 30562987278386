import styled, { css } from 'styled-components/macro'
import { device } from '../../utils/mediaQueries'
import Microlink from '@microlink/react'

type ChatStyleProps = {
  checked?: boolean | null
  isDraggingOver?: boolean
}

export const Wrapper = styled.div<ChatStyleProps>`
  padding: 20px 5% 200px 5%;
  height: 100%;
  width: 100%;
  overflow: hidden scroll;
  position: relative;

  ${(props) =>
    props.isDraggingOver &&
    css`
      background: rgb(0 0 0 / 1.5%);
    `}

  div.chat-msg-group {
    display: flex;
    position: relative;
    flex-direction: column;
  }

  .chat-msg-group-date__wrapper {
    text-align: center;
    margin: 10px 0 14px;
    position: relative;
  }

  .chat-msg-group-content__wrapper {
    display: inherit;
  }

  .chat-msg-group-date__wrapper span {
    display: inline-block;
    font-size: 11px;
    padding: 4px 16px;
    border-radius: 5px;
    text-transform: uppercase;
    border: 1px solid rgba(193, 192, 195, 0.5);
    box-sizing: border-box;
    font-style: normal;
    line-height: 25px;
    font-family: GoldplayMedium, serif;
    font-weight: 600;
    letter-spacing: 0.03em;
    color: #99979b;
  }

  div.chat-msg-group-loader {
    text-align: center;
    position: absolute;
    display: inline-block;
    top: 0;
    left: 50%;
    z-index: 1;
  }

  .chat-msg-group-loader span {
    border-radius: 50%;
    display: flex;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.07);
    background: #ffffff;
    width: 45px;
    height: 45px;
    align-items: center;
    justify-content: center;
  }

  .chat-msg-group-loader span svg.chat-loader__icon {
    width: 35px;
    color: #a46edd;
  }

  @media screen and ${device.smallMobile} {
    padding-top: 10px;
  }

  @media screen and ${device.mobile} {
    padding-top: 10px;
  }
`

export const InputWrapper = styled.div`
  position: fixed;
  bottom: 0;
  padding: 25px 50px 25px 100px;
  z-index: 99;
  display: flex;
  background: rgb(253, 253, 253);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`

export const InChatBubble = styled.p`
  max-width: 40%;
  display: flex;
  width: fit-content;
  font-style: normal;
  line-height: 25px;
  flex-wrap: wrap;
  font-family: 'GoldplayMedium', sans-serif;
  font-size: 15px;
  align-self: flex-start;
  padding: 10px 15px 12px;
  box-shadow: rgb(0 0 0 / 7%) 0 0 12px;
  border-radius: 15px 25px 5px 25px;
  position: relative;
  overflow-wrap: break-word;
  white-space: pre-line;
  margin-bottom: 15px;
  color: #38313c;
  background: #ffffff;

  a.chat-view__more {
    font-size: 13.5px;
    margin-left: 5px;
    color: #007bff;
  }

  span.chat-filler {
    width: 65px;
    display: inline-block;
    height: 3px;
    background: transparent;
  }

  span.chat-timestamp {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    right: 7px;
    bottom: 0;
    color: #99979b;
    font-size: 11.5px;
  }

  svg.chat-status__icon {
    width: 16px;
    color: #99979b;
    margin-left: 3px;
  }

  button.chat-options {
    opacity: 0;
    position: absolute;
    right: 5px;
    top: 3px;
    pointer-events: none;
    transition: all 0.2s;
    background: #ffffff;
  }

  a.embedded-link {
    text-decoration: none !important;
    color: #007bff;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  &:before {
    content: '';
    position: absolute;
    left: -5px;
    top: 0;
    display: block;
    width: 20px;
    height: 15px;
    background-color: #ffffff;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 100%;
  }

  @media screen and ${device.smallMobile} {
    max-width: 100%;
  }

  @media screen and ${device.mobile} {
    max-width: 100%;
  }
`

export const OutChatBubble = styled.p`
  max-width: 40%;
  align-self: flex-end;
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  font-style: normal;
  line-height: 25px;
  font-family: 'GoldplayMedium', sans-serif;
  font-size: 15px;
  position: relative;
  padding: 10px 15px 12px;
  box-shadow: rgb(0 0 0 / 7%) 0 0 12px;
  border-radius: 25px 10px 25px 5px;
  overflow-wrap: break-word;
  white-space: pre-line;
  margin-bottom: 15px;
  color: #ffffff;
  background: #a46edd;

  a.chat-view__more {
    font-size: 13.5px;
    margin-left: 5px;
    color: #d3e2f1;
    //color: rgb(1, 6, 238);
  }

  span.chat-filler {
    width: 65px;
    display: inline-block;
    height: 3px;
    background: transparent;
  }

  span.chat-timestamp {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    right: 7px;
    bottom: 0;
    color: #ecebed;
    font-size: 11.5px;
  }

  svg.chat-status__icon {
    width: 16px;
    color: #ecebed;
    margin-left: 3px;
  }

  svg.chat-clock__icon {
    width: 11px;
    height: 11px;
    color: #ecebed;
    margin-bottom: 5px;
    margin-right: 5px;
  }

  svg.chat-error__icon {
    color: #ff6060;
    width: 15px;
    height: 15px;
    margin-bottom: 5px;
    margin-right: 5px;
  }

  .chat-options {
    position: absolute;
    right: 5px;
    top: 0;
  }

  .chat-options ul {
    top: 25px;
    //border: 1px solid rgba(193, 192, 195, 0.5);
  }

  .chat-options .chat-options__btn {
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s;
    cursor: pointer;
  }

  .chat-options .chat-options__btn.opened {
    opacity: 1;
    pointer-events: unset;
  }

  &:hover .chat-options .chat-options__btn {
    opacity: 1;
    pointer-events: unset;
  }

  .chat-options button .chat-options__icon {
    color: #ecebed;
    width: 17px;
    height: 17px;
  }

  a.embedded-link {
    text-decoration: none !important;
    color: #d3e2f1;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    //color: rgb(1, 6, 238);
  }

  &:before {
    content: '';
    position: absolute;
    right: -5px;
    top: 0;
    display: block;
    width: 20px;
    height: 15px;
    background-color: #a46edd;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 100%;
  }

  @media screen and ${device.smallMobile} {
    max-width: 100%;
  }

  @media screen and ${device.mobile} {
    max-width: 100%;
  }
`

export const ChatLink = styled(Microlink)`
  font-family: 'GoldplayMedium', sans-serif;
  border-radius: 0.42857em;
  margin-bottom: 5px;
  text-decoration: none !important;

  div.microlink_card__iframe iframe {
    width: fit-content !important;
  }

  footer.microlink_card__content_url span {
    display: none !important;
  }
`

export const ChatMedia = styled.div`
  display: flex;
  width: fit-content;
  max-width: 30%;
  font-style: normal;
  line-height: 25px;
  font-family: 'GoldplayMedium', sans-serif;
  font-size: 15px;
  padding: 4px;
  box-shadow: rgb(0 0 0 / 7%) 0 0 12px;
  border-radius: 5px;
  position: relative;
  overflow-wrap: break-word;
  margin-bottom: 15px;

  &.incoming-chat {
    align-self: flex-start;
    color: #38313c;
    background: #ffffff;
  }

  &.outgoing-chat {
    color: #ffffff;
    background: #a46edd;
    align-self: flex-end;
  }

  img.chat-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  span.chat-timestamp {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    right: 7px;
    bottom: 0;
    font-size: 11.5px;
  }

  svg.chat-status__icon {
    width: 16px;
    margin-left: 3px;
  }

  &.incoming-chat span.chat-timestamp {
    color: #99979b;
  }

  &.outgoing-chat span.chat-timestamp {
    color: #ecebed;
  }

  &.incoming-chat svg.chat-status__icon {
    color: #99979b;
  }

  &.outgoing-chat svg.chat-status__icon {
    color: #ecebed;
  }

  span.chat-timestamp {
    color: #ecebed;
  }

  svg.chat-status__icon {
    color: #ecebed;
  }

  &.outgoing-chat svg.chat-clock__icon {
    width: 11px;
    height: 11px;
    color: #ecebed;
    margin-bottom: 5px;
    margin-right: 5px;
  }

  &.outgoing-chat svg.chat-error__icon {
    color: #ff6060;
    width: 15px;
    height: 15px;
    margin-bottom: 5px;
    margin-right: 5px;
  }

  button.chat-options {
    opacity: 0;
    position: absolute;
    right: 5px;
    top: 3px;
    pointer-events: none;
    transition: all 0.2s;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  &:hover button.chat-options {
    opacity: 1;
    pointer-events: unset;
  }

  button .chat-options__icon {
    color: #ecebed;
    width: 17px;
    height: 17px;
  }

  @media screen and ${device.smallMobile} {
    max-width: 80%;
  }

  @media screen and ${device.mobile} {
    max-width: 80%;
  }
`
