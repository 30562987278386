import React from 'react'
import PropTypes, { InferProps } from 'prop-types'
import { Container, ModalWrapper, Button, Close } from './PhotoViewer.style'

type ClickEvent = () => void

PhotoViewer.propTypes = {
  showModal: PropTypes.bool,
  onCloseModal: PropTypes.func as PropTypes.Validator<ClickEvent>,
  photoDataURL: PropTypes.string,
  onSendPhoto: PropTypes.func as PropTypes.Validator<() => void>,
}

function PhotoViewer({
  showModal,
  onCloseModal,
  photoDataURL,
  onSendPhoto,
}: InferProps<typeof PhotoViewer.propTypes>) {
  return (
    <>
      <ModalWrapper show={showModal} onHide={onCloseModal} centered>
        <Container>
          <Close onClick={onCloseModal}>x</Close>
          <div className={'content-wrapper'}>
            <h3 className={'title'}>Preview</h3>
            <div className={'img-cover'}>
              <img src={photoDataURL || ''} alt="photo previewer" />
            </div>
          </div>
          <Button
            label={'Send'}
            type={'button-primary'}
            onClick={() => {
              onSendPhoto()
              onCloseModal()
            }}
            disabled={false}
            loading={false}
            hideIcon={true}
          />
        </Container>
      </ModalWrapper>
    </>
  )
}

export default PhotoViewer
