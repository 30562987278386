import lazyWithRetry from './utils/lazyWithRetry'

const SignIn = lazyWithRetry(() => import('./pages/Login/Login'))
const GetStarted = lazyWithRetry(() => import('./pages/Onboarding/Profile/Profile'))
// prettier-ignore
const AssignedProvider = lazyWithRetry(() => import('./pages/Onboarding/AssignedProvider/AssignedProvider'))
// prettier-ignore
const MedicalQuestionnaire = lazyWithRetry(() => import('./pages/Onboarding/MedicalQuestions/MedicalQuestions'))
const Subscription = lazyWithRetry(() => import('./pages/Onboarding/Subscription/Subscription'))
const Home = lazyWithRetry(() => import('./pages/Home/Home'))
const Messages = lazyWithRetry(() => import('./pages/Messages/Messages'))
const Profile = lazyWithRetry(() => import('./pages/Profile/Profile'))
const BirthControlVisit = lazyWithRetry(() => import('./pages/Visits/BirthControl/BirthControl'))
// prettier-ignore
const HistoryBirthControlVisit = lazyWithRetry(() => import('./pages/VisitHistories/BirthControl/BirthControl'))
const NotFound = lazyWithRetry(() => import('./pages/NotFound/NotFound'))

export const paths = {
  LOGIN_URL_PATH: '/',
  REGISTER_URL_PATH: '/onboarding',
  GET_STARTED_URL_PATH: '/onboarding/get-started',
  ASSIGNED_PROVIDER_URL_PATH: '/onboarding/get-started/assigned-provider',
  MEDICAL_QUESTIONNAIRES_URL_PATH: '/onboarding/get-started/medical-questionnaires',
  SUBSCRIPTION_URL_PATH: '/onboarding/get-started/subscription',
  HOME_URL_PATH: '/home',
  PROFILE_URL_PATH: '/profile',
  MESSAGE_URL_PATH: '/messages',
  BIRTH_CONTROL_VISIT_URL_PATH: '/birth-control-visit/new',
  BIRTH_CONTROL_HISTORY_URL_PATH: '/birth-control-visit/histories',
}

const routes = [
  {
    path: paths.LOGIN_URL_PATH,
    exact: true,
    component: SignIn,
    isPrivate: false,
  },
  {
    path: paths.REGISTER_URL_PATH,
    exact: true,
    component: SignIn,
    isPrivate: false,
  },
  {
    path: paths.GET_STARTED_URL_PATH,
    exact: true,
    component: GetStarted,
    isPrivate: false,
  },
  {
    path: paths.ASSIGNED_PROVIDER_URL_PATH,
    exact: true,
    component: AssignedProvider,
    isPrivate: false,
  },
  {
    path: paths.MEDICAL_QUESTIONNAIRES_URL_PATH,
    exact: true,
    component: MedicalQuestionnaire,
    isPrivate: false,
  },
  {
    path: paths.SUBSCRIPTION_URL_PATH,
    exact: true,
    component: Subscription,
    isPrivate: false,
  },
  {
    path: paths.HOME_URL_PATH,
    exact: true,
    component: Home,
    isPrivate: true,
  },
  {
    path: paths.PROFILE_URL_PATH,
    exact: true,
    component: Profile,
    isPrivate: true,
  },
  {
    path: paths.MESSAGE_URL_PATH,
    exact: true,
    component: Messages,
    isPrivate: true,
  },
  {
    path: paths.BIRTH_CONTROL_VISIT_URL_PATH,
    exact: true,
    component: BirthControlVisit,
    isPrivate: true,
  },
  {
    path: paths.BIRTH_CONTROL_HISTORY_URL_PATH,
    exact: true,
    component: HistoryBirthControlVisit,
    isPrivate: true,
  },
  {
    path: '/*',
    exact: true,
    component: NotFound,
    isPrivate: false,
  },
]

export default routes
