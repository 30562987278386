import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { getCookie } from './utils/getCookie'
import { paths } from './routes'
import Dashboard from './pages/Dashboard/Dashboard'

interface ExtendedRouteProps extends RouteProps {
  isPrivate: boolean
}

function AppRoute({ component: Component, path, isPrivate, ...rest }: ExtendedRouteProps) {
  if (!Component) return null
  const authTokenCookie = getCookie('_loon_tokid')
  const authUserCookie = getCookie('_loon_usrid')
  return (
    <>
      {!isPrivate ? (
        <Route
          {...rest}
          exact
          path={path}
          render={(props) =>
            authTokenCookie && authUserCookie ? (
              <Redirect
                to={{
                  pathname: paths.HOME_URL_PATH,
                  state: { from: props.location },
                }}
              />
            ) : (
              <Component {...props} />
            )
          }
        />
      ) : (
        <Route
          {...rest}
          exact
          path={path}
          render={(props) => {
            window.scrollTo(0, 0)
            if (authTokenCookie && authUserCookie) {
              return (
                <Dashboard {...props}>
                  <Component {...props} />
                </Dashboard>
              )
            } else {
              return (
                <Redirect
                  to={{
                    pathname: paths.LOGIN_URL_PATH,
                    state: { from: props.location },
                  }}
                />
              )
            }
          }}
        />
      )}
    </>
  )
}

export default AppRoute
