import styled from 'styled-components/macro'
import { device } from '../../utils/mediaQueries'

export const ErrorWrapper = styled.div`
  display: flex;
  height: inherit;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h5 {
    font-family: CeraProBlack, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: #3a3a3a;
    text-transform: none;
    margin-bottom: 1.5rem;
  }

  p {
    font-style: normal;
    font-size: 16px;
    color: #5f5f5f;
    margin-top: 0;
    margin-bottom: 0.5rem;

    @media screen and ${device.smallMobile} {
      font-size: 15px;
    }

    @media screen and ${device.mobile} {
      font-size: 18px;
    }
  }

  span {
    color: #45abe0 !important;
    cursor: pointer;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: block;
  background: #fdfdfd;
  overflow: hidden;
`

export const Container = styled.div`
  height: 100%;
  width: auto;
  padding: 0 !important;
  margin-right: auto;
  margin-left: auto;
`
