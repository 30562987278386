import env from './env'
import { getCookie } from './getCookie'
import { isEmpty } from 'ramda'
const SLACK_WEBHOOK_URL = env('LOON_SLACK_WEBHOOK_URL')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const slack = require('slack-notify')(SLACK_WEBHOOK_URL)

const cookie = getCookie('_loon_tokid')

const auth = cookie && JSON.parse(cookie)

export const reportErrorToSlack = (
  message: string,
  status: string,
  action: string,
  path: string,
  api: string,
  phoneNumber: string
) => {
  if (env('LOON_ENV') === 'development') {
    return
  }

  slack.bug({
    text: `${message}`,
    fields: {
      PhoneNumber: !isEmpty(phoneNumber) ? phoneNumber : auth?.phoneNumber,
      Status: status,
      Action: action,
      Path: path,
      APIData: api,
    },
  })
}
