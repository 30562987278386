import styled, { css } from 'styled-components/macro'
import { Modal } from 'react-bootstrap'
import { device } from '../../utils/mediaQueries'
import { Link } from 'react-router-dom'
import { Input as NormalInput, Button as Btn } from '../../components'

type FullModalStyleProps = {
  checked?: boolean | null
}

export const Wrapper = styled(Modal)`
  .modal-dialog.modal-xl {
    max-width: inherit;
    margin: 0;
    height: 100%;
  }

  &.modal {
    z-index: 7070 !important;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }

  .modal-content {
    background: #ffffff;
    border: none;
    border-radius: 0;
    height: 100%;
    width: 100%;
    flex-direction: column;
    padding-top: 66.09px;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    overflow-y: scroll;
  }

  @media screen and ${device.smallMobile} {
    .modal-dialog {
    }

    .modal-content {
    }
  }

  @media screen and ${device.mobile} {
    .modal-dialog {
    }

    .modal-content {
    }
  }
`

export const NavWrapper = styled.nav`
  background-color: #ffffff !important;
  padding: 0.8rem 3rem;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 6060;
  height: 66.09px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  display: flex;
  align-items: center;

  @media screen and ${device.smallMobile} {
    padding: 0.8rem 1.5rem;
    height: 70px;
  }

  @media screen and ${device.mobile} {
    padding: 0.8rem 1.5rem;
    height: 70px;
  }
`

export const BackAction = styled(Link)`
  display: flex;
  padding-top: 0.32rem;
  padding-bottom: 0.32rem;
  line-height: inherit;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.9);
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  font-family: 'GoldplayMedium', sans-serif;
  font-style: normal;
  color: #38313c;
  font-size: 16px;
  align-items: center;

  &:hover {
    text-decoration: none;
    color: #38313c;
  }

  img {
    margin-right: 15px;
    width: 20px;
  }
`

export const ModalBody = styled(Modal.Body)`
  width: inherit;
  height: inherit;
  padding: 0 3rem 3rem;
  background: #ffffff;
  position: relative;

  @media screen and ${device.smallMobile} {
    padding: 1rem;
  }

  @media screen and ${device.mobile} {
    padding: 1rem;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  max-width: 30%;
  flex-direction: column;
  position: relative;
  padding: 50px 15px;
  margin-right: auto;
  margin-left: auto;

  p.container-title {
    font-family: 'GoldplayMedium', sans-serif;
    font-style: normal;
    font-size: 16px;
    color: #99979b;
    margin-bottom: 0;
  }

  p.container-subtitle {
    letter-spacing: 0.03em;
    font-family: 'DM Serif Display', sans-serif;
    font-size: 25px;
    line-height: initial;
    color: #38313c;
    margin-bottom: 0;
  }

  @media screen and ${device.smallMobile} {
    z-index: 1;
    max-width: 100%;
    padding-top: 40px;
  }

  @media screen and ${device.mobile} {
    z-index: 1;
    max-width: 100%;
    padding-top: 40px;
  }
`

export const HeadTitle = styled.div``

export const SubTitle = styled.div``

export const LoonBag = styled.div`
  position: fixed;
  bottom: 0;
  right: -45px;

  img {
    width: 180px;
  }

  @media screen and ${device.smallMobile} {
    right: -40px;
    img {
      width: 150px;
    }
  }

  @media screen and ${device.mobile} {
    right: -40px;
    img {
      width: 150px;
    }
  }
`

export const OptionsPanel = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;

  .srv-validation-message {
    margin-top: -26px;
    margin-bottom: 20px;
    padding-top: 5px;
    text-align: left;
    font-size: 13px;
    letter-spacing: 0.4px;
    color: #ff8c42;
    font-family: 'GoldplayMedium', sans-serif;
    width: 100%;

    :last-child {
      margin-bottom: 0 !important;
    }
  }

  input,
  .StripeElement {
    height: 45px;
    padding: 12px 16px;
    border: 1.5px solid #c0bdc1;
    border-radius: 5px;
    font-family: 'GoldplayMedium', sans-serif;
    font-weight: 500;
    font-size: 17px;
    line-height: 1.2;
    color: #000000;
    outline: 0;
    text-rendering: optimizeLegibility;
    letter-spacing: 0.03em;

    margin: 5px 0 30px;

    :last-child {
      margin-bottom: 0 !important;
    }
  }

  input::placeholder {
    color: #a7a7a7 !important;
    font-weight: 300 !important;
    font-size: 16px !important;
    font-family: 'Goldplay', sans-serif !important;
  }

  input::-webkit-input-placeholder {
    color: #a7a7a7 !important;
    font-weight: 300 !important;
    font-size: 16px !important;
    font-family: 'Goldplay', sans-serif !important;
  }

  input:-ms-input-placeholder {
    color: #a7a7a7 !important;
    font-weight: 300 !important;
    font-size: 16px !important;
    font-family: 'Goldplay', sans-serif !important;
  }

  input:focus,
  .StripeElement--focus {
    outline-offset: 0 !important;
    border: 1.5px solid #38313c !important;
    background: unset !important;
  }

  @media screen and ${device.smallMobile} {
  }

  @media screen and ${device.mobile} {
  }
`

export const ErrorNotice = styled.div`
  margin-top: -26px;
  margin-bottom: 20px;
  padding-top: 5px;
  text-align: left;
  font-size: 13px;
  letter-spacing: 0.4px;
  color: #ff8c42;
  font-family: 'GoldplayMedium', sans-serif;
  width: 100%;

  @media screen and ${device.smallMobile} {
  }

  @media screen and ${device.mobile} {
  }
`

export const ListCol = styled.div<FullModalStyleProps>`
  display: flex;
  align-items: center;
  padding: 10px 12px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.checked &&
    css`
      background: #fbfafb;
      border: 0.6px solid #8cc12285;
      box-sizing: border-box;
      border-radius: 5px;
    `}

  @media screen and ${device.smallMobile} {
  }

  @media screen and ${device.mobile} {
  }
`

export const InputLabel = styled.label`
  font-family: 'GoldplayMedium', sans-serif;
  font-style: normal;
  font-size: 15px;
  line-height: 17px;
  width: inherit;
  margin-bottom: 0.5rem;
  color: #706b77;

  @media screen and ${device.smallMobile} {
    font-size: 16px;
  }

  @media screen and ${device.mobile} {
    font-size: 16px;
  }
`

export const Input = styled(NormalInput)`
  @media screen and ${device.smallMobile} {
  }

  @media screen and ${device.mobile} {
  }
`

export const InputRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  --gap: 20px;
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));

  > * {
    margin: var(--gap) 0 0 var(--gap);
  }

  padding: 0;

  div.expiryDate {
    flex: 1;
  }

  div.cvv {
    flex: 1;
  }

  @media screen and ${device.smallMobile} {
    width: 100%;
  }

  @media screen and ${device.mobile} {
    width: auto;
  }
`

export const Button = styled(Btn)`
  margin-top: 15%;
  width: 100%;

  @media screen and ${device.smallMobile} {
    margin-top: 20%;
  }

  @media screen and ${device.mobile} {
    margin-top: 28%;
  }
`
