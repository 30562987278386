import { isEmpty } from 'ramda'

export const formatMessageText = (
  text: string
): {
  url: string[]
  text: string
} => {
  const urls = text.match(/\b(http|https)?:\/\/\S+/gi) || []

  if (isEmpty(urls)) {
    return {
      url: [],
      text: text,
    }
  }

  const isNotAnImageURLS = urls.filter(
    (url) =>
      url.match(/(s3-|s3\.)?(.*)\.amazonaws\.com\S+\.(?:jpeg|jpg|gif|png|JPG|JPEG|GIF|PNG)/) ===
        null || url.match(/\.(?:jpeg|jpg|gif|png|JPG|JPEG|GIF|PNG)$/) === null
  )

  if (isEmpty(isNotAnImageURLS)) {
    return {
      url: [],
      text: '',
    }
  }

  isNotAnImageURLS.forEach((link) => {
    const hyperlink = `<a href="${link}" target="_blank"  rel="noopener noreferrer" class="embedded-link">${link}</a>`
    text = text.replace(link, hyperlink)
  })

  return {
    url: isNotAnImageURLS,
    text: text,
  }
}
