import iconLoonLogo from './icon-loon-logo.svg'
import iconDropdown from './icon-dropdown.png'
import iconLoader from './icon-loader.svg'
import iconButtonAction from './icon-button-action.svg'
import iconPhoneVerified from './icon-phone-verified-like.svg'
import iconVerifiedProvider from './icon-verified-provider.svg'
import iconNotificationBellV1 from './icon-notification-bell-v1.svg'
import iconNotificationBellV2 from './icon-notification-bell-v2.svg'
import iconSelectedVisit from './icon-selected-visit.svg'
import iconUnSelectedVisit from './icon-unselected-visit.svg'
import iconBackArrowLeft from './icon-back-arrow-left.svg'
import iconRadioInputCheckedV2 from './icon-radio-input-checked-v2.svg'
import iconRadioInputCheckedV1 from './icon-radio-input-checked-v1.svg'
import iconCheckboxInputCheckedV2 from './icon-checkbox-input-checked-v2.svg'
import iconCheckboxInputCheckedV1 from './icon-checkbox-input-checked-v1.svg'
import iconAlertInfoWarning from './icon-alert-info-warning.svg'
import iconIdentificationUploader from './icon-identification-uploader.svg'
import iconSelfieUploader from './icon-selfie-uploader.svg'
import iconIdentificationBackUploader from './icon-identification-back-uploader.svg'
import iconCongratsSuccessStar from './icon-congrats-success-star.svg'
import iconMoreToggle from './icon-more-toggle.svg'
import iconMessageCloseSidebar from './icon-message-close-sidebar.svg'
import iconUpdateSuccessfulCheck from './icon-update-successful-check.svg'
import iconUploadPhoto from './icon-upload-photo.svg'
import iconBottomNavHome from './icon-bottom-nav-home.svg'
import iconBottomNavMessages from './icon-bottom-nav-messages.svg'
import iconBottomNavVisits from './icon-bottom-nav-visits.svg'
import iconNotificationSuccess from './icon-notification-success.svg'
import iconChatImageDropdown from './icon-chat-image-dropdown.svg'

export const icons = {
  iconLoonLogo: iconLoonLogo,
  iconDropdown: iconDropdown,
  iconLoader: iconLoader,
  iconButtonAction: iconButtonAction,
  iconPhoneVerified: iconPhoneVerified,
  iconVerifiedProvider: iconVerifiedProvider,
  iconNotificationBellV1: iconNotificationBellV1,
  iconNotificationBellV2: iconNotificationBellV2,
  iconSelectedVisit: iconSelectedVisit,
  iconUnSelectedVisit: iconUnSelectedVisit,
  iconBackArrowLeft: iconBackArrowLeft,
  iconRadioInputCheckedV2: iconRadioInputCheckedV2,
  iconRadioInputCheckedV1: iconRadioInputCheckedV1,
  iconCheckboxInputCheckedV2: iconCheckboxInputCheckedV2,
  iconCheckboxInputCheckedV1: iconCheckboxInputCheckedV1,
  iconAlertInfoWarning: iconAlertInfoWarning,
  iconIdentificationUploader: iconIdentificationUploader,
  iconSelfieUploader: iconSelfieUploader,
  iconIdentificationBackUploader: iconIdentificationBackUploader,
  iconCongratsSuccessStar: iconCongratsSuccessStar,
  iconMoreToggle: iconMoreToggle,
  iconMessageCloseSidebar: iconMessageCloseSidebar,
  iconUpdateSuccessfulCheck: iconUpdateSuccessfulCheck,
  iconUploadPhoto: iconUploadPhoto,
  iconBottomNavHome: iconBottomNavHome,
  iconBottomNavMessages: iconBottomNavMessages,
  iconBottomNavVisits: iconBottomNavVisits,
  iconNotificationSuccess: iconNotificationSuccess,
  iconChatImageDropdown: iconChatImageDropdown,
}
