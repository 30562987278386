import styled from 'styled-components/macro'
import { device } from '../../../utils/mediaQueries'
import { Input, TextBox } from '../../index'

export const QuestionPanel = styled.div`
  background: #f2ecf9;
  box-sizing: border-box;
  border-radius: 8px;
  color: #393340;
  padding: 15px;
  font-size: 16px;
  font-family: 'GoldplayMedium', sans-serif;
  letter-spacing: 0.5px;
  line-height: 28px;
  margin-top: 40px;
  margin-bottom: 40px;

  label {
    margin-bottom: 0;
  }

  span {
  }

  @media screen and ${device.smallMobile} {
    font-size: 17px;
  }

  @media screen and ${device.mobile} {
    font-size: 17px;
  }
`

export const OptionsPanel = styled.div`
  display: flex;
  padding-right: 15px;
  padding-left: 15px;
  flex-direction: column;
`

export const FormsCol = styled.div`
  display: flex;
  padding: 0;

  @media screen and ${device.smallMobile} {
    width: 100%;
  }

  @media screen and ${device.mobile} {
    width: auto;
  }
`

export const FormInput = styled(Input)`
  @media screen and ${device.smallMobile} {
  }

  @media screen and ${device.mobile} {
  }

  @media screen and ${device.tablet} {
  }
`

export const ErrorNotice = styled.div`
  font-size: 13.5px;
  letter-spacing: 0.4px;
  color: #ff8c42;
  font-family: 'GoldplayMedium', sans-serif;
  font-weight: 400;
  width: 100%;
  margin-top: 15px;

  @media screen and ${device.smallMobile} {
  }

  @media screen and ${device.mobile} {
  }
`
