import React, { createContext, useContext, useEffect, useReducer } from 'react'
import apigateway from '../utils/apigateway'
import * as H from 'history'
import moment from 'moment'
import axios, { AxiosResponse } from 'axios'
import env from '../utils/env'
import { setCookie } from '../utils/setCookie'
import { formatErrors, transformRequest, transformResponse } from '../utils/request'
import { paths } from '../routes'
import { getCookie } from '../utils/getCookie'
import { FullPageLoader } from '../components'
import readAWSS3File from '../utils/readAWSS3File'
import { CHAT_FILE_FOLDER, PROFILE_PHOTO_FOLDER } from '../utils/constants'
import Cookies from 'js-cookie'
import queryString from 'querystring'
import { map, prop, sortBy, uniqBy } from 'ramda'
import { QuestionnaireAnswers, VisitType } from './quesContext'
import { Conversation, Message } from './msgContext'

axios.defaults.baseURL = env('LOON_API_URL')

axios.defaults.headers.common['Accept'] = 'application/json'

export type AuthUser = {
  id: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  profilePicture: string
  streetAddress: string
  apartment: string
  city: string
  state: string
  zipCode: string | number
  description?: string
  createdAt?: string
  updatedAt?: string
  customerId?: string
  coupon?: string
  unreadCount?: number
  patientDetails?: {
    dateOfBirth: string
    gender: 'MALE' | 'FEMALE' | 'TRANSGENDER' | 'NON-BINARY'
    allergies: string
    currentMedication: string
  }
  insurance: Insurance
  subscription?: {
    nextChargeDate: string
    subscriptionPlan: string
    subscriptionPlans: any
    last4Digits: string
    expirationDate: string
    postalCode: string
  }
  coordinates?: {
    latitude: number
    longitude: number
  }
}

export type AuthToken = {
  userId: string
  email: string
  phoneNumber: string
  expiresAt: string
  token: string
}

export type UserAssignedGuide = {
  id: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  profilePicture: string
  description: string
  zipCode: string | number
  roles?: Array<'CARE-HEALTH-GUIDE' | 'MENTAL-HEALTH-GUIDE'>
  createdAt?: string
  updatedAt?: string
}

export type Insurance = {
  bin: string
  cardBackUrl: string
  cardFrontUrl: string
  memberId: string
  memberName: string
  pcn: string
  planName: string
  provider: 'BCBS' | 'SELF'
  rxGroup: string
}

export type Toast = {
  message: string
  appearance: 'success' | 'error' | 'warning' | 'info'
  autoDismiss: boolean
  autoDismissTimeout?: number
  show: boolean
}

type AuthState = {
  authUser?: AuthUser
  authToken?: AuthToken
  assignedGuide?: UserAssignedGuide
  toast?: Toast
  conversation?: Conversation
  convoSharedFiles?: Array<Message>
  removeToast: boolean
  isProcessingAuth: boolean
  isLoadingConversation: boolean
  isProcessingInsurance: boolean
  isSyncingAuthUser: boolean
}

type AuthDispatch = (action: AuthAction) => void

type AuthAction =
  | { type: 'SET_AUTH_USER'; payload: AuthUser | undefined }
  | { type: 'SET_AUTH_TOKEN'; payload: AuthToken | undefined }
  | { type: 'SET_ASSIGNED_GUIDE'; payload: UserAssignedGuide | undefined }
  | { type: 'SET_CONVERSATION'; payload: Conversation | undefined }
  | { type: 'SET_CONVO_SHARED_FILES'; payload: Array<Message> }
  | { type: 'PROCESS_AUTH'; payload: boolean }
  | { type: 'PROCESS_INSURANCE'; payload: boolean }
  | { type: 'LOAD_CONVERSATION'; payload: boolean }
  | { type: 'SYNC_AUTH'; payload: boolean }
  | { type: 'SHOW_TOAST'; payload: Toast | undefined }
  | { type: 'REMOVE_TOAST'; payload: boolean }

const initialState: AuthState = {
  authUser: undefined,
  authToken: undefined,
  assignedGuide: undefined,
  toast: undefined,
  conversation: undefined,
  convoSharedFiles: [],
  removeToast: false,
  isProcessingAuth: false,
  isLoadingConversation: true,
  isProcessingInsurance: false,
  isSyncingAuthUser: false,
}

const AuthStateContext = createContext<{ state: AuthState; dispatch: AuthDispatch } | undefined>(
  undefined
)

function AuthReducer(state = initialState, action: AuthAction): AuthState {
  switch (action.type) {
    case 'SET_AUTH_USER':
      return {
        ...state,
        authUser: action.payload,
      }
    case 'SET_AUTH_TOKEN':
      return {
        ...state,
        authToken: action.payload,
      }
    case 'SET_ASSIGNED_GUIDE':
      return {
        ...state,
        assignedGuide: action.payload,
      }
    case 'SET_CONVERSATION':
      return {
        ...state,
        conversation: action.payload,
      }
    case 'SET_CONVO_SHARED_FILES':
      return {
        ...state,
        convoSharedFiles: action.payload,
      }
    case 'LOAD_CONVERSATION':
      return {
        ...state,
        isLoadingConversation: action.payload,
      }
    case 'PROCESS_AUTH':
      return {
        ...state,
        isProcessingAuth: action.payload,
      }
    case 'PROCESS_INSURANCE':
      return {
        ...state,
        isProcessingInsurance: action.payload,
      }
    case 'SYNC_AUTH':
      return {
        ...state,
        isSyncingAuthUser: action.payload,
      }
    case 'SHOW_TOAST':
      return {
        ...state,
        toast: action.payload,
      }
    case 'REMOVE_TOAST':
      return {
        ...state,
        removeToast: action.payload,
      }
    default:
      return state
  }
}

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(AuthReducer, initialState)

  useEffect(() => {
    ;(async function syncAuthUser() {
      const authTokenCookie = JSON.parse(getCookie('_loon_tokid') || '{}')
      if (authTokenCookie.token) {
        try {
          dispatch({ type: 'SYNC_AUTH', payload: true })

          const {
            data: { data: authUser },
          }: any = (await getAuthUser(authTokenCookie.token)) as AxiosResponse<AuthUser>

          const assignedGuide = (await getAssignedGuide(authTokenCookie.token)) as UserAssignedGuide

          if (Object.keys(authUser).length === 0) return

          dispatch({
            type: 'SET_AUTH_USER',
            payload: {
              ...authUser,
              profilePicture: await readAWSS3File(PROFILE_PHOTO_FOLDER, authUser?.profilePicture),
            },
          })

          dispatch({ type: 'SET_AUTH_TOKEN', payload: authTokenCookie })

          dispatch({ type: 'SET_ASSIGNED_GUIDE', payload: assignedGuide })

          dispatch({ type: 'SYNC_AUTH', payload: false })
        } catch (error) {
          dispatch({ type: 'SYNC_AUTH', payload: false })
          signOut(dispatch)
        }
      }
    })()
  }, [])
  return (
    <AuthStateContext.Provider value={{ state, dispatch }}>
      {state.isSyncingAuthUser ? <FullPageLoader /> : children}
    </AuthStateContext.Provider>
  )
}

function useAuthContext() {
  const context = useContext(AuthStateContext)
  if (context === undefined) {
    throw new Error('authContext must be used within a AuthProvider')
  }
  return context
}

export { AuthProvider, useAuthContext }

/****
 * ----------------------------------------------------------------
 * Authentication Actions Asynchronous Functions (similar to Redux)
 * ----------------------------------------------------------------
 */
export const sendOTPCode = async (dispatch: AuthDispatch, phoneNumber: string) => {
  try {
    dispatch({ type: 'PROCESS_AUTH', payload: true })
    await apigateway.post('/auth/phone/send-code', {
      phone_number: phoneNumber,
    })
    dispatch({ type: 'PROCESS_AUTH', payload: false })
    return true
  } catch (error) {
    dispatch({ type: 'PROCESS_AUTH', payload: false })
    alert(error.message)
  }
}

export const verifyOTPCode = async (
  dispatch: AuthDispatch,
  phoneNumber: string,
  verificationCode: string,
  history: H.History,
  destinationPath: string,
  isOnboarding: boolean
) => {
  try {
    dispatch({ type: 'PROCESS_AUTH', payload: true })

    const authToken = (await apigateway.post('/auth/phone/verify-code', {
      phoneNumber,
      verificationCode,
    })) as AuthToken

    if (authToken && authToken.phoneNumber !== phoneNumber) {
      dispatch({ type: 'PROCESS_AUTH', payload: false })
      const message = 'Oops. Issue verifying phone number. Please try again!'
      alert(message)
      return
    }

    const {
      data: { data: authUser },
    }: any = (await getAuthUser(authToken.token)) as AxiosResponse<AuthUser>

    if (isOnboarding || (!authUser.firstName && !authUser.lastName && !authUser.email)) {
      dispatch({ type: 'PROCESS_AUTH', payload: false })
      return authToken
    }

    await goToDashboard(dispatch, history, authToken, destinationPath)
  } catch (error) {
    dispatch({ type: 'PROCESS_AUTH', payload: false })
    alert(error.message)
  }
}

export const goToDashboard = async (
  dispatch: AuthDispatch,
  history: H.History,
  authToken: AuthToken,
  destinationPath = '',
  routerState = {}
) => {
  dispatch({ type: 'PROCESS_AUTH', payload: true })

  const {
    data: { data: authUser },
  }: any = (await getAuthUser(authToken.token)) as AxiosResponse<AuthUser>

  const assignedGuide = (await getAssignedGuide(authToken.token)) as UserAssignedGuide

  const expires = Math.ceil(moment.duration(moment(authToken.expiresAt).diff(moment())).asDays())

  await setCookie('_loon_tokid', JSON.stringify(authToken), expires)

  await setCookie('_loon_usrid', JSON.stringify(authUser), expires)

  dispatch({ type: 'SET_AUTH_TOKEN', payload: authToken })

  dispatch({
    type: 'SET_AUTH_USER',
    payload: {
      ...authUser,
      profilePicture: await readAWSS3File(PROFILE_PHOTO_FOLDER, authUser?.profilePicture),
    },
  })

  dispatch({ type: 'SET_ASSIGNED_GUIDE', payload: assignedGuide })

  dispatch({ type: 'PROCESS_AUTH', payload: false })

  if (destinationPath) {
    history.replace(destinationPath)
    return
  }

  history.replace(paths.HOME_URL_PATH, routerState)
}

export const updateAuthUser = async (
  dispatch: AuthDispatch,
  payload: AuthUser,
  authToken: AuthToken,
  cb: () => void
) => {
  try {
    dispatch({ type: 'PROCESS_AUTH', payload: true })

    await axios({
      method: 'patch',
      url: `/user`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${authToken.token}`,
      },
      data: payload,
      transformResponse: transformResponse,
      transformRequest: transformRequest,
    })

    dispatch({ type: 'PROCESS_AUTH', payload: false })

    cb()
  } catch (error) {
    dispatch({ type: 'PROCESS_AUTH', payload: false })
    alert(error.message)
  }
}

export const updateInsurance = async (
  dispatch: AuthDispatch,
  payload: Insurance,
  token: string,
  cb: () => void
) => {
  try {
    dispatch({ type: 'PROCESS_INSURANCE', payload: true })

    await axios({
      method: 'patch',
      url: `/user/insurance`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: payload,
      transformResponse: transformResponse,
      transformRequest: transformRequest,
    })

    dispatch({ type: 'PROCESS_INSURANCE', payload: false })

    cb()
  } catch (error) {
    dispatch({ type: 'PROCESS_INSURANCE', payload: false })
    alert(error.message)
  }
}

export const getConversation = async (dispatch: AuthDispatch, guideId: string) => {
  try {
    dispatch({ type: 'LOAD_CONVERSATION', payload: true })

    const conversations = (await apigateway.get(`/conversations`)) as any

    const convoWithGuide: Array<Conversation> = await conversations.filter((conversation: any) => {
      const users = conversation.users.filter((user: any) => user.user.id === guideId)
      return users.length > 0
    })

    if (!(convoWithGuide && convoWithGuide.length > 0)) {
      dispatch({ type: 'LOAD_CONVERSATION', payload: false })
      alert(
        "Oops. Looks like there's issue assigning you to the right healthcare provider. Please contact our support team!"
      )
      return
    }

    dispatch({ type: 'SET_CONVERSATION', payload: convoWithGuide[0] })

    dispatch({ type: 'LOAD_CONVERSATION', payload: false })
  } catch (error) {
    dispatch({ type: 'LOAD_CONVERSATION', payload: false })
    alert(error.message)
  }
}

export const autoLoadUnReadMessages = async (dispatch: AuthDispatch, guideId: string) => {
  try {
    const conversations = (await apigateway.get(`/conversations`)) as any

    const convoWithGuide: Array<Conversation> = await conversations.filter((conversation: any) => {
      const users = conversation.users.filter((user: any) => user.user.id === guideId)
      return users.length > 0
    })

    if (!(convoWithGuide && convoWithGuide.length > 0)) {
      return
    }

    const conversation = convoWithGuide[0]

    if (!conversation) return

    return conversation.unreadMessageCount
  } catch (error) {
    // alert(error.message)
  }
}

export const getSharedMessagesFiles = async (
  dispatch: AuthDispatch,
  conversationId: string,
  pageSize: number
) => {
  try {
    const messages = (await apigateway.get(`/conversation/${conversationId}`, {
      conversationId: conversationId,
      pageSize: pageSize,
    })) as Array<Message>

    if (!messages) return

    let sharedFiles = messages.filter((msg) => ['VIDEO', 'IMAGE', 'FILE'].includes(msg.type))

    if (!sharedFiles) return

    sharedFiles = await Promise.all(
      map(
        async (msgFile: Message) => ({
          ...msgFile,
          content:
            msgFile.type === 'IMAGE' || msgFile.type === 'VIDEO' || msgFile.type === 'FILE'
              ? await readAWSS3File(CHAT_FILE_FOLDER, msgFile.content)
              : msgFile.content,
        }),
        sortBy(prop('createdAt'), sharedFiles)
      )
    )

    sharedFiles = uniqBy(prop('id'), sharedFiles)

    dispatch({
      type: 'SET_CONVO_SHARED_FILES',
      payload: sharedFiles,
    })
  } catch (error) {
    const { config, message, status } = formatErrors(error)
    alert(message)
  }
}

export const getQuestionnaires = async (
  status: string,
  type: VisitType | undefined,
  token: string
) => {
  const paramsQuery = queryString.stringify({
    ...(status && { status: status }),
    ...(type && { type: type }),
  })
  const { data: response } = (await axios({
    method: 'get',
    url: `/questionnaires?${paramsQuery}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    transformResponse: transformResponse,
    transformRequest: transformRequest,
  })) as AxiosResponse<any>

  if (!response.data) {
    return []
  }

  return sortBy(prop('createdAt'), [...response.data]).reverse() as Array<QuestionnaireAnswers>
}

export const showToast = (
  dispatch: AuthDispatch,
  message: string,
  type: 'success' | 'error' | 'warning' | 'info'
) => {
  dispatch({
    type: 'SHOW_TOAST',
    payload: {
      message: message,
      appearance: type,
      autoDismiss: false,
      show: true,
      autoDismissTimeout: 8000,
    },
  })
}

export const getAssignedGuide = async (token: string) => {
  const {
    data: { data: guides },
  }: any = (await getGuides(token)) as any

  const assignedGuides: Array<UserAssignedGuide> = await Promise.all(
    guides.map(async (guide: UserAssignedGuide) => {
      return {
        ...guide,
        profilePicture: await readAWSS3File(PROFILE_PHOTO_FOLDER, guide.profilePicture),
      }
    })
  )

  // Issue with guide list order
  assignedGuides.reverse()

  return assignedGuides[0]
}

export const getGuides = (token: string) => {
  return axios({
    method: 'get',
    url: `/user/guides`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    transformResponse: transformResponse,
  })
}

export const getVisitPaymentIntent = (token: string, type: 'BIRTH-CONTROL') => {
  const payload = { visitType: type }
  return axios({
    method: 'post',
    url: `/billing/visit`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: payload,
    transformResponse: transformResponse,
    transformRequest: transformRequest,
  })
}

export const confirmVisitPayment = (token: string, paymentIntentId: string) => {
  return axios({
    method: 'patch',
    url: `/billing/visit/${paymentIntentId}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    transformResponse: transformResponse,
    transformRequest: transformRequest,
  })
}

const getAuthUser = (token: string) => {
  return axios({
    method: 'get',
    url: `/user/profile`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    transformResponse: transformResponse,
  })
}

export const signOut = (dispatch: AuthDispatch) => {
  dispatch({ type: 'SET_AUTH_USER', payload: undefined })
  dispatch({ type: 'SET_AUTH_TOKEN', payload: undefined })
  Cookies.remove('_loon_tokid')
  Cookies.remove('_loon_usrid')
  window.location.href = `${env('LOON_APP_URL')}${paths.LOGIN_URL_PATH}`
}
