import styled, { css } from 'styled-components/macro'
import { device } from '../../../utils/mediaQueries'

type InputWrapperProps = {
  error?: boolean | null
  width?: number | null
}

export const InputWrapper = styled.input<InputWrapperProps>`
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: 45px;
  padding: 12px 16px;
  border: 1.5px solid #c0bdc1;
  border-radius: 5px;
  font-family: 'GoldplayMedium', sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 1.2;
  color: #000000;
  outline: 0;
  text-rendering: optimizeLegibility;
  margin: 5px 0;
  letter-spacing: 0.03em;

  ${(props) =>
    props.error &&
    css`
      border-color: red !important;
    `}
  &:disabled {
    color: #99979b;
    background: #f5f3f5;
    border: 1.5px solid #c0bdc1;
  }

  &:focus {
    outline-offset: 0;
    border: 1.5px solid #38313c;
  }

  &::-webkit-input-placeholder {
    color: #a7a7a7;
    font-weight: 300;
    font-size: 16px;
    font-family: 'Goldplay', sans-serif;
  }

  &:-ms-input-placeholder {
    color: #a7a7a7;
    font-weight: 300;
    font-size: 16px;
    font-family: 'Goldplay', sans-serif;
  }

  &::placeholder {
    color: #a7a7a7;
    font-weight: 300;
    font-size: 16px;
    font-family: 'Goldplay', sans-serif;
  }

  @media screen and ${device.smallMobile} {
    -webkit-appearance: none !important;
  }

  @media screen and ${device.mobile} {
    -webkit-appearance: none !important;
  }
`
