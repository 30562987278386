import React, { useEffect, useRef, useState } from 'react'
import { Text, ActionBtn } from './MoreLessTextViewer.style'
import PropTypes, { InferProps } from 'prop-types'

MoreLessTextViewer.defaultProps = {
  className: '',
  readMoreText: 'Read more',
  readLessText: 'Read less',
  linesLimit: 3,
}

MoreLessTextViewer.propTypes = {
  text: PropTypes.string as PropTypes.Validator<string>,
  className: PropTypes.string as PropTypes.Validator<string>,
  readMoreText: PropTypes.string as PropTypes.Validator<string>,
  readLessText: PropTypes.string as PropTypes.Validator<string>,
  linesLimit: PropTypes.number as PropTypes.Validator<number>,
}

function MoreLessTextViewer({
  text,
  className,
  linesLimit,
  readMoreText,
  readLessText,
}: InferProps<typeof MoreLessTextViewer.propTypes>) {
  const [readStatus, setReadStatus] = useState(false)
  const [showReadStatus, setShowReadStatus] = useState(false)
  const readMoreElement = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!readMoreElement.current) {
      return
    }

    const divHeight = readMoreElement.current.offsetHeight

    const lineHeight = parseInt(
      window.getComputedStyle(readMoreElement.current).getPropertyValue('line-height')
    )

    const lines = divHeight / lineHeight

    setShowReadStatus(lines > linesLimit)
  }, [linesLimit])

  return (
    <>
      <Text
        readMore={readStatus}
        linesLimit={linesLimit}
        ref={readMoreElement}
        className={className}
      >
        {text}
      </Text>
      {showReadStatus && (
        <ActionBtn onClick={() => setReadStatus(!readStatus)}>
          {readStatus ? `${readLessText}` : `${readMoreText}`}
        </ActionBtn>
      )}
    </>
  )
}

export default MoreLessTextViewer
