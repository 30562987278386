import React from 'react'
import { Wrapper } from './DatePickerInput.styles'
import PropTypes, { InferProps } from 'prop-types'
import { Input } from '../../index'

type ChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => void
type BlurEvent = (event: React.FocusEvent<HTMLInputElement>) => void
type FocusEvent = (event: React.FocusEvent<HTMLInputElement>) => void

DatePickerInput.defaultProps = {
  type: 'text',
  disabled: false,
  onBlur: undefined,
  onFocus: undefined,
}

DatePickerInput.propTypes = {
  type: PropTypes.string as PropTypes.Validator<string>,
  width: PropTypes.number,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) as PropTypes.Validator<
    string | number
  >,
  error: PropTypes.bool,
  placeholder: PropTypes.string as PropTypes.Validator<string>,
  className: PropTypes.string,
  disabled: PropTypes.bool as PropTypes.Validator<boolean>,
  onFocus: PropTypes.func as PropTypes.Validator<FocusEvent>,
  onBlur: PropTypes.func as PropTypes.Validator<BlurEvent>,
  onChange: PropTypes.func.isRequired as PropTypes.Validator<ChangeEvent>,
}

function DatePickerInput({
  type,
  width,
  name,
  value,
  error,
  placeholder,
  className,
  disabled,
  onBlur,
  onFocus,
  onChange,
}: InferProps<typeof DatePickerInput.propTypes>) {
  return (
    <Wrapper className={className || ''}>
      {type === 'date' ? (
        <Input
          className={'date-picker-wrapper'}
          width={width}
          type={'text'}
          value={value}
          name={name}
          placeholder={'mm/dd/yyyy'}
          onChange={onChange}
          onBlur={onBlur}
          error={error}
          disabled={disabled}
          maxLength={10}
        />
      ) : (
        <Input
          className={'date-input-wrapper'}
          type={'text'}
          value={''}
          name={''}
          placeholder={placeholder}
          onChange={() => false}
          onFocus={onFocus}
          error={false}
        />
      )}
    </Wrapper>
  )
}

export default DatePickerInput
