import styled, { css } from 'styled-components/macro'

type SelectStyleProps = {
  error?: boolean | null
  width?: number | null
  setPlaceholder?: boolean | null
}

export const Wrapper = styled.div<SelectStyleProps>`
  position: relative;
  margin: 5px 0;

  select {
    width: ${(props) => (props.width ? `${props.width}px` : '100%')};
    height: 45px;
    padding: 12px 16px;
    border: 1.5px solid #c0bdc1;
    border-radius: 5px;
    font-family: 'GoldplayMedium', sans-serif;
    font-weight: 500;
    font-size: 17px;
    line-height: 1.2;
    color: #000000;
    outline: 0;
    text-rendering: optimizeLegibility;
    letter-spacing: 0.03em;
    -webkit-appearance: none;
    cursor: pointer;

    ${(props) =>
      props.error &&
      css`
        border-color: red !important;
      `}
    &:disabled {
      color: #7a8998;
      background: #f5f7fa;
      border: 1.5px solid #dfe5eb;
    }

    &:focus {
      outline: -webkit-focus-ring-color auto 0;
      transition-duration: 0.3s;
      outline-offset: 0;
      border: 1.5px solid #38313c;
    }

    &::-webkit-input-placeholder {
      color: #a7a7a7;
      font-weight: 300;
      font-size: 16px;
      font-family: 'Goldplay', sans-serif;
    }

    &:-ms-input-placeholder {
      color: #a7a7a7;
      font-weight: 300;
      font-size: 16px;
      font-family: 'Goldplay', sans-serif;
    }

    &::placeholder {
      color: #a7a7a7;
      font-weight: 300;
      font-size: 16px;
      font-family: 'Goldplay', sans-serif;
    }

    option[disabled]:first-child {
      display: none;
    }

    ${(props) =>
      props.setPlaceholder &&
      css`
      color: #a7a7a7; !important;
      font-weight: 300 !important;
      font-size: 16px !important;
      font-family: 'Goldplay', sans-serif !important;

      option {
        color: #a7a7a7 !important;
      }
    `}
  }

  img {
    position: absolute;
    margin: auto;
    top: 20px;
    right: 16px;
    vertical-align: middle;
    width: 10px;
  }
`
