import styled, { css } from 'styled-components/macro'
import { device } from '../../../utils/mediaQueries'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

type InputWrapperProps = {
  hasError?: boolean | number | null
  width?: number | null
}

export const PhoneInputWrapper = styled(PhoneInput)<InputWrapperProps>`
  .PhoneInputCountry {
    margin: auto;
  }

  .PhoneInputCountry,
  .PhoneInputCountrySelect,
  .PhoneInputCountryIcon {
    display: none;
  }

  .PhoneInputCountrySelectArrow {
    display: block;
    border-style: none;
    width: inherit;
    height: inherit;
    opacity: unset;
    transform: none;
    margin-left: -10px;
    margin-right: 10px;
  }

  .PhoneInputCountrySelectArrow:before {
    content: '+1' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.03em;
  }

  input:disabled {
    color: #7a8998 !important;
    background: #f5f7fa !important;
    border-color: #dfe5eb !important;
  }

  input {
    width: ${(props) => (props.width ? `${props.width}px` : '100%')};
    height: 45px;
    padding: 12px 16px;
    border: 1.5px solid #c0bdc1;
    border-radius: 5px;
    outline: 0;
    font-family: 'GoldplayMedium', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 1.2;
    color: #000000;
    text-rendering: optimizeLegibility;
    margin: 5px 0;
    letter-spacing: 0.03em;

    ${(props) =>
      props.hasError &&
      css`
        border: 1.5px solid #f16149 !important;
      `}
    &:disabled {
      color: #7a8998 !important;
      background: #f5f7fa !important;
      border: 1.5px solid #dfe5eb !important;
    }

    &:focus {
      outline-offset: 0;
      border: 1.5px solid #38313c;
    }

    &::-webkit-input-placeholder {
      color: #a7a7a7;
      font-weight: 300;
      font-size: 16px;
      font-family: 'Goldplay', sans-serif;
    }

    &:-ms-input-placeholder {
      color: #a7a7a7;
      font-weight: 300;
      font-size: 16px;
      font-family: 'Goldplay', sans-serif;
    }

    &::placeholder {
      color: #a7a7a7;
      font-weight: 300;
      font-size: 16px;
      font-family: 'Goldplay', sans-serif;
    }
  }

  @media screen and ${device.smallMobile} {
    .PhoneInputCountry {
      margin-top: 2px;
    }

    input {
      -webkit-appearance: none !important;
    }
  }

  @media screen and ${device.mobile} {
    .PhoneInputCountry {
      margin-top: 2px;
    }

    input {
      -webkit-appearance: none !important;
    }
  }

  @media screen and ${device.laptop} {
    .PhoneInputCountry {
      margin-top: 2px;
    }

    input {
      -webkit-appearance: none !important;
    }
  }
`
