import { useEffect } from 'react'
import { useToasts } from 'react-toast-notifications'
import { isEmpty } from 'ramda'
import { useAuthContext } from '../../context/authContext'

function Toast() {
  const { addToast, removeAllToasts } = useToasts()

  const {
    state: { toast, removeToast },
  } = useAuthContext()

  useEffect(() => {
    if (toast) {
      if (toast.show && !isEmpty(toast.message)) {
        addToast(toast.message, {
          appearance: toast.appearance,
          autoDismiss: toast.autoDismiss,
        })
      }
    }
  }, [toast, addToast])

  useEffect(() => {
    if (removeToast) {
      removeAllToasts()
    }
  }, [removeToast, removeAllToasts])

  return null
}

export default Toast
