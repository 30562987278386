import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { device } from '../../utils/mediaQueries'

export const Wrapper = styled.nav`
  background-color: #ffffff !important;
  padding: 0.8rem 3rem;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 6060;
  flex-flow: row nowrap;
  justify-content: flex-start;
  display: flex;
  align-items: center;

  @media screen and ${device.smallMobile} {
    padding: 0.8rem 1.5rem;
    //height: 70px;
  }

  @media screen and ${device.mobile} {
    padding: 0.8rem 1.5rem;
    //height: 70px;
  }
`

export const BrandLogo = styled(Link)`
  display: inline-block;
  padding-top: 0.32rem;
  padding-bottom: 0.32rem;
  margin-right: 5rem;
  font-size: 1.125rem;
  line-height: inherit;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.9);
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;

  img {
    margin-bottom: 5px;
    width: 57px;
    fill: #38313c;
    height: 26px;
  }
`

export const BackAction = styled.span`
  display: flex;
  padding-top: 0.32rem;
  padding-bottom: 0.32rem;
  line-height: inherit;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.9);
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  font-family: 'GoldplayMedium', sans-serif;
  font-style: normal;
  color: #38313c;
  font-size: 16px;
  align-items: center;

  &:hover {
    text-decoration: none;
    color: #38313c;
  }

  img {
    margin-right: 15px;
    width: 20px;
  }
`

export const MenuWrapper = styled.div`
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  align-items: center;

  @media screen and ${device.smallMobile} {
    float: right;
    justify-content: flex-end;
  }

  @media screen and ${device.mobile} {
    float: right;
    justify-content: flex-end;
  }
`

export const MenuItems = styled.ul`
  flex-direction: row;
  margin-right: auto !important;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: flex;

  &.invisible {
    visibility: hidden;
  }

  a {
    color: #99979b;
    display: block;
    cursor: pointer;
    padding: 0.5rem 1rem;
    text-decoration: none;
    background-color: transparent;
    font-family: 'GoldplayMedium', sans-serif;
    font-style: normal;
    font-size: 15px;
    font-weight: 400;
    position: relative;
  }

  a.active {
    color: #38313c;
    font-weight: bolder;
  }

  a:hover,
  &:focus {
    color: #38313c;
  }

  @media screen and ${device.smallMobile} {
    display: none;
  }

  @media screen and ${device.mobile} {
    display: none;
  }
`

export const MenuProfile = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;

  div.profile-dropdown {
    background-color: transparent;
    padding: 3px;
    border: 1px solid rgba(193, 192, 195, 0.5);
    box-sizing: border-box;
    border-radius: 100px;

    ul.dropdown-menu__options {
      right: -30px;
      left: unset;
      display: flex;
      flex-direction: column;
    }

    span {
      margin-right: 10px;
      margin-left: 2px;
      font-family: 'Goldplay', sans-serif;
      font-style: normal;
      font-size: 13px;
      color: #000000;

      &[data-initials] {
        display: block;
      }

      &[data-initials]:before {
        content: attr(data-initials);
        display: inline-block;
        font-weight: 600;
        width: 2.5em;
        height: 2.5em;
        line-height: 2.5em;
        text-align: center;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 5px;
        background: plum;
        color: white;
      }

      @media screen and ${device.smallMobile} {
        margin-right: 0;
        margin-left: 0;

        &[data-initials]:before {
          width: 35px !important;
          height: 35px !important;
          line-height: 35px !important;
          margin-right: 0 !important;
        }

        span {
          display: none;
        }
      }

      @media screen and ${device.mobile} {
        margin-right: 0;
        margin-left: 0;

        &[data-initials]:before {
          width: 35px !important;
          height: 35px !important;
          line-height: 35px !important;
          margin-right: 0 !important;
        }

        span {
          display: none;
        }
      }
    }
  }

  @media screen and ${device.smallMobile} {
    div.profile-dropdown {
      border: none;
    }
  }

  @media screen and ${device.mobile} {
    div.profile-dropdown {
      border: none;
    }
  }
`

export const NavigationToggle = styled.button`
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  padding: 0.25rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1;
  -webkit-appearance: button;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  text-transform: none;
  overflow: visible;
  margin: 0;
  font-family: inherit;
  display: none;

  @media screen and ${device.smallMobile} {
    display: block !important;
  }

  @media screen and ${device.mobile} {
    display: block !important;
  }

  span {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: '';
    background: no-repeat center center;
    background-size: 100% 100%;
    color: rgba(0, 0, 0, 0.5);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
`

export const NotifyBadge = styled.span`
  position: relative;
  font-family: 'Goldplay', sans-serif;
  background-color: transparent;
  border: 1px solid rgba(193, 192, 195, 0.5);
  margin-right: 15px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.invisible {
    visibility: hidden;
  }

  img {
    width: 20px;
  }

  span {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #f7c23d;
    position: absolute;
    top: 1px;
    right: 0;
  }

  @media screen and ${device.smallMobile} {
    margin-right: 10px;
    width: 35px;
    height: 35px;

    img {
      width: 17px;
    }
  }

  @media screen and ${device.mobile} {
    width: 35px;
    height: 35px;
    margin-right: 10px;

    img {
      width: 17px;
    }
  }
`
