import React, { Suspense } from 'react'
import routes from './routes'
import { FullPageLoader } from './components'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import AppRoute from './AppRoute'
import { AuthProvider } from './context/authContext'

function App() {
  return (
    <AuthProvider>
      <Suspense fallback={<FullPageLoader />}>
        <Router>
          <Switch>
            {routes.map((route) => (
              <AppRoute
                key={route.path}
                exact={route.exact}
                path={route.path}
                component={route.component}
                isPrivate={route.isPrivate}
              />
            ))}
          </Switch>
        </Router>
      </Suspense>
    </AuthProvider>
  )
}

export default App
