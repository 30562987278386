import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: block;
  cursor: pointer;
  position: relative;

  ul.dropdown-menu__options {
    z-index: 1000;
    top: 50px;
    left: 0;
    position: absolute;
    width: 200px;
    padding: 10px 0;
    background: #ffffff;
    box-shadow: 0 5px 10px rgb(193 180 207 / 10%);
    border: none;
    border-radius: 10px;
    transition: all 0.2s ease-in;
    opacity: 0;
    transform: scale(0);

    a {
      font-family: 'GoldplayMedium', sans-serif;
      font-style: normal;
      font-size: 15px;
      font-weight: 500;
      color: #38313c;
      display: block;
      padding: 0.65rem 1.5rem !important;
      letter-spacing: 0.3px;

      &:hover,
      &:focus {
        color: #a46edd !important;
        text-decoration: none;
        background-color: #f8f9fa;
      }

      &.active {
        color: #a46edd !important;
        text-decoration: none;
        background-color: transparent;
      }
    }
  }

  ul.dropdown-menu__options--show {
    opacity: 1;
    transform: scale(1);
  }

  ul.dropdown-menu__options--right {
    left: unset;
    right: 0;
  }
`
