import styled from 'styled-components/macro'
import { Modal } from 'react-bootstrap'
import { device } from '../../utils/mediaQueries'
import { Button as Btn } from '../../components'

export const ModalWrapper = styled(Modal)`
  &.modal {
    z-index: 7070 !important;
  }

  .modal-content {
    border: none;
    border-radius: 5px;
    min-height: 370px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  @media screen and ${device.smallMobile} {
    .modal-dialog {
      margin: 0.5rem 1rem;
    }
  }

  @media screen and ${device.mobile} {
    .modal-dialog {
      margin: 0.5rem 1rem;
    }
  }
`

export const Container = styled.div`
  height: inherit;
  width: 100%;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
  padding-right: 30px;
  padding-bottom: 80px;
  padding-left: 30px;
  align-items: center;

  div.content-wrapper {
    align-self: center;
    text-align: center;
  }
`

export const Close = styled.div`
  font-size: 15px;
  font-weight: bolder;
  font-family: 'GoldplayMedium', sans-serif;
  font-style: normal;
  padding: 5px 15px;
  align-self: flex-end;
  cursor: pointer;
  text-align: center;

  @media screen and ${device.smallMobile} {
    top: 20px;
    right: 40px;
  }

  @media screen and ${device.mobile} {
    top: 20px;
    right: 40px;
  }
`

export const ModalCloseIcon = styled.img`
  width: inherit;
  cursor: pointer;
`

export const Button = styled(Btn)`
  justify-content: center;
  width: 80%;
  margin-top: 4%;

  @media screen and ${device.smallMobile} {
    margin-top: 0;
    margin-bottom: 15%;
  }

  @media screen and ${device.mobile} {
    margin-top: 0;
    margin-bottom: 15%;
  }
`

export const Cropper = styled.div`
  margin-top: 20px;

  .cr-boundary {
    border-radius: 9999px;
  }

  .cr-slider-wrap {
    margin-top: 40px;
  }

  .cr-slider {
    width: 250px;
  }

  .cr-slider::-webkit-slider-thumb {
  }

  .cr-slider::-moz-range-thumb {
  }
`
