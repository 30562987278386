import styled, { css } from 'styled-components/macro'
import { device } from '../../utils/mediaQueries'
import { Button as Btn } from '../../components'

type DetailsStyleProps = {
  isDraggingOver?: boolean | null
}

export const AlertPanel = styled.div`
  background: #e4fafb;
  border: 1px solid #1dc7d3;
  box-sizing: border-box;
  border-radius: 8px;
  color: #393340;
  padding: 15px;
  font-size: 15px;
  margin-top: 40px;
  display: flex;
  font-family: 'GoldplayMedium', sans-serif;
  width: 100%;

  img {
    margin-right: 10px;
  }

  @media screen and ${device.smallMobile} {
    font-size: 16px;
  }

  @media screen and ${device.mobile} {
    font-size: 16px;
  }
`

export const OptionsPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Button = styled(Btn)`
  margin-top: 18%;
  margin-bottom: 13%;
  width: 100%;

  @media screen and ${device.smallMobile} {
    margin-top: 20%;
  }

  @media screen and ${device.mobile} {
    margin-top: 28%;
  }
`

export const ImageUploadGrid = styled.div<DetailsStyleProps>`
  border: 1px dashed rgba(153, 151, 155, 0.5);
  min-height: 155px;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-top: 44px;
  cursor: pointer;
  position: relative;

  &:hover {
    border: 1px dashed rgba(193, 192, 195, 0.5);
  }

  &:active {
    border: 1px dashed rgba(193, 192, 195, 0.5);
  }

  &:focus {
    border: 1px dashed rgba(193, 192, 195, 0.5);
  }

  ${(props) =>
    props.isDraggingOver &&
    css`
      background: rgb(0 0 0 / 2.5%);
    `}

  @media screen and ${device.smallMobile} {
    margin-bottom: 30px;
  }

  @media screen and ${device.mobile} {
    margin-bottom: 30px;
  }
`

export const UploadStatus = styled.div`
  position: absolute;
  top: 10px;
  right: 12px;

  &.id_front_view {
  }

  &.id_back_view {
  }

  img {
    width: 18px;
  }

  //display: flex;
  //justify-content: flex-end;
  //margin-right: -20px;
  //
  //&.id_front_view {
  //  margin-top: -10px;
  //}
  //
  //&.id_back_view {
  //  margin-top: -10px;
  //}
  //
  //img {
  //  width: 18px;
  //}
`

export const UploadedDocx = styled.div`
  text-align: center;

  img[alt='id_front_view'] {
    width: auto;
    height: 135px;
  }

  img[alt='id_back_view'] {
    width: auto;
    height: 135px;
  }
`

export const UploadIcon = styled.div`
  text-align: center;

  p {
    font-family: 'Goldplay', sans-serif;
    font-style: normal;
    margin-bottom: 0;
    margin-top: 10px;
    color: #99979b;
    font-size: 13px;
  }

  img[alt='id_front_view'] {
    width: 40px;
  }

  img[alt='id_back_view'] {
    width: 45px;
  }

  @media screen and ${device.smallMobile} {
    p {
      font-size: 14px;
    }
  }

  @media screen and ${device.mobile} {
    p {
      font-size: 14px;
    }
  }
`

export const UploadInput = styled.div`
  cursor: pointer;
  height: 100px;
  //background: antiquewhite;
  position: absolute;
  width: auto !important;

  input {
    cursor: pointer;
    //background: aqua;
    height: inherit;
    opacity: 0;
    -moz-opacity: 0;
    filter: Alpha(Opacity=0);
  }
`

export const ErrorNotice = styled.div`
  font-size: 13.5px;
  letter-spacing: 0.4px;
  color: #ff8c42;
  font-family: 'GoldplayMedium', sans-serif;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0;

  @media screen and ${device.smallMobile} {
  }

  @media screen and ${device.mobile} {
  }
`

export const TextNotice = styled.div`
  font-size: 13.5px;
  letter-spacing: 0.4px;
  color: #ff8c42;
  font-family: 'GoldplayMedium', sans-serif;
  font-weight: 400;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;

  @media screen and ${device.smallMobile} {
  }

  @media screen and ${device.mobile} {
  }
`
