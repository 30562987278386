import React, { useEffect, useState } from 'react'
import {
  ErrorNotice,
  TextNotice,
  AlertPanel,
  QuestionPanel,
  OptionsPanel,
  FormsCol,
  WeightInput,
} from './WeightInputFormBuilder.styles'
import PropTypes, { InferProps } from 'prop-types'
import { isEmpty } from 'ramda'
import { icons } from '../../../assets/icons'

type Answer = {
  questionKey: string
  answers: Array<string>
}

type ChangeEvent = React.ChangeEvent<HTMLInputElement>

export interface BuilderLabel {
  id: string
  label: string
}

export interface BuilderInputs {
  name: string
  placeholder?: string
}

WeightInputFormBuilder.defaultProps = {
  builderValue: '',
  notice: undefined,
}

WeightInputFormBuilder.propTypes = {
  builderLabel: PropTypes.object as PropTypes.Validator<BuilderLabel>,
  builderInputs: PropTypes.object as PropTypes.Validator<BuilderInputs>,
  onInputChange: PropTypes.func as PropTypes.Validator<(answers: Answer) => void>,
  error: PropTypes.string as PropTypes.Validator<string | undefined>,
  builderValue: PropTypes.string as PropTypes.Validator<string>,
  notice: PropTypes.string as PropTypes.Requireable<string>,
}

function WeightInputFormBuilder({
  builderLabel,
  builderValue,
  onInputChange,
  builderInputs,
  error,
  notice,
}: InferProps<typeof WeightInputFormBuilder.propTypes>) {
  const [value, setValue] = useState('')

  useEffect(() => {
    if (builderValue) {
      const weightValue = builderValue?.replace(/[^0-9]/g, '') || ''
      setValue(weightValue)
    }
  }, [builderLabel.id, builderValue])

  const handleInputsChange = (event: ChangeEvent) => {
    const { value } = event.target

    if (!value.match('^[0-9]*$')) {
      return
    }

    onInputChange({ questionKey: builderLabel?.id, answers: [`${value}lb`] })

    setValue(value)
  }

  return (
    <>
      <QuestionPanel>
        <label htmlFor={builderLabel?.id}>{builderLabel?.label}</label>
      </QuestionPanel>
      {/* {notice && <TextNotice>{notice}</TextNotice>} */}

      <OptionsPanel>
        <FormsCol>
          <WeightInput
            value={value}
            name={builderInputs?.name}
            placeholder={builderInputs?.placeholder || ''}
            onChange={handleInputsChange}
          />
        </FormsCol>
        {error && !isEmpty(error) && <ErrorNotice>{error}</ErrorNotice>}
      </OptionsPanel>
    </>
  )
}

export default WeightInputFormBuilder
