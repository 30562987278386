import React, { useEffect, useRef } from 'react'
import { Wrapper } from './LinkDropdown.style'
import PropTypes, { InferProps } from 'prop-types'
import { Link } from 'react-router-dom'

LinkDropdown.defaultProps = {
  className: '',
  position: 'left',
}

LinkDropdown.propTypes = {
  label: PropTypes.node.isRequired as PropTypes.Validator<React.ReactNode>,
  options: PropTypes.array.isRequired as PropTypes.Validator<
    Array<{ label: string; path: string | undefined; onOptionClicked: () => void }>
  >,
  className: PropTypes.string as PropTypes.Validator<string>,
  position: PropTypes.string as PropTypes.Validator<string>,
  showDropdown: PropTypes.bool.isRequired as PropTypes.Validator<boolean>,
  setShowDropdown: PropTypes.func.isRequired as PropTypes.Validator<(value: boolean) => void>,
}

function LinkDropdown({
  label,
  options,
  className,
  position,
  showDropdown,
  setShowDropdown,
}: InferProps<typeof LinkDropdown.propTypes>) {
  const node = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const element = node?.current as any
      if (element.contains(event.target)) {
        return
      }
      setShowDropdown(false)
    }

    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setShowDropdown, showDropdown])

  return (
    <Wrapper ref={node} className={className || ''}>
      {label}
      <ul
        className={`dropdown-menu__options ${showDropdown ? 'dropdown-menu__options--show' : ''}  ${
          position === 'right' ? 'dropdown-menu__options--right' : ''
        }`}
      >
        {options &&
          options.map((option, index) => (
            <Link
              key={index}
              to={option.path || '#'}
              onClick={() => {
                option.onOptionClicked()
                setShowDropdown(false)
              }}
            >
              {option.label}
            </Link>
          ))}
      </ul>
    </Wrapper>
  )
}

export default LinkDropdown
