import React, { ErrorInfo } from 'react'
import { Container, Wrapper, ErrorWrapper } from './ErrorBoundary.style'
import * as Cookies from 'js-cookie'
import { reportErrorToSlack } from '../../utils/reportErrorToSlack'
import { getCookie } from '../../utils/getCookie'
import env from '../../utils/env'
import { paths } from '../../routes'

type ErrorBoundaryProps = {
  children?: React.ReactNode
  systemError?: boolean
}

type ErrorBoundaryState = {
  error?: Error | null
  errorInfo?: ErrorInfo | null
  hasError: boolean
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      error: null,
      errorInfo: null,
      hasError: props?.systemError || false,
    }
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  componentDidCatch(error: Error | null, errorInfo: ErrorInfo) {
    this.setState({ hasError: true, errorInfo })
    this.logErrorToService(error, errorInfo)
  }

  logErrorToService = (error: Error | null, errorInfo: React.ErrorInfo) => {
    if (error) {
      // TODO: send error report to service provider
      // TODO https://www.smashingmagazine.com/2020/06/react-error-handling-reporting-error-boundary-sentry/
      // Temporarily using Slack

      const cookie = getCookie('_loon_tokid')
      const auth = cookie && JSON.parse(cookie)

      reportErrorToSlack(
        error?.message,
        error?.name,
        error?.stack || 'Main Frontend System Error',
        window.location.href,
        // eslint-disable-next-line camelcase
        '',
        auth?.phoneNumber || ''
      )
    }
  }

  handleSignOut = () => {
    Cookies.remove('_loon_tokid')
    Cookies.remove('_loon_usrid')
    localStorage.clear()
    window.location.reload()
  }

  handleGoBack = () => {
    window.location.href = paths.HOME_URL_PATH
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <Wrapper>
            <Container>
              <ErrorWrapper>
                <img width={'30%'} alt="error on loon" src={''} className="error" />
                <h5>{'Whoops!'}</h5>
                <p>
                  {`Something unusual happened. Kindly retry your last action.`}
                  <span onClick={this.handleGoBack}> Go Back</span>
                </p>
                <p>
                  If this persists, please reach out to{' '}
                  <span
                    onClick={() => {
                      window.open(env('LOON_SUPPORT_URL'), '_self')
                    }}
                  >
                    {' '}
                    support team.
                  </span>
                </p>
              </ErrorWrapper>
            </Container>
          </Wrapper>
        </>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
