import styled, { css } from 'styled-components/macro'
import { device } from '../../../utils/mediaQueries'
import { CheckBox } from '../../index'

type BuilderStyleProps = {
  error?: boolean | null
  checked?: boolean | null
}

export const QuestionPanel = styled.div`
  background: #f2ecf9;
  box-sizing: border-box;
  border-radius: 8px;
  color: #393340;
  padding: 15px;
  font-size: 16px;
  font-family: 'GoldplayMedium', sans-serif;
  letter-spacing: 0.5px;
  line-height: 28px;
  margin-top: 40px;
  margin-bottom: 40px;

  label {
    margin-bottom: 0;
  }

  span {
  }

  @media screen and ${device.smallMobile} {
    font-size: 17px;
  }

  @media screen and ${device.mobile} {
    font-size: 17px;
  }
`

export const OptionsPanel = styled.div`
  display: flex;
  padding-right: 15px;
  padding-left: 15px;
  flex-direction: column;

  @media screen and ${device.smallMobile} {
    padding-right: 0;
    padding-left: 0;
  }

  @media screen and ${device.mobile} {
    padding-right: 0;
    padding-left: 0;
  }
`

export const ListCol = styled.div<BuilderStyleProps>`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  padding: 8px 12px;

  &:last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.checked &&
    css`
      background: #fbfafb;
      border: 0.6px solid #8cc12285;
      box-sizing: border-box;
      border-radius: 5px;
    `}

  @media screen and ${device.smallMobile} {
  }

  @media screen and ${device.mobile} {
  }
`

export const CheckBoxItem = styled(CheckBox)`
  width: auto !important;

  input + label span {
    width: 20px;
    height: 20px;
    border: 1px solid #cecfcd;
  }
`

export const CheckBoxLabel = styled.label`
  font-size: 15px;
  font-family: 'GoldplayMedium', sans-serif;
  color: #706b77;
  margin-bottom: 0;
  letter-spacing: 0.5px;
  margin-left: 10px;
  cursor: pointer;

  span {
  }

  @media screen and ${device.smallMobile} {
    font-size: 16px;
  }

  @media screen and ${device.mobile} {
    font-size: 16px;
  }

  @media screen and ${device.tablet} {
  }

  @media screen and ${device.largerDesktop} {
  }
`

export const ErrorNotice = styled.div`
  font-size: 13.5px;
  letter-spacing: 0.4px;
  color: #ff8c42;
  font-family: 'GoldplayMedium', sans-serif;
  width: 100%;
  margin-top: 10px;
  padding-right: 15px;
  padding-left: 30px;
  margin-bottom: 0;

  @media screen and ${device.smallMobile} {
  }

  @media screen and ${device.mobile} {
  }
`

export const TextNotice = styled.div`
  font-size: 13.5px;
  letter-spacing: 0.4px;
  color: #ff8c42;
  font-family: 'GoldplayMedium', sans-serif;
  font-weight: 400;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;

  @media screen and ${device.smallMobile} {
  }

  @media screen and ${device.mobile} {
  }
`
