import React, { useState } from 'react'
import PropTypes, { InferProps } from 'prop-types'
import {
  ModalBody,
  Wrapper,
  BackAction,
  NavWrapper,
  Container,
  LoonBag,
  ListCol,
  OptionsPanel,
  RadioItem,
  RadioLabel,
  Button,
  Input,
  InputLabel,
  InputForm,
} from './SubscriptionByInsuranceModal.style'
import { icons } from '../../assets/icons'
import { images } from '../../assets/images'
import { isEmpty } from 'ramda'
import useValidator from '../../hooks/useValidator'
import { InsuranceCardUpload } from '../index'
import { AuthToken, Insurance, updateInsurance, useAuthContext } from '../../context/authContext'

type ChangeEvent = React.ChangeEvent<HTMLInputElement>

SubscriptionByInsuranceModal.propTypes = {
  authToken: PropTypes.object as PropTypes.Validator<AuthToken | undefined>,
  onCloseFullModal: PropTypes.func as PropTypes.Validator<() => void>,
  onCompleteSubscription: PropTypes.func as PropTypes.Validator<() => void>,
}

function SubscriptionByInsuranceModal({
  authToken,
  onCloseFullModal,
  onCompleteSubscription,
}: InferProps<typeof SubscriptionByInsuranceModal.propTypes>) {
  const [insuranceType, setInsuranceType] = useState<string>('')
  const [showDetailsPage, setShowDetailsPage] = useState(false)
  const [validator, showValidationMessage] = useValidator()
  const [insuranceDetails, setInsuranceDetails] = useState<Insurance>({
    memberId: '',
    memberName: '',
    planName: '',
    bin: '',
    rxGroup: '',
    pcn: '',
    cardFrontUrl: '',
    cardBackUrl: '',
    provider: 'BCBS',
  })

  const {
    state: { isProcessingInsurance },
    dispatch,
  } = useAuthContext()

  const handleRadioInputChange = (event: ChangeEvent) => {
    const { value, checked } = event.target
    if (checked) {
      setInsuranceType(value)
    }
  }

  const handleGoBack = () => {
    if (showDetailsPage) {
      setShowDetailsPage(false)
      return
    }
    onCloseFullModal()
  }

  const handleInputChange = (event: ChangeEvent | string) => {
    if (typeof event === 'string') {
      setInsuranceDetails((insurance) => ({
        ...insurance,
        [name]: event,
      }))
      return
    }
    const { name, value } = event.target
    setInsuranceDetails((insurance) => ({
      ...insurance,
      [name]: value,
    }))
  }

  const handleInsuranceDetailsSubmit = async (insuranceDetails: Insurance) => {
    const payload = {
      ...(insuranceDetails.provider && { provider: insuranceDetails.provider }),
      ...(insuranceDetails.memberId && { memberId: insuranceDetails.memberId.trim() }),
      ...(insuranceDetails.memberName && { memberName: insuranceDetails.memberName.trim() }),
      ...(insuranceDetails.planName && { planName: insuranceDetails.planName.trim() }),
      ...(insuranceDetails.bin && { bin: insuranceDetails.bin.trim() }),
      ...(insuranceDetails.rxGroup && { rxGroup: insuranceDetails.rxGroup.trim() }),
      ...(insuranceDetails.pcn && { pcn: insuranceDetails.pcn.trim() }),
      ...(insuranceDetails.cardBackUrl && { cardBackUrl: insuranceDetails.cardBackUrl.trim() }),
      ...(insuranceDetails.cardFrontUrl && {
        cardFrontUrl: insuranceDetails.cardFrontUrl.trim(),
      }),
    } as Insurance

    if (!authToken) return

    await updateInsurance(dispatch, payload, authToken.token, () => {
      onCompleteSubscription()
    })
  }

  const handleContinueAction = () => {
    setShowDetailsPage(insuranceType === 'insuranceInfo' || insuranceType === 'insuranceCard')
  }

  return (
    <>
      <Wrapper size={'xl'} show={true} onHide={() => false} animation={false}>
        <NavWrapper>
          <BackAction to="#" onClick={handleGoBack}>
            <img className="back" src={icons.iconBackArrowLeft} alt={'back'} />
            Back
          </BackAction>
        </NavWrapper>
        <ModalBody>
          <Container>
            <p className={'container-title'}>{`ALMOST DONE!`}</p>
            <p className={'container-subtitle'}>{`Let’s get your insurance details.`}</p>
            {!showDetailsPage && (
              <>
                <OptionsPanel>
                  <ListCol checked={insuranceType === 'insuranceInfo'}>
                    <RadioItem
                      size={'small'}
                      id={`insuranceInfo`}
                      name={'insuranceType'}
                      value={`insuranceInfo`}
                      onChange={handleRadioInputChange}
                      checked={insuranceType === 'insuranceInfo'}
                    />
                    <RadioLabel htmlFor={`insuranceInfo`}>
                      Type your insurance information
                    </RadioLabel>
                  </ListCol>

                  <ListCol checked={insuranceType === 'insuranceCard'}>
                    <RadioItem
                      size={'small'}
                      id={`insuranceCard`}
                      name={'insuranceType'}
                      value={`insuranceCard`}
                      onChange={handleRadioInputChange}
                      checked={insuranceType === 'insuranceCard'}
                    />
                    <RadioLabel htmlFor={`insuranceCard`}>
                      Upload photo of insurance card
                    </RadioLabel>
                  </ListCol>
                </OptionsPanel>

                <Button
                  label={'Save and Continue'}
                  type={'button-primary'}
                  onClick={handleContinueAction}
                  disabled={isEmpty(insuranceType)}
                  loading={false}
                />
              </>
            )}

            {showDetailsPage && insuranceType === 'insuranceInfo' && (
              <InputForm>
                <InputLabel htmlFor={'memberId'}>Your member ID</InputLabel>
                <Input
                  value={insuranceDetails.memberId || ''}
                  name={'memberId'}
                  placeholder={'Eg. 0091234'}
                  onChange={(event) => handleInputChange(event)}
                  error={false}
                />
                {validator.message('memberId', insuranceDetails.memberId, 'required|string', {
                  messages: {
                    required: 'Member ID is required.',
                  },
                })}

                <InputLabel htmlFor={'memberName'}>Your member name</InputLabel>
                <Input
                  value={insuranceDetails.memberName || ''}
                  name={'memberName'}
                  placeholder={'Eg. Jones Obine'}
                  onChange={(event) => handleInputChange(event)}
                  error={false}
                />
                {validator.message('memberName', insuranceDetails.memberName, 'required|string', {
                  messages: {
                    required: 'Member Name is required.',
                  },
                })}

                <InputLabel htmlFor={'planName'}>Plan name</InputLabel>
                <Input
                  value={insuranceDetails.planName || ''}
                  name={'planName'}
                  placeholder={'Eg. HMO'}
                  onChange={(event) => handleInputChange(event)}
                  error={false}
                />
                {validator.message('planName', insuranceDetails.planName, 'required|string', {
                  messages: {
                    required: 'Plan Name is required.',
                  },
                })}

                <InputLabel htmlFor={'bin'}>BIN</InputLabel>
                <Input
                  value={insuranceDetails.bin || ''}
                  name={'bin'}
                  placeholder={''}
                  onChange={(event) => handleInputChange(event)}
                  error={false}
                />
                {validator.message('bin', insuranceDetails.bin, 'required|string', {
                  messages: {
                    required: 'BIN is required.',
                  },
                })}

                <InputLabel htmlFor={'rxGroup'}>Rx Group</InputLabel>
                <Input
                  value={insuranceDetails.rxGroup || ''}
                  name={'rxGroup'}
                  placeholder={''}
                  onChange={(event) => handleInputChange(event)}
                  error={false}
                />
                {validator.message('rxGroup', insuranceDetails.rxGroup, 'required|string', {
                  messages: {
                    required: 'Rx Group is required.',
                  },
                })}

                <InputLabel htmlFor={'pcn'}>PCN</InputLabel>
                <Input
                  value={insuranceDetails.pcn || ''}
                  name={'pcn'}
                  placeholder={''}
                  onChange={(event) => handleInputChange(event)}
                  error={false}
                />
                {validator.message('pcn', insuranceDetails.pcn, 'required|string', {
                  messages: {
                    required: 'PCN is required.',
                  },
                })}

                <Button
                  className={'input-form__btn'}
                  label={'Save and Continue'}
                  type={'button-primary'}
                  onClick={async () => {
                    if (!validator.allValid()) {
                      showValidationMessage(true)
                      return
                    }
                    await handleInsuranceDetailsSubmit(insuranceDetails)
                  }}
                  disabled={isEmpty(insuranceDetails.memberId) || isProcessingInsurance}
                  loading={isProcessingInsurance}
                />
              </InputForm>
            )}

            {showDetailsPage && insuranceType === 'insuranceCard' && (
              <InsuranceCardUpload
                isProcessingCardDetails={isProcessingInsurance}
                onSubmitCardDetails={async (file) => {
                  await handleInsuranceDetailsSubmit({
                    provider: insuranceDetails.provider,
                    cardFrontUrl: file.id_front_view,
                    cardBackUrl: file.id_back_view,
                  } as Insurance)
                }}
              />
            )}
          </Container>
          <LoonBag>
            <img src={images.imageLoonBag} alt={'loon package bag'} />
          </LoonBag>
        </ModalBody>
      </Wrapper>
    </>
  )
}

export default SubscriptionByInsuranceModal
